import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import { Language } from '@zakodium/profid-shared';

import { Article } from './article';

import { RichTextRenderer } from '#tailwind_ui';

export interface AlertDocumentGeneralInformationProps {
  language: Language;
  generalInformation: string | null;
  isDraft?: boolean;
}

export function AlertDocumentGeneralInformation(
  props: AlertDocumentGeneralInformationProps,
) {
  const { language, generalInformation, isDraft } = props;

  return (
    <Article
      icon={<InformationCircleIcon className="h-5" />}
      title={
        <T
          keyName="series.alert.field.general"
          language={language}
          ns="alert"
        />
      }
      content={
        generalInformation && <RichTextRenderer state={generalInformation} />
      }
      language={language}
      isDraft={isDraft}
    />
  );
}
