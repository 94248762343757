import {
  DocumentPlusIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { T, useTranslate } from '@tolgee/react';
import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { match } from 'ts-pattern';
import { useBoolean, useCounter } from 'usehooks-ts';

import { useDocumentSetDynamicDocumentContext } from '../../contexts/documentSeDynamicDocument';
import { useDocumentSetCandidateContext } from '../../contexts/documentSetCandidate';
import { requiredObject, requiredString } from '../../forms/validation';
import { usePaginationTable } from '../../hooks/usePaginationTable';
import { useIdParam } from '../../hooks/useParams';
import { useSortTable } from '../../hooks/useSortTable';
import { useTableSelection } from '../../hooks/useTableSelection';
import { DocumentSetCandidateTableSearchProvider } from '../../providers/DocumentSetCandidateTableSearchProvider';
import { DocumentSetDynamicDocumentTableSearchProvider } from '../../providers/DocumentSetDynamicDocumentTableSearchProvider';
import { IndexFilterError } from '../TableSearch/IndexFilterError';
import IfHasPermission from '../permissions/IfHasPermission';
import FormattedButton from '../translation/FormattedButton';
import FormattedErrorAlert from '../translation/FormattedErrorAlert';
import FormattedFormError from '../translation/FormattedFormError';
import FormattedInputField from '../translation/FormattedInputField';
import { UserChangesMeta } from '../user/UserChangesMeta';

import { ConfirmDeleteDocumentSet } from './DocumentSet/ConfirmDeleteDocumentSet';
import DocumentSetDocumentCandidatesTable from './DocumentSetDocumentCandidatesTable';
import DocumentSetTitle from './DocumentSetTitle';
import DocumentSetType from './DocumentSetType';
import DocumentSetView from './DocumentSetView';

import {
  DocumentSortField,
  useAddDocumentsToSetMutation,
  useDocumentSetDocumentsQuery,
  useExcludeCandidateDocumentsFromSetMutation,
  useRemoveDocumentsFromSetMutation,
  useRenameDocumentSetMutation,
} from '#gql';
import { FormattedDialogCancelButton, FormattedSubmitButton } from '#intl';
import {
  ButtonGroup,
  ButtonGroupButton,
  ButtonGroupDropdown,
  DropdownOptions,
  FormRHFDialogBody,
  FormRHFDialogFooter,
  FormRHFDialogRoot,
  FormRHFDialogTitle,
  SlideOver,
} from '#tailwind_ui';
import { H4 } from '#ui/heading';
import { HighlightedLink } from '#ui/link';
import { PageLayout, PageLayoutNavigation } from '#ui/page_layout';
import { assert } from '#utils/assert';

const validationSchema = requiredObject({
  name: requiredString,
});

function DocumentSet() {
  const { t } = useTranslate();
  const candidateContext = useDocumentSetCandidateContext();
  const dynamicDocumentContext = useDocumentSetDynamicDocumentContext();
  const id = useIdParam();
  const navigate = useNavigate();
  const counter = useCounter();
  const [candidateError, setCandidateError] = useState<Error | null>(null);
  const [documentError, setDocumentError] = useState<Error | null>(null);
  const [sort, tableSort] = useSortTable<DocumentSortField>({
    field: 'caseSeizureDate',
    direction: 'DESC',
  });
  const { pagination, tablePagination } = usePaginationTable();
  const {
    value: isOpenCandidates,
    setTrue: openCandidates,
    setFalse: closeCandidates,
  } = useBoolean();
  const {
    value: isOpenEditModal,
    setTrue: openEditModal,
    setFalse: closeEditModal,
  } = useBoolean();
  const {
    value: isOpenDeleteModal,
    setTrue: openDeleteModal,
    setValue: setDeleteModal,
  } = useBoolean();

  const documentSelection = useTableSelection<string>();
  const candidatesSelection = useTableSelection<string>();

  const queryResult = useDocumentSetDocumentsQuery({
    variables: {
      ...pagination,
      id,
      sort,
      candidatesFilterBy: candidateContext.filters,
    },
    onCompleted(data) {
      if (!data.documentSet.dynamicFilters) return;

      dynamicDocumentContext.store.setInitialData(
        data.documentSet.dynamicFilters,
      );
      dynamicDocumentContext.store.reset();
    },
  });
  const data = queryResult.data || queryResult.previousData;
  const { error, refetch } = queryResult;
  const [addDocumentsToSet] = useAddDocumentsToSetMutation();
  const [removeDocumentsFromSet] = useRemoveDocumentsFromSetMutation();
  const [renameDocumentSet] = useRenameDocumentSetMutation();
  const [excludeCandidatesDocumentsFromSet] =
    useExcludeCandidateDocumentsFromSetMutation();

  const defaultFormValue = useMemo(
    () => ({ name: data?.documentSet?.name ?? '' }),
    [data?.documentSet?.name],
  );

  // Quite hard to split in subcomponent because data is needed to compute props for PageLayout
  if (error || !data) {
    return (
      <PageLayout
        title={
          <DocumentSetTitle
            value={{ id, name: data?.documentSet?.name ?? '' }}
          />
        }
        navigation={
          <PageLayoutNavigation to="/document-sets">
            <T keyName="nav.doc_sets" />
          </PageLayoutNavigation>
        }
      >
        {error && <IndexFilterError error={error} context={candidateContext} />}
        {null}
      </PageLayout>
    );
  }

  const { documentSet } = data;
  const padifCountTranslation = (
    <T
      keyName="doc_set.list.padif_analysis.counts"
      params={{
        count: documentSet.padifAnalysis.totalCount,
        Link: (content: string) => (
          <HighlightedLink
            key={`Link-${documentSet.padifAnalysis.totalCount}`}
            to={`/padif?documentSets=${documentSet.id}`}
          >
            {content}
          </HighlightedLink>
        ),
      }}
    />
  );

  const documentSetOptions: DropdownOptions<
    'rename' | 'excluded_documents' | 'delete'
  > = [
    [
      {
        type: 'action',
        label: <T keyName="doc_set.rename" />,
        data: 'rename',
        icon: <PencilIcon className="text-primary-600" />,
      },
      {
        type: 'action',
        label: (
          <T
            keyName="doc_set.manage_excluded_documents"
            params={{ count: documentSet.allExcludedDocumentsIds.length }}
          />
        ),
        data: 'excluded_documents',
        icon: <DocumentPlusIcon className="text-neutral-600" />,
      },
      {
        type: 'action',
        data: 'delete',
        icon: <TrashIcon className="text-danger-600" />,
        label: <T keyName="global.delete" />,
      },
    ],
  ];

  function onClickRemove() {
    const documents = Array.from(documentSelection.value);

    const mutation = match(documentSet.type)
      .with('manual', () => removeDocumentsFromSet)
      .with('dynamic', () => excludeCandidatesDocumentsFromSet)
      .exhaustive();

    mutation({
      variables: {
        input: {
          id,
          documents,
        },
      },
    })
      .then(() => {
        setDocumentError(null);
        counter.increment();
        documentSelection.methods.clear();
        void refetch();
      })
      .catch((error) => {
        setDocumentError(error);
      });
  }

  function onClickAdd() {
    const documents = Array.from(candidatesSelection.value);
    addDocumentsToSet({
      variables: {
        input: {
          id,
          documents,
        },
      },
    })
      .then(() => {
        setCandidateError(null);
        closeCandidates();
        counter.increment();
        candidatesSelection.methods.clear();
        void refetch();
      })
      .catch((error) => {
        setCandidateError(error);
      });
  }

  function onClickExclude() {
    const documents = Array.from(candidatesSelection.value);
    excludeCandidatesDocumentsFromSet({
      variables: {
        input: {
          id,
          documents,
        },
      },
    })
      .then(() => {
        setCandidateError(null);
        closeCandidates();
        counter.increment();
        candidatesSelection.methods.clear();
        void refetch();
      })
      .catch((error) => {
        setCandidateError(error.message);
      });
  }

  async function onRename({ name }: typeof defaultFormValue) {
    // skip mutation if name don't change
    if (name === documentSet.name) return closeEditModal();

    await renameDocumentSet({
      variables: {
        input: { id, name },
      },
    });

    await refetch();
  }

  return (
    <PageLayout
      title={<DocumentSetTitle value={documentSet} />}
      navigation={
        <PageLayoutNavigation to="/document-sets">
          <T keyName="nav.doc_sets" />
        </PageLayoutNavigation>
      }
      actions={
        <IfHasPermission userPermission="documentSet_update">
          <ButtonGroup>
            {match(documentSet.type)
              .with('manual', () => (
                <ButtonGroupButton variant="white" onClick={openCandidates}>
                  <T keyName="doc_set.manage_candidates" />
                </ButtonGroupButton>
              ))
              .with('dynamic', () => (
                <ButtonGroupButton
                  variant="white"
                  onClick={() =>
                    navigate(`/document-sets/${id}/dynamic-filters`)
                  }
                >
                  <T keyName="doc_set.manage_dynamic_filters" />
                </ButtonGroupButton>
              ))
              .exhaustive()}
            <ButtonGroupDropdown
              options={documentSetOptions}
              onSelect={(action) => {
                assert(action.data);

                match(action.data)
                  .with('rename', openEditModal)
                  .with('excluded_documents', () =>
                    navigate('./excluded-documents'),
                  )
                  .with('delete', openDeleteModal)
                  .exhaustive();
              }}
            />
          </ButtonGroup>
        </IfHasPermission>
      }
      footerClassName="flex gap-2"
      footer={
        <>
          <FormattedButton
            messageId="global.select_all"
            variant="secondary"
            size="small"
            onClick={() =>
              documentSelection.methods.set(documentSet.allDocumentsIds)
            }
          />
          <FormattedButton
            messageId="global.deselect_all"
            variant="secondary"
            size="small"
            onClick={documentSelection.methods.clear}
          />
          <span className="flex-1" />
          <FormattedButton
            onClick={onClickRemove}
            disabled={documentSelection.value.size === 0}
            messageId={match(documentSet.type)
              .returnType<TranslationKey>()
              .with('manual', () => 'doc_set.list.documents.action.remove')
              .with('dynamic', () => 'doc_set.list.documents.action.exclude')
              .exhaustive()}
            messageParams={{ count: documentSelection.value.size }}
          />
        </>
      }
    >
      <div className="flex items-center justify-between gap-2">
        <p>
          <DocumentSetType value={documentSet} />
          {' | '}
          {match(documentSet.type)
            .with('dynamic', () => (
              <T
                keyName="doc_set.list.documents.dynamic.counts"
                params={{
                  documentCount: documentSet.documents.totalCount,
                  excludedCount: documentSet.allExcludedDocumentsIds.length,
                }}
              />
            ))
            .with('manual', () => (
              <T
                keyName="doc_set.list.documents.manual.counts"
                params={{ count: documentSet.documents.totalCount }}
              />
            ))
            .exhaustive()}
          {' | '}
          {padifCountTranslation}
        </p>
        <UserChangesMeta
          {...documentSet}
          mailSubject={`[${t('global.title.doc_set', { noWrap: true })}] ${documentSet.name}`}
          mailBody={window.location.href}
        />
      </div>

      <FormattedErrorAlert
        error={documentError}
        onDismiss={() => setDocumentError(null)}
      />

      <DocumentSetView
        documentSet={documentSet}
        tableSort={tableSort}
        tablePagination={tablePagination}
        selection={documentSelection}
        selectedClassname={clsx(
          documentSet.type === 'dynamic' && 'bg-danger-300',
        )}
      />

      <SlideOver
        isOpen={isOpenCandidates}
        hasCloseButton
        onClose={closeCandidates}
        requestCloseOnClickOutside
        maxWidth={false}
        className="max-w-screen-lg"
      >
        <SlideOver.Header>
          <H4>
            <T keyName="doc_set.list.candidates.title" />
          </H4>
        </SlideOver.Header>
        <SlideOver.Content>
          <FormattedErrorAlert
            error={candidateError}
            onDismiss={() => setCandidateError(null)}
          />
          <DocumentSetDocumentCandidatesTable
            key={counter.count}
            documentSetId={documentSet.id}
            selection={candidatesSelection}
          />
        </SlideOver.Content>
        <SlideOver.Footer>
          <FormattedButton
            messageId="global.select_all"
            variant="secondary"
            size="small"
            onClick={() =>
              candidatesSelection.methods.set(documentSet.allCandidatesIds)
            }
          />
          <FormattedButton
            messageId="global.deselect_all"
            variant="secondary"
            size="small"
            onClick={candidatesSelection.methods.clear}
          />
          <span className="flex-1" />
          <FormattedButton
            messageId="doc_set.list.candidates.action.exclude"
            messageParams={{ count: candidatesSelection.value.size }}
            onClick={onClickExclude}
            disabled={candidatesSelection.value.size === 0}
            color="warning"
          />
          <FormattedButton
            messageId="doc_set.list.candidates.action.add"
            messageParams={{ count: candidatesSelection.value.size }}
            onClick={onClickAdd}
            disabled={candidatesSelection.value.size === 0}
          />
        </SlideOver.Footer>
      </SlideOver>

      <FormRHFDialogRoot<typeof defaultFormValue>
        autoCloseOnSubmit
        open={isOpenEditModal}
        validationSchema={validationSchema}
        defaultValues={defaultFormValue}
        onSubmit={onRename}
        onOpenChange={(value) => {
          if (value) return;
          closeEditModal();
        }}
      >
        <FormRHFDialogTitle>
          <T keyName="doc_set.edit.title" />
        </FormRHFDialogTitle>
        <FormRHFDialogBody>
          <FormattedInputField name="name" label="global.name" />
          <FormattedFormError />
        </FormRHFDialogBody>
        <FormRHFDialogFooter>
          <FormattedDialogCancelButton />
          <FormattedSubmitButton messageId="global.change" />
        </FormRHFDialogFooter>
      </FormRHFDialogRoot>

      <ConfirmDeleteDocumentSet
        id={id}
        name={documentSet.name}
        isOpen={isOpenDeleteModal}
        setIsOpen={setDeleteModal}
        disabled={documentSet.padifAnalysis.totalCount > 0}
      >
        <p>{padifCountTranslation}</p>
      </ConfirmDeleteDocumentSet>
    </PageLayout>
  );
}

export default function DocumentSetPage() {
  return (
    <DocumentSetDynamicDocumentTableSearchProvider>
      <DocumentSetCandidateTableSearchProvider>
        <DocumentSet />
      </DocumentSetCandidateTableSearchProvider>
    </DocumentSetDynamicDocumentTableSearchProvider>
  );
}
