import {
  DraftOrPublicationAlert,
  getDate,
  getTranslation,
  isAlertDraft,
} from './utils/draftOrPublication';

import { AlertDocumentHeader as Header } from '#series_alert';

interface AlertDocumentHeaderProps {
  alert: DraftOrPublicationAlert;
}

export default function AlertDocumentHeader(props: AlertDocumentHeaderProps) {
  const translation = getTranslation(props.alert);
  const date = getDate(props.alert);
  const isDraft = isAlertDraft(props.alert);

  return (
    <Header
      language={translation?.language}
      date={date}
      serieSeqId={props.alert.serie.seqId}
      isDraft={isDraft}
    />
  );
}
