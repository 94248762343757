import { TableSearchBaseFilters } from './TableSearchContextProvider';

export function checkIsFiltered(filters: TableSearchBaseFilters) {
  for (const value of Object.values(filters)) {
    if (Array.isArray(value)) {
      if (value.length > 0) return true;
      continue;
    }

    if (value) return true;
  }

  return false;
}
