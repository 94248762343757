import { useTranslate } from '@tolgee/react';
import { useCallback, useMemo, useRef } from 'react';
import { match } from 'ts-pattern';

import { useUserContext } from '../../../contexts/user';

import { UserActiveStateBadge, UserConfirmStateBadge } from './UserStatusBadge';

import { GqlServicesQuery, useServicesQuery } from '#gql';
import {
  SearchSelect,
  Select,
  SimpleListContainer,
  SimpleListContainerItem,
  useSearchSelect,
} from '#tailwind_ui';

export function AdminUsersTableFilters() {
  return (
    <SimpleListContainer>
      <SimpleListContainerItem className="space-y-2">
        <SearchSelectService />
        <SelectIsDisabled />
        <SelectIsConfirmed />
      </SimpleListContainerItem>
    </SimpleListContainer>
  );
}

function SearchSelectService() {
  const { t } = useTranslate();
  const { filters, store } = useUserContext();

  const { data: servicesData, error: servicesError } = useServicesQuery();
  if (servicesError) throw servicesError;

  const services = useRef(
    new Map<string, GqlServicesQuery['services']['nodes'][number]>(),
  );
  const serviceOptions = useMemo(() => {
    return (
      servicesData?.services.nodes.map((s) => {
        services.current.set(s.identifier, s);
        return s.identifier;
      }) ?? []
    );
  }, [servicesData?.services]);
  const searchSelectService = useSearchSelect({ options: serviceOptions });

  const onChange = useCallback(
    (option: string | undefined) => {
      store.mergeData({ service: option ?? null });
    },
    [store],
  );

  const renderOption = useCallback((option: string) => {
    const service = services.current.get(option);

    if (!service) return null;

    return `${service.identifier} - ${service.name}`;
  }, []);

  return (
    <SearchSelect<string>
      {...searchSelectService}
      selected={filters.service ?? ''}
      renderOption={renderOption}
      label="Service"
      hiddenLabel
      clearable
      placeholder={t('global.service')}
      onChange={onChange}
    />
  );
}

const DISABLED_OPTIONS = ['is-disabled', 'is-active'];
function SelectIsDisabled() {
  const { t } = useTranslate();
  const { filters, store } = useUserContext();

  const selected = useMemo(
    () =>
      match(filters.isDisabled)
        .with(true, () => 'is-disabled')
        .with(false, () => 'is-active')
        .otherwise(() => ''),
    [filters.isDisabled],
  );

  const onChange = useCallback(
    (selected: string | undefined) => {
      store.mergeData({
        isDisabled: match(selected)
          .with('is-disabled', () => true)
          .with('is-active', () => false)
          .otherwise(() => null),
      });
    },
    [store],
  );

  return (
    <Select<string>
      className="min-w-[16ch]"
      options={DISABLED_OPTIONS}
      selected={selected}
      renderOption={renderSelectIsDisabledOption}
      renderSelectedOption={renderSelectIsDisabledOption}
      onChange={onChange}
      clearable
      placeholder={t('user.status.DISABLED')}
    />
  );
}

function renderSelectIsDisabledOption(option: string) {
  if (!option) return '';

  return <UserActiveStateBadge isDisabled={option === 'is-disabled'} />;
}

const CONFIRMED_OPTIONS = ['is-confirmed', 'is-unconfirmed'];
function SelectIsConfirmed() {
  const { t } = useTranslate();
  const { filters, store } = useUserContext();

  const selected = useMemo(
    () =>
      match(filters.isConfirmed)
        .with(true, () => 'is-confirmed')
        .with(false, () => 'is-unconfirmed')
        .otherwise(() => ''),
    [filters.isConfirmed],
  );

  const onChange = useCallback(
    (selected: string | undefined) => {
      store.mergeData({
        isConfirmed: match(selected)
          .with('is-confirmed', () => true)
          .with('is-unconfirmed', () => false)
          .otherwise(() => null),
      });
    },
    [store],
  );

  return (
    <Select<string>
      className="min-w-[16ch]"
      options={CONFIRMED_OPTIONS}
      selected={selected}
      renderOption={renderSelectIsConfirmedOption}
      renderSelectedOption={renderSelectIsConfirmedOption}
      onChange={onChange}
      clearable
      placeholder={t('user.status.CONFIRMED')}
    />
  );
}

function renderSelectIsConfirmedOption(option: string) {
  if (!option) return '';

  return <UserConfirmStateBadge isConfirmed={option === 'is-confirmed'} />;
}
