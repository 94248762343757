import { T } from '@tolgee/react';

import { useDocumentSetDynamicDocumentContext } from '../../../contexts/documentSeDynamicDocument';
import { UseTableSelect } from '../../../hooks/useTableSelection';
import { IndexFilterError } from '../../TableSearch/IndexFilterError';
import FormattedButton from '../../translation/FormattedButton';
import { DocumentSetEditDynamicFiltersView } from '../DocumentSetEditDynamicFiltersPage';

import { FirstStepFormValues } from './common';

import { useSimulateDocumentsWithFiltersQuery } from '#gql';
import { Size, Variant } from '#tailwind_ui';
import { H2 } from '#ui/heading';

export interface DocumentSetNewStep2DynamicProps {
  selection: UseTableSelect<string>;
  identifiers: FirstStepFormValues;
  onSubmit: () => void;
}

export default function DocumentSetNewStep2Dynamic(
  props: DocumentSetNewStep2DynamicProps,
) {
  const { onSubmit, ...dataProps } = props;
  const { selection } = props;

  const actionBar = (
    <div className="flex gap-2">
      <FormattedButton
        messageId="global.deselect_all"
        variant={Variant.secondary}
        size={Size.small}
        onClick={selection.methods.clear}
      />

      <span className="flex-1" />

      <FormattedButton
        messageId="doc_set.form.new.save_dynamic_with_exclusion"
        messageParams={{ count: selection.value.size }}
        onClick={onSubmit}
      />
    </div>
  );

  return (
    <>
      <H2>
        <T keyName="doc_set.form.new.step_select_documents.dynamic_title" />
      </H2>

      <p>
        <T keyName="doc_set.form.new.step_select_documents.dynamic_description" />
      </p>

      {actionBar}

      <DocumentSetNewStep2DynamicData {...dataProps} />

      {actionBar}
    </>
  );
}

type DocumentSetNewStep2DynamicDataProps = Omit<
  DocumentSetNewStep2DynamicProps,
  'onSubmit'
>;
function DocumentSetNewStep2DynamicData(
  props: DocumentSetNewStep2DynamicDataProps,
) {
  const { docType, docCountry } = props.identifiers;
  const context = useDocumentSetDynamicDocumentContext();

  const result = useSimulateDocumentsWithFiltersQuery({
    variables: {
      ...context.pagination,
      sortBy: context.sort,
      filterBy: {
        ...context.filters,
        docType: docType ? [docType] : [],
        docCountry: docCountry ? [docCountry] : [],
      },
    },
  });

  const error = result.error;
  const data = result.data || result.previousData;

  if (error) return <IndexFilterError error={error} context={context} />;
  if (!data?.documents) return null;

  const { facets, nodes, totalCount } = data.documents;

  return (
    <DocumentSetEditDynamicFiltersView
      facets={facets}
      documents={nodes}
      totalCount={totalCount}
      selection={props.selection}
    />
  );
}
