import {
  GqlAlertDraftAllLanguagesQuery,
  GqlAlertDraftQuery,
  GqlAlertPublicationQuery,
  Language,
} from '#gql';
import { assert } from '#utils/assert';

export type DraftOrPublicationAlert =
  | GqlAlertDraftQuery['alert']
  | GqlAlertDraftAllLanguagesQuery['alert']
  | GqlAlertPublicationQuery['alert'];

export function getTranslation(alert: DraftOrPublicationAlert) {
  if (isAlertDraft(alert)) {
    assert(alert.draft);
    assert('translation' in alert.draft);
    return alert.draft.translation;
  } else {
    return alert.publication.translation;
  }
}

export function getRapidDetectionImage(alert: DraftOrPublicationAlert) {
  if (isAlertDraft(alert)) {
    assert(alert.draft);
    return alert.draft.rapidIdentificationImage;
  } else {
    return alert.publication.rapidIdentificationImage;
  }
}

export function getDate(alert: DraftOrPublicationAlert) {
  if (isAlertDraft(alert)) {
    assert(alert.draft);
    return new Date(alert.draft.updatedAt);
  } else {
    return new Date(alert.publication.publishedAt);
  }
}

export function getUser(alert: DraftOrPublicationAlert) {
  return isAlertDraft(alert)
    ? alert.draft?.updatedBy.name
    : alert.publication?.publishedBy?.name;
}

export function getLanguages(alert: DraftOrPublicationAlert): Language[] {
  if (isAlertDraft(alert)) {
    assert(alert.draft);
    return alert.draft.languages;
  } else {
    return alert.publication.languages;
  }
}

export function isAlertDraft(
  alert: DraftOrPublicationAlert,
): alert is
  | GqlAlertDraftQuery['alert']
  | GqlAlertDraftAllLanguagesQuery['alert'] {
  return (alert as GqlAlertPublicationQuery['alert']).publication === undefined;
}
