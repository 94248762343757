import { sqliteAdaptDatabase } from '@zakodium/profid-shared';
import { useCallback, useMemo, useRef } from 'react';
import initSqlJs from 'sql.js';
import sqlWasmUrl from 'sql.js/dist/sql-wasm.wasm?url';

import { usePromise } from '../../hooks/usePromise';

let sqlite: initSqlJs.SqlJsStatic | undefined;

function useSqlite() {
  const sqliteState = usePromise(
    useCallback(async () => {
      if (sqlite) return sqlite;

      const _sqlite = await initSqlJs({ locateFile: () => sqlWasmUrl });
      if (sqlite) return sqlite; // https://eslint.org/docs/latest/rules/require-atomic-updates

      return (sqlite = _sqlite);
    }, []),
  );

  if (sqliteState.error) throw sqliteState.error;

  return sqliteState.data;
}

export function useSqliteDatabase(
  data?: Uint8Array,
  wrapError?: (error: Error) => Error,
) {
  const sqlite = useSqlite();
  const Database = sqlite?.Database;

  // don't force the caller to wrap into a useCallback
  const wrapErrorRef = useRef(wrapError);
  wrapErrorRef.current = wrapError;

  return useMemo(() => {
    try {
      if (!Database) return;
      const sqliteDatabase = new Database(data);
      const database = sqliteAdaptDatabase(sqliteDatabase);

      // Will throw an error if init data is not a sqlite buffer
      database
        .prepare("SELECT name FROM sqlite_master WHERE type = 'table' LIMIT 1")
        .get();

      return database;
    } catch (error) {
      throw wrapErrorRef.current?.(error) ?? error;
    }
  }, [Database, data]);
}
