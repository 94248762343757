import { CSSProperties } from 'react';

import { FormattedBadge } from './translation/FormattedBadge';

interface ImgLinkProps {
  src: string;
  isExtra?: boolean;
  thumbSrc?: string;
  imgClassName?: string;
  imgStyle?: CSSProperties;
  alt?: string;
  download?: string | false;
}

export default function ImgLink(props: ImgLinkProps) {
  const {
    alt,
    imgClassName,
    imgStyle,
    src,
    thumbSrc,
    isExtra,
    download = false,
  } = props;

  return (
    <div className="relative">
      {isExtra && (
        <div
          style={imgStyle}
          className="pointer-events-none absolute flex h-full w-full items-center justify-around"
        >
          <FormattedBadge
            className="opacity-70"
            variant="COLORED_BACKGROUND"
            color="neutral"
            label="doc.extra_image"
            size="small"
          />
        </div>
      )}

      <a
        href={src}
        target="_blank"
        rel="noopener noreferrer"
        download={download}
      >
        <img
          style={imgStyle}
          className={imgClassName}
          src={thumbSrc || src}
          alt={alt || 'thumbnail'}
        />
      </a>
    </div>
  );
}
