import { PhotoIcon } from '@heroicons/react/24/outline';
import { Fragment, PropsWithChildren, ReactNode } from 'react';

import FormattedEmptyState from '../../../components/translation/FormattedEmptyState';

import { FileImage } from '#series';

export interface SeriesImagesContainerProps extends PropsWithChildren {
  className?: string;
  images: FileImage[];
  renderImage: (image: FileImage) => ReactNode;
}

export function SeriesImagesContainer(props: SeriesImagesContainerProps) {
  const {
    className = 'columns-1 sm:columns-2 lg:columns-3',
    images,
    renderImage,
    children,
  } = props;

  if (images.length === 0) {
    return (
      <FormattedEmptyState title="global.no_images" icon={<PhotoIcon />} />
    );
  }

  return (
    <>
      <div className={className}>
        {images.map((image) => (
          <Fragment key={image.id}>{renderImage(image)}</Fragment>
        ))}
      </div>

      {children}
    </>
  );
}
