/**
 * This should be restricted to a safe list of extensions to mitigate the risk of viruses or XSS vulnerabilities.
 */
const mimetypeAllowList = {
  'image/png': ['.png'],
  'image/jpeg': ['.jpeg', '.jpg'],
  'image/bmp': ['.bmp'],
  'application/pdf': ['.pdf'],
  'text/plain': ['.txt'],
};
const allowedAttachmentExtensions = new Set(
  Object.values(mimetypeAllowList).flat(),
);

export const SeriesRules = {
  get validNameRegex() {
    return /^[\dA-Z]+(?:[ _-][\dA-Z]+)*$/;
  },

  isValidName(name: string): boolean {
    return SeriesRules.validNameRegex.test(name);
  },

  get mimetypeAllowList() {
    return mimetypeAllowList;
  },

  getAllowedExtensions() {
    return Array.from(allowedAttachmentExtensions);
  },

  isAllowedExtension(extension: string): boolean {
    return allowedAttachmentExtensions.has(extension.toLowerCase());
  },

  getField(mimetype: string): 'images' | 'attachments' {
    if (mimetype.startsWith('image/')) return 'images';

    return 'attachments';
  },
};
