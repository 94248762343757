import { T } from '@tolgee/react';
import clsx from 'clsx';
import { ReactNode } from 'react';

import Stamp from '../../../../components/alerts/Stamp';

import { Language } from '#gql';
import { H4 } from '#ui/heading';

export interface ArticleProps {
  title: ReactNode;
  icon?: ReactNode;
  content: ReactNode;
  children?: ReactNode;
  isDraft?: boolean;
  language: Language | undefined;
}
export function Article(props: ArticleProps) {
  const { title, icon, isDraft, content, children, language } = props;

  return (
    <article className="break-inside-avoid border-2 border-solid border-neutral-400">
      <H4 className="flex items-center gap-2 border-b-2 border-neutral-400 px-4 py-1 text-blue-950">
        {icon} {title}
      </H4>
      <div className="space-y-2">
        {children}
        <div className={clsx('relative', isDraft && 'min-h-[140px]')}>
          {content}
          {isDraft && (
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 -rotate-12">
              <div>
                <Stamp size="xl">
                  <T keyName="global.draft" language={language} />
                </Stamp>
              </div>
            </div>
          )}
        </div>
      </div>
    </article>
  );
}
