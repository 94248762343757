import { Route } from 'react-router-dom';

import { ExportSeriePage } from './pages/export_serie_page';
import { ImportSeriePage } from './pages/import_serie_page';

export function seriesExchangeRoutes() {
  return [
    <Route
      key="/series/import"
      path="/series/import"
      element={<ImportSeriePage />}
    />,
    <Route
      key="/series/:seqId/export"
      path="/series/:seqId/export"
      element={<ExportSeriePage />}
    />,
  ];
}
