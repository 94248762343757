import { idBytes } from '../../id';

export interface FormatOptions {
  encryptedData: ArrayBuffer;
  salt: Uint8Array;
  iv: Uint8Array;
}

export function format(options: FormatOptions): ArrayBuffer {
  const id = idBytes;
  const version = Uint8Array.of(1);
  const { salt, iv, encryptedData } = options;

  const formattedData = new Uint8Array(
    id.byteLength +
      version.byteLength +
      salt.byteLength +
      iv.byteLength +
      encryptedData.byteLength,
  );

  let offset = 0;
  formattedData.set(id, offset);
  formattedData.set(version, (offset += id.byteLength));
  formattedData.set(salt, (offset += version.byteLength));
  formattedData.set(iv, (offset += salt.byteLength));
  formattedData.set(
    new Uint8Array(options.encryptedData),
    offset + iv.byteLength,
  );

  return formattedData.buffer;
}
