import { DocumentIcon } from '@heroicons/react/24/outline';
import { AnchorHTMLAttributes, PropsWithChildren } from 'react';

import ByteSize from '../../../components/ByteSize';
import { FileAttachment } from '../types';

export interface SeriesAttachmentProps extends PropsWithChildren {
  attachment: FileAttachment;
  anchorProps?: AnchorHTMLAttributes<HTMLAnchorElement>;
}

export function SeriesAttachment(props: SeriesAttachmentProps) {
  const { attachment, anchorProps } = props;

  return (
    <>
      <DocumentIcon className="h-5 w-5" />

      <a
        href={attachment.url}
        target="_blank"
        rel="noopener noreferrer"
        className="truncate"
        title={attachment.filename}
        {...anchorProps}
      >
        {attachment.filename}
      </a>

      <span className="flex-1" />

      <span className="shrink-0">
        <ByteSize bytes={attachment.size} />
      </span>

      {props.children}
    </>
  );
}
