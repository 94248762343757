import { ReactNode } from 'react';

import ImgLink from '../../../components/ImgLink';
import { MandatoryChildren } from '../../../global';

import { FileImage } from '#series';
import { OverlayProps } from '#ui/overlay';

export interface SeriesImageOverlayProps extends MandatoryChildren {
  label: ReactNode;
  Overlay: (props: OverlayProps) => ReactNode;
  overlayChildren?: ReactNode;
}

export function SeriesImageOverlay(props: SeriesImageOverlayProps) {
  const { children, label, Overlay, overlayChildren } = props;

  return (
    <div className="group relative min-h-[200px] break-inside-avoid">
      <div className="p-2">{children}</div>

      <Overlay>
        <p>{label}</p>
        {overlayChildren}
      </Overlay>
    </div>
  );
}

export interface SeriesImageProps {
  image: FileImage;
}
export function SeriesImage(props: SeriesImageProps) {
  const { image } = props;

  return <ImgLink src={image.url} />;
}
