import { CipherOptions, getCipherAlgorithm } from './cipher_algorithm';
import { derivePassword } from './derive_password';

export async function encrypt(options: CipherOptions) {
  const key = await derivePassword(options.password, options.salt);

  return crypto.subtle.encrypt(
    getCipherAlgorithm(options.iv),
    key,
    options.data,
  );
}
