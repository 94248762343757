import { MegaphoneIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import { Language } from '@zakodium/profid-shared';

import { Article } from './article';

import { RichTextRenderer } from '#tailwind_ui';

export interface AlertDocumentMeasureProps {
  language: Language;
  isDraft?: boolean;
  measures: string | null;
}

export function AlertDocumentMeasure(props: AlertDocumentMeasureProps) {
  const { language, isDraft, measures } = props;

  return (
    <Article
      icon={<MegaphoneIcon className="h-5" />}
      title={
        <T
          keyName="series.alert.field.measures"
          language={language}
          ns="alert"
        />
      }
      language={language}
      content={measures && <RichTextRenderer state={measures} />}
      isDraft={isDraft}
    />
  );
}
