import type {
  Database as SqlJsDatabase,
  Statement as SqlJsStatement,
} from 'sql.js';

import {
  BindParams,
  Database,
  RowObject,
  RunResult,
  Statement,
} from '../types';

export class SqlJsDatabaseImpl implements Database {
  constructor(private readonly database: SqlJsDatabase) {}

  prepare = <
    Result extends RowObject = RowObject,
    BP extends BindParams | undefined = BindParams | undefined,
  >(
    source: string,
  ): Statement<BP, Result> => {
    return new SqlJsStatementImpl(this.database.prepare(source), this.database);
  };

  exec = (source: string): void => {
    this.database.run(source);
  };

  serialize = (): Uint8Array => {
    return this.database.export();
  };
}

export class SqlJsStatementImpl<
  BP extends BindParams | undefined = BindParams | undefined,
  Result extends RowObject = RowObject,
> implements Statement<BP, Result>
{
  constructor(
    private readonly statement: SqlJsStatement,
    private readonly database: SqlJsDatabase,
  ) {
    this.iterate = this.iterate.bind(this);
  }

  get = (params?: BindParams): Result | undefined => {
    this.statement.bind(params);

    if (this.statement.step()) {
      return this.statement.getAsObject() as Result;
    }

    return undefined;
  };

  *iterate(params?: BindParams): Generator<Result> {
    this.statement.bind(params);

    while (this.statement.step()) {
      yield this.statement.getAsObject() as Result;
    }
  }

  all = (params?: BindParams): Result[] => {
    return Array.from(this.iterate(params));
  };

  mapAll = <ResultMapped>(
    mapperFn: (row: Result) => ResultMapped,
    params?: BindParams,
  ): ResultMapped[] => {
    return Array.from(this.iterate(params), mapperFn);
  };

  run = (params?: BindParams): RunResult => {
    this.statement.bind(params);
    this.statement.run();

    return {
      changes: this.database.getRowsModified(),
      lastInsertRowid: Number(
        this.database.exec('SELECT last_insert_rowid()')[0].values[0][0],
      ),
    };
  };
}
