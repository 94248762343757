import { T } from '@tolgee/react';
import React, { useState } from 'react';
import { useKbsGlobal } from 'react-kbs';
import {
  getTargetImageStyle,
  RoiContainer,
  RoiList,
  RoiProvider,
  useActions,
  useCommittedRois,
  useTargetRef,
} from 'react-roi';
import { Link, useNavigate } from 'react-router-dom';

import { useScanIdParam } from '../../../hooks/useParams';
import FormattedButton from '../../translation/FormattedButton';
import FormattedErrorAlert from '../../translation/FormattedErrorAlert';

import { CroppedImagePreviewButton } from './CroppedImagePreviewButton';
import { StateNotCompatibleFormattedAlert } from './utils/StateNotCompatibleFormattedAlert';

import {
  useCorrectScanImportDocumentExtractionMutation,
  useScanQuery,
} from '#gql';
import { scanImportRemoveTab } from '#scan_import';
import { CardLayout } from '#ui/card_layout';
import { PageLayout, PageLayoutNavigation } from '#ui/page_layout';
import { assert } from '#utils/assert';

export default function ScanCorrectExtractDocumentPage() {
  const scanId = useScanIdParam();

  const { data, error } = useScanQuery({
    variables: { id: scanId },
  });

  if (error) {
    return <FormattedErrorAlert error={error} />;
  }
  if (!data?.scan) return null;

  const {
    scan: { importData, filename },
  } = data;

  assert(importData?.__typename !== 'ProcessAnalyze');

  if (!importData?.parameters.extract) {
    return <StateNotCompatibleFormattedAlert id={scanId} />;
  }
  const initialRegion = importData.parameters.extract;

  const { __typename, ...region } = initialRegion;

  return (
    <PageLayout
      title={<T keyName="nav.scans.list.import.extraction" />}
      navigation={
        <>
          <PageLayoutNavigation to="/scans">
            <T keyName="nav.scans.list" />
          </PageLayoutNavigation>
          <PageLayoutNavigation to="./../">{filename}</PageLayoutNavigation>
        </>
      }
    >
      <RoiProvider
        initialConfig={{
          rois: [{ ...region, id: 'crop' }],
          mode: 'select',
          selectedRoiId: 'crop',
          resizeStrategy: 'contain',
        }}
      >
        <RoiCropContainer
          image={importData.extractedDocument.scanJpg}
          scanId={scanId}
        />
      </RoiProvider>
    </PageLayout>
  );
}

function RoiCropContainer(props: {
  image: { url: string; id: string };
  scanId: string;
}) {
  const { image, scanId } = props;
  const { cancelAction } = useActions();
  const imageRef = useTargetRef<HTMLImageElement>();

  useKbsGlobal([
    {
      shortcut: 'Escape',
      handler: (event) => cancelAction(event, { noUnselection: false }),
    },
  ]);

  return (
    <CardLayout
      bodyClassName="!p-0"
      header={<T keyName="scan.step.correct.extract_document.footer" />}
      footer={
        <div className="flex items-center justify-between">
          <CroppedImagePreviewButton imageRef={imageRef} />
          <div className="flex items-center gap-3">
            <FormattedButton
              messageId="global.back"
              as={Link}
              to="./.."
              color="neutral"
            />
            <SaveButton scanId={scanId} />
          </div>
        </div>
      }
    >
      <RoiContainer
        noUnselection
        zoomWithoutModifierKey
        target={
          <img
            alt="Full scan"
            ref={imageRef}
            src={image.url}
            style={getTargetImageStyle()}
            crossOrigin="use-credentials"
          />
        }
        className="max-h-[60vh]"
      >
        <RoiList
          getOverlayOpacity={() => 0.5}
          allowRotate
          showGrid
          getStyle={(roi) => ({
            resizeHandlerColor:
              roi.action.type !== 'idle' ? 'rgba(255,255,255,0.5)' : 'white',
            rectAttributes: {
              fill: 'transparent',
            },
          })}
        />
      </RoiContainer>
    </CardLayout>
  );
}

function SaveButton(props: { scanId: string }) {
  const { scanId } = props;
  const navigate = useNavigate();
  const rois = useCommittedRois();
  assert(rois.length === 1);
  const { id, ...cropRegion } = rois[0];
  const [isDisabled, setIsDisabled] = useState(false);
  const [mutate] = useCorrectScanImportDocumentExtractionMutation();
  return (
    <FormattedButton
      messageId="global.save"
      variant="primary"
      disabled={isDisabled}
      onClick={() => {
        setIsDisabled(true);
        mutate({ variables: { input: { scanId, cropRegion } } })
          .then(() => {
            scanImportRemoveTab(scanId);
            navigate('./..');
          })
          .catch(() => setIsDisabled(false));
      }}
    />
  );
}
