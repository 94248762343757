import { PlusIcon } from '@heroicons/react/24/outline';
import { T, useTranslate } from '@tolgee/react';
import { ReactNode, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';

import { FormUpdateDocumentInput } from '../../../../components/document/DocumentEditInterfaces';
import FormattedButton from '../../../../components/translation/FormattedButton';
import FormattedMultiSearchSelectField from '../../../../components/translation/FormattedMultiSearchSelectField';
import FormattedSubmitButton from '../../../../components/translation/FormattedSubmitButton';

import { useDefaultValue, validationSchema } from './new_shared';
import { LabelFields, LabelFieldsProps } from './shared_fields';

import {
  DocumentType,
  FraudType,
  GqlLabelsQuery,
  GqlNewLabelInput,
  useLabelsQuery,
} from '#gql';
import { FormattedAlert } from '#intl';
import {
  FormRHFDialogBody,
  FormRHFDialogFooter,
  FormRHFDialogRoot,
  FormRHFDialogTitle,
  FormRHFModalProps,
  useCheckedFormRHFContext,
  useOnOff,
} from '#tailwind_ui';
import { LinkLike } from '#ui/link';

export interface LabelsNewFieldsProps {
  name: string;
  label: TranslationKey;
}

export interface NewLabelInputExtended extends GqlNewLabelInput {
  _guid?: string;
}

type ExistingLabel = string;
type PossibleOptionAnd = ExistingLabel & NewLabelInputExtended;
type PossibleOptionOr = ExistingLabel | NewLabelInputExtended;
type PossibleOptions = PossibleOptionAnd[];

const EMPTY_ARRAY: [] = [];

/**
 * Keep in mind this component add _guid attribute on new labels objects
 * @param props
 */
export default function SelectLabelsNewFields(props: LabelsNewFieldsProps) {
  const { name, label } = props;

  const { t } = useTranslate();

  const form =
    useCheckedFormRHFContext<Record<typeof name, PossibleOptionOr[]>>();
  const [docType, docFraudType, docCountry] = useWatch<FormUpdateDocumentInput>(
    {
      name: ['docType', 'docFraudType', 'docCountry'],
    },
  ) as [DocumentType, FraudType, string];

  const labelFieldsProps = useMemo<
    Omit<LabelFieldsProps, 'isScopedLabel' | 'setIsScopedLabel'>
  >(
    () => ({
      docType: [docType],
      docFraudType: [docFraudType],
      docCountry: [docCountry],
    }),
    [docType, docFraudType, docCountry],
  );

  const [search, setSearch] = useState('');
  const _defaultValue = useDefaultValue();
  const [defaultValue, setDefaultValue] =
    useState<NewLabelInputExtended>(_defaultValue);

  const [newOptions, setNewOptions] =
    useState<NewLabelInputExtended[]>(EMPTY_ARRAY);
  const [isOpen, setOpen, setClose] = useOnOff();

  const [isScopedLabel, setIsScopedLabel] = useState(false);

  const { data, error } = useLabelsQuery({
    variables: {
      filterBy: {
        docType: docType ? [null, docType] : null,
        docFraudType: docFraudType ? [null, docFraudType] : null,
        docCountry: docCountry ? [null, docCountry] : null,
      },
    },
    // cleanup impossibles labels due to doc change
    onCompleted(data) {
      const labelsMap = new Set<string>();
      for (const label of data.labels.nodes) {
        labelsMap.add(label.id);
      }
      const actualLabels = form.getValues('labels');
      const authorizedActualLabels = actualLabels.filter((label) => {
        if (typeof label !== 'string') return true;

        return labelsMap.has(label);
      });

      if (actualLabels.length !== authorizedActualLabels.length) {
        form.setValue('labels', authorizedActualLabels, {
          shouldDirty: false,
          shouldTouch: false,
        });
      }
    },
  });
  const labels = data?.labels.nodes;

  const [labelsOptions, optionsMap, labelsMap] = useMemo<
    [
      string[],
      Map<string, ReactNode>,
      Map<string, GqlLabelsQuery['labels']['nodes'][number]>,
    ]
  >(() => {
    const options: string[] = [];
    const optionsMap = new Map<string, ReactNode>();
    const labelsMap = new Map<
      string,
      GqlLabelsQuery['labels']['nodes'][number]
    >();

    for (const label of labels ?? []) {
      options.push(label.id);
      optionsMap.set(label.id, label.name);
      labelsMap.set(label.id, label);
    }

    return [options, optionsMap, labelsMap];
  }, [labels]);

  const filteredLabels = useMemo(() => {
    if (!search) return labelsOptions;

    const searchLowerCase = search.toLowerCase();
    return labelsOptions.filter((id) => {
      const label = labelsMap.get(id);
      if (!label) return false;

      return label.name.toLowerCase().includes(searchLowerCase);
    });
  }, [labelsOptions, labelsMap, search]);

  const filteredNewOptions = useMemo(() => {
    if (!search) return newOptions;

    const searchLowerCase = search.toLowerCase();
    return newOptions.filter((label) => {
      if (!label) return false;

      return label.name.toLowerCase().includes(searchLowerCase);
    });
  }, [newOptions, search]);

  const combinedFilteredOptions = useMemo(() => {
    const combined: Array<ExistingLabel | GqlNewLabelInput> = [
      ...filteredNewOptions,
      ...filteredLabels,
    ];

    return combined.sort((a, b) => {
      const aName = typeof a === 'string' ? a : a.name;
      const bName = typeof b === 'string' ? b : b.name;
      return aName.localeCompare(bName);
    });
  }, [filteredNewOptions, filteredLabels]);

  if (error) throw error;

  function onSubmitNewLabelForm(
    ...params: Parameters<FormRHFModalProps<NewLabelInputExtended>['onSubmit']>
  ) {
    const [data, , methods] = params;
    let isEdit = true;
    if (!data._guid) {
      isEdit = false;
      data._guid = crypto.randomUUID();
    }
    data.name = data.name.trim();

    function labelUniqueCompare(
      a: NewLabelInputExtended | GqlLabelsQuery['labels']['nodes'][number],
      b: NewLabelInputExtended | GqlLabelsQuery['labels']['nodes'][number],
    ) {
      if ('_guid' in a && '_guid' in b && a._guid === b._guid) return false;

      return (
        a.name === b.name &&
        // treat null equivalent of undefined in this context
        // eslint-disable-next-line eqeqeq
        a.docType == b.docType &&
        // eslint-disable-next-line eqeqeq
        a.docFraudType == b.docFraudType &&
        // eslint-disable-next-line eqeqeq
        a.docCountry == b.docCountry
      );
    }

    let uniqueError = newOptions.some((o) => labelUniqueCompare(o, data));
    for (const label of labelsMap.values()) {
      if (labelUniqueCompare(label, data)) {
        uniqueError = true;
        break;
      }
    }

    if (uniqueError) {
      throw new Error(t('label.create.error.unique', { name: data.name }));
    }

    data.description = data.description?.trim();

    let labels = form.getValues(name) ?? [];
    labels = labels.slice();

    if (isEdit) {
      setNewOptions((options) => {
        options = options.slice();
        const index = options.findIndex((o) => o._guid === data._guid);
        options[index] = data;

        return options;
      });
      const index = labels.findIndex(
        (l) => typeof l === 'object' && '_guid' in l && l._guid === data._guid,
      );
      labels[index] = data;
    } else {
      setNewOptions((options) => options.concat(data));
      labels.push(data);
    }

    form.setValue(name, labels, {
      shouldDirty: true,
      shouldTouch: true,
    });

    setClose();
    setDefaultValue(_defaultValue);
    methods.reset();
  }

  return (
    <>
      <FormattedMultiSearchSelectField<PossibleOptionAnd>
        name={name}
        label={label}
        options={combinedFilteredOptions as PossibleOptions}
        getBadgeColor={(option: PossibleOptionOr) => {
          if (typeof option === 'string') {
            const label = labelsMap.get(option);

            return {
              variant: 'CUSTOM_COLOR',
              textColor: label ? label.color.text : 'white',
              backgroundColor: label ? label.color.background : 'red',
              rounded: false,
            };
          }

          return {
            variant: 'CUSTOM_COLOR',
            textColor: option.color.text,
            backgroundColor: option.color.background,
            rounded: false,
          };
        }}
        renderOption={(option: PossibleOptionOr) => {
          if (typeof option === 'string') return optionsMap.get(option);
          return option.name;
        }}
        getValue={(option: PossibleOptionOr) => {
          if (typeof option === 'string') return `db_${option}`;
          return `mem_${String(option._guid)}`;
        }}
        corner={
          <LinkLike onClick={setOpen}>
            <T keyName="doc.field.new_labels.add" />
          </LinkLike>
        }
        searchValue={search}
        onSearchChange={setSearch}
      />

      {newOptions?.length > 0 && (
        <FormattedAlert type="info" messageId="doc.field.new_labels.help" />
      )}

      <FormRHFDialogRoot<NewLabelInputExtended>
        open={isOpen}
        mode="onSubmit"
        defaultValues={defaultValue}
        validationSchema={validationSchema}
        onOpenChange={(value) => {
          if (value) return;
          setClose();
        }}
        onSubmit={onSubmitNewLabelForm}
        icon={<PlusIcon />}
      >
        <FormRHFDialogTitle>
          <T
            keyName={
              defaultValue._guid
                ? 'form.edit_label.title'
                : 'doc.field.new_labels.add'
            }
          />
        </FormRHFDialogTitle>
        <FormRHFDialogBody>
          <LabelFields
            isScopedLabel={isScopedLabel}
            setIsScopedLabel={setIsScopedLabel}
            {...labelFieldsProps}
          />

          <FormattedAlert type="info" messageId="doc.field.new_labels.help" />
        </FormRHFDialogBody>
        <FormRHFDialogFooter>
          <FormButtons
            onCancel={() => {
              setDefaultValue(_defaultValue);
              setClose();
            }}
            isEdit={Boolean(defaultValue._guid)}
          />
        </FormRHFDialogFooter>
      </FormRHFDialogRoot>
    </>
  );
}

interface FormButtonsProps {
  onCancel: () => void;
  isEdit?: boolean;
}
function FormButtons({ onCancel, isEdit }: FormButtonsProps) {
  return (
    <>
      <FormattedButton
        messageId="global.cancel"
        variant="white"
        onClick={onCancel}
      />
      <FormattedSubmitButton
        color="primary"
        messageId={isEdit ? 'global.edit' : 'global.add'}
      />
    </>
  );
}
