import { TrashIcon as TrashIconSolid } from '@heroicons/react/20/solid';
import { useCallback, useState } from 'react';

import IfHasPermission from '../permissions/IfHasPermission';

import DeleteSerieAttachment from './DeleteSerieAttachment';

import {
  FileAttachment,
  SeriesAttachment,
  SeriesAttachmentsContainer,
} from '#series';
import { Button, Color, Size } from '#tailwind_ui';

export interface RemovableSeriesAttachmentsProps {
  serieId: string;
  attachments: FileAttachment[];
}

export function RemovableSeriesAttachments(
  props: RemovableSeriesAttachmentsProps,
) {
  const { serieId, attachments } = props;

  const [deleteAttachment, setDeleteAttachment] =
    useState<null | FileAttachment>(null);

  const closeModal = useCallback(() => setDeleteAttachment(null), []);

  return (
    <SeriesAttachmentsContainer
      attachments={attachments}
      renderAttachments={(attachment) => (
        <SeriesAttachment attachment={attachment}>
          <IfHasPermission userPermission="serie_update">
            <Button
              size={Size.xSmall}
              color={Color.danger}
              onClick={() => setDeleteAttachment(attachment)}
              roundness="circular"
            >
              <TrashIconSolid className="h-5 w-5" />
            </Button>
          </IfHasPermission>
        </SeriesAttachment>
      )}
    >
      {deleteAttachment && (
        <DeleteSerieAttachment
          closeModal={closeModal}
          attachment={deleteAttachment}
          serieId={serieId}
        />
      )}
    </SeriesAttachmentsContainer>
  );
}
