import { PadifAggregationMethod } from '../../generated/graphql_enums';

export const min = (values: number[]) => Math.min(...values);
export const max = (values: number[]) => Math.max(...values);
export const sum = (values: number[]) => values.reduce((p, c) => p + c);
export const average = (values: number[]) => sum(values) / values.length;
export const median = (values: number[]) => {
  values.sort((a, b) => a - b);

  const n = values.length;
  if (n % 2 === 1) {
    const medianIndex = (n + 1) / 2 - 1;
    return values[medianIndex];
  }

  const medianIndex = n / 2 - 1;
  return (values[medianIndex] + values[medianIndex + 1]) / 2;
};
export const euclideanDistance = (values: number[]) =>
  Math.sqrt(values.reduce((p, dist) => p + dist ** 2));

type Aggregator = (values: number[]) => number;
export function getAggregator(method: PadifAggregationMethod): Aggregator {
  switch (method) {
    case 'MIN':
      return min;
    case 'MAX':
      return max;
    case 'SUM':
      return sum;
    case 'AVERAGE':
      return average;
    case 'MEDIAN':
      return median;
    case 'EUCLIDEAN_DISTANCE':
      return euclideanDistance;
    default:
      throw new Error('Unsupported method');
  }
}
