import { BufferSource } from '../types';

import { decrypt } from './cipher/decrypt';
import { encrypt } from './cipher/encrypt';
import { format } from './encapsulation/format';
import { parse } from './encapsulation/parse';

export async function encode(data: BufferSource, password: string) {
  const salt = crypto.getRandomValues(new Uint8Array(16));
  const iv = crypto.getRandomValues(new Uint8Array(16));

  const encryptedData = await encrypt({
    data,
    password,
    salt,
    iv,
  });

  return format({
    encryptedData,
    salt,
    iv,
  });
}

export function decode(data: BufferSource, password: string) {
  const parsedData = parse(data);

  return decrypt({
    data: parsedData.encryptedData,
    salt: parsedData.salt,
    iv: parsedData.iv,
    password,
  });
}
