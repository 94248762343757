import { T } from '@tolgee/react';
import { SerieImportStatus } from '@zakodium/profid-shared';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import FormattedErrorAlert from '../../../../components/translation/FormattedErrorAlert';
import { safeTranslationKeySeriesImportStatus } from '../../../../translationsMapping/importSerie';

import { useSeriesImportStatusQuery } from '#gql';
import { FormattedButton } from '#intl';
import { CardLayout } from '#ui/card_layout';

export interface ImportProgressProps {
  jobId: string;
}

const events: SerieImportStatus[] = ['started', 'loaded', 'ready'];

export function ImportProgress(props: ImportProgressProps) {
  const importStatus = useSeriesImportStatusQuery({
    variables: {
      jobId: props.jobId,
    },
    pollInterval: 1000,
  });

  const data = importStatus.data ?? importStatus.previousData;

  const status = data?.serieImportStatus.status;
  const seqId = data?.serieImportStatus.series?.at(0);

  const percent =
    ((events.indexOf(status as Exclude<typeof status, null | undefined>) + 1) /
      events.length) *
    100;

  return (
    <>
      {importStatus.error && <FormattedErrorAlert error={importStatus.error} />}

      <CardLayout
        title="series.import"
        bodyClassName="space-y-6 pt-6"
        footerClassName="flex justify-end"
        footer={
          seqId && (
            <FormattedButton
              as={Link}
              to={`/series/${seqId}`}
              messageId="series_exchange.import.go_to_imported_series"
            />
          )
        }
      >
        <div className="overflow-hidden rounded-md border-[3px] border-neutral-200 bg-neutral-200">
          <div
            className="h-2 animate-pulse rounded-md bg-primary-600"
            style={{ width: `${percent}%` }}
          />
        </div>

        <div className="mt-6 hidden grid-cols-3 text-sm font-medium text-neutral-600 sm:grid">
          {events.map((event, index) => (
            <div
              key={event}
              className={clsx(
                'text-center first:text-left last:text-right',
                events.indexOf(status ?? 'error') >= index &&
                  'text-primary-600',
              )}
            >
              <T keyName={safeTranslationKeySeriesImportStatus(event)} />
            </div>
          ))}
        </div>
      </CardLayout>
    </>
  );
}
