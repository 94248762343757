import type { Database as BetterSqlite3Database } from 'better-sqlite3';
import type { Database as SqlJsDatabase } from 'sql.js';

import { BetterSqlite3DatabaseImpl } from './adapters/better_sqlite_3';
import { SqlJsDatabaseImpl } from './adapters/sql_js';
import { Database } from './types';

export function adaptDatabase(
  db: BetterSqlite3Database | SqlJsDatabase,
): Database {
  if ('serialize' in db) {
    return new BetterSqlite3DatabaseImpl(db);
  } else if ('export' in db) {
    return new SqlJsDatabaseImpl(db);
  }

  throw new Error(`this db driver is unsupported`);
}
