import { ReactElement, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import DocumentSet from '../components/DocumentSet/DocumentSet';
import DocumentSetDynamicFilters from '../components/DocumentSet/DocumentSetEditDynamicFiltersPage';
import { DocumentSetExcludedDocuments } from '../components/DocumentSet/DocumentSetExcludedDocuments';
import DocumentSetNew from '../components/DocumentSet/DocumentSetNewWizard/DocumentSetNew';
import DocumentSetsIndexPage from '../components/DocumentSet/DocumentSetsIndexPage';
import ErrorBoundary from '../components/ErrorBoundary';
import MustLogin from '../components/MustLogin';
import NotFound from '../components/NotFound';
import PadifIndexPage from '../components/Padif/PadifIndexPage';
import PadifNew from '../components/Padif/PadifNew';
import PadifShow from '../components/Padif/PadifShow';
import PadifShowMainReportJsPage from '../components/Padif/PadifShowMainReportJsPage';
import PadifShowMainReportPythonPage from '../components/Padif/PadifShowMainReportPythonPage';
import PadifShowMainRoiPage from '../components/Padif/PadifShowMainRoiPage';
import { PadifNewRoiPage } from '../components/Padif/react-roi/PadifNewRoiPage';
import AdminAuditLogsIndexPage from '../components/admin/audit-logs/AdminAuditLogsIndexPage';
import { AdminContact } from '../components/admin/contact/AdminContact';
import AdminSeizureAuthoritiesCreate from '../components/admin/seizure-authorities/AdminSeizureAuthoritiesCreate';
import AdminSeizureAuthoritiesIndexPage from '../components/admin/seizure-authorities/AdminSeizureAuthoritiesIndexPage';
import AdminServicesCreate from '../components/admin/services/AdminServicesCreate';
import AdminServicesIndexPage from '../components/admin/services/AdminServicesIndexPage';
import AdminUsersIndexPage from '../components/admin/users/AdminUsersIndexPage';
import CreateUser from '../components/admin/users/CreateUser';
import EditAlertDraft from '../components/alerts/pages/EditAlertDraft';
import ViewAlertDraft from '../components/alerts/pages/ViewAlertDraft';
import ViewAlertPublication from '../components/alerts/pages/ViewAlertPublication';
import AnnouncementBanner from '../components/announcement/AnnouncementBanner';
import ExperimentalFeaturesConfig from '../components/experimental-features/ExperimentalFeaturesConfig';
import { ExperimentalFeatureId } from '../components/experimental-features/experimentalFeatures';
import { useExperimentalFeatures } from '../components/experimental-features/useExperimentalFeatures';
import NavigationBar from '../components/nav/NavigationBar';
import { useAppMeta } from '../components/providers/AppMetaProvider.context';
import { useSession } from '../components/providers/LoginProvider.context';
import { ScanView } from '../components/scans/ScanView';
import Scanner from '../components/scans/Scanner';
import ScansIndexPage from '../components/scans/ScansIndexPage';
import ScansUpload from '../components/scans/ScansUpload';
import EditSerie from '../components/serie/EditSerie';
import MergeSeries from '../components/serie/MergeSeries';
import NewSeriesPage from '../components/serie/NewSeriesPage';
import SeriesIndexPage from '../components/serie/SeriesIndexPage';
import UploadSerieAttachments from '../components/serie/UploadSerieAttachments';
import ViewSerie from '../components/serie/ViewSerie';
import Activate from '../components/user/Activate';
import LoginRedirect from '../components/user/LoginRedirect';
import { Feature } from '../constants';

import { documentsRoutes } from '#routes/document';
import { homeRoutes } from '#routes/home';
import { labelRoutes } from '#routes/label';
import { scanImportRoutes } from '#routes/scan_import';
import { seriesExchangeRoutes } from '#routes/series_exchange';
import { userSettingsRoutes } from '#routes/user_settings';
import { Footer, MainWrapper } from '#ui/app_layout';

export default function Router() {
  const session = useSession();
  useEffect(() => session(), [session]);

  return (
    <Routes>
      <Route
        path="/user/activate"
        element={
          <MainWrapper>
            <Activate />
          </MainWrapper>
        }
      />
      <Route
        path="/login"
        element={
          <MainWrapper>
            <MustLogin>
              <LoginRedirect />
            </MustLogin>
          </MainWrapper>
        }
      />
      <Route
        path="*"
        element={
          <>
            <AnnouncementBanner />
            <MainWrapper>
              <MustLogin>
                <div className="shrink-0">
                  <NavigationBar />
                </div>
                <div className="mt-4 flex-auto shrink-0 grow">
                  <ErrorBoundary>
                    <LoggedInRoutes />
                  </ErrorBoundary>
                </div>
              </MustLogin>
            </MainWrapper>
            <Footer />
          </>
        }
      />
    </Routes>
  );
}

function LoggedInRoutes() {
  const routes = useRoutes();
  return <Routes>{routes}</Routes>;
}

function useRoutes() {
  const { features } = useAppMeta();
  const { featureSet } = useExperimentalFeatures();

  const routes: ReactElement[] = [...homeRoutes(), ...userSettingsRoutes()];

  // eslint-disable-next-line no-lone-blocks
  {
    // Admin pages
    routes.push(
      <Route
        key="/admin/users"
        path="/admin/users"
        element={<AdminUsersIndexPage />}
      />,
      <Route
        key="/admin/users/create"
        path="/admin/users/create"
        element={<CreateUser />}
      />,
      <Route
        key="/admin/services"
        path="/admin/services"
        element={<AdminServicesIndexPage />}
      />,
      <Route
        key="/admin/services/create"
        path="/admin/services/create"
        element={<AdminServicesCreate />}
      />,
      <Route
        key="/admin/contact"
        path="/admin/contact"
        element={<AdminContact />}
      />,
    );
  }

  if (features.includes(Feature.documents)) {
    routes.push(
      ...labelRoutes(),
      ...documentsRoutes(featureSet),
      <Route key="/upload" path="/upload" element={<ScansUpload />} />,
      <Route key="/scanner" path="/scanner" element={<Scanner />} />,
      <Route key="/scans" path="/scans" element={<ScansIndexPage />} />,
      <Route
        key="/scans/:scanId"
        path="/scans/:scanId"
        element={<ScanView />}
      />,
      ...scanImportRoutes(),
      <Route
        key="/admin/seizure-authorities"
        path="/admin/seizure-authorities"
        element={<AdminSeizureAuthoritiesIndexPage />}
      />,
      <Route
        key="/admin/seizure-authorities/create"
        path="/admin/seizure-authorities/create"
        element={<AdminSeizureAuthoritiesCreate />}
      />,
    );
  }

  if (features.includes(Feature.series)) {
    if (featureSet.has('series_exchange')) {
      routes.push(...seriesExchangeRoutes());
    }

    routes.push(
      <Route key="/series" path="/series" element={<SeriesIndexPage />} />,
      <Route
        key="/series/new"
        path="/series/new"
        element={<NewSeriesPage />}
      />,
      <Route key="/series/:seqId" path="/series/:seqId">
        <Route index element={<ViewSerie />} />
        <Route path="edit" element={<EditSerie />} />
        <Route path="merge" element={<MergeSeries />} />
        <Route path="files/manage" element={<UploadSerieAttachments />} />
      </Route>,
      <Route key="/alerts/:id" path="/alerts/:id">
        <Route path="draft" element={<ViewAlertDraft />} />
        <Route path="draft/edit" element={<EditAlertDraft />} />
        <Route
          path="publication/:publicationId"
          element={<ViewAlertPublication />}
        />
      </Route>,
    );
  }

  if (features.includes(Feature.analysis)) {
    routes.push(
      <Route
        key="/document-sets"
        path="/document-sets"
        element={<DocumentSetsIndexPage />}
      />,
      <Route
        key="/document-sets/new"
        path="/document-sets/new"
        element={<DocumentSetNew />}
      />,
      <Route
        key="/document-sets/:id"
        path="/document-sets/:id"
        element={<DocumentSet />}
      />,
      <Route
        key="/document-sets/:id/dynamic-filters"
        path="/document-sets/:id/dynamic-filters"
        element={<DocumentSetDynamicFilters />}
      />,
      <Route
        key="/document-sets/:id/excluded-documents"
        path="/document-sets/:id/excluded-documents"
        element={<DocumentSetExcludedDocuments />}
      />,
      <Route key="/padif" path="/padif" element={<PadifIndexPage />} />,
      <Route key="/padif/new" path="/padif/new" element={<PadifNew />} />,
      <Route
        key="/padif/:id/roi/new"
        path="/padif/:id/roi/new"
        element={<PadifNewRoiPage />}
      />,
      <Route key="/padif/:id" path="/padif/:id" element={<PadifShow />}>
        <Route index element={<PadifShowMainRoiPage />} />

        {featureSet.has('padifJs') ? (
          [
            <Route
              key="report-js"
              path="report"
              element={<PadifShowMainReportJsPage />}
            />,
            <Route
              key="report-python"
              path="report-legacy"
              element={<PadifShowMainReportPythonPage />}
            />,
          ]
        ) : (
          <Route
            key="report-python"
            path="report"
            element={<PadifShowMainReportPythonPage />}
          />
        )}
      </Route>,
    );
  }

  addExperimentalRoutes(routes, featureSet);

  routes.push(<Route key="*" path="*" element={<NotFound />} />);

  return routes;
}

function addExperimentalRoutes(
  routes: ReactElement[],
  experimentalFeatures: Set<ExperimentalFeatureId>,
) {
  routes.push(
    <Route
      key="/experimental"
      path="/experimental"
      element={<ExperimentalFeaturesConfig />}
    />,
  );

  if (experimentalFeatures.has('auditLogs')) {
    routes.push(
      <Route
        key="/admin/audit-logs"
        path="/admin/audit-logs"
        element={<AdminAuditLogsIndexPage />}
      />,
    );
  }
}
