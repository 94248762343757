import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import { Language } from '@zakodium/profid-shared';
import { ReactNode } from 'react';

import { Article } from './article';

import { RichTextRenderer } from '#tailwind_ui';

export interface AlertDocumentRapidIdentificationDescriptionProps {
  language: Language;
  isDraft?: boolean;
  description: string | null;
  children: ReactNode;
}

export function AlertDocumentRapidIdentificationDescription(
  props: AlertDocumentRapidIdentificationDescriptionProps,
) {
  const { language, description, isDraft, children } = props;

  return (
    <Article
      icon={<MagnifyingGlassIcon className="h-5" />}
      title={
        <T
          keyName="series.alert.field.rapid_identification_elements"
          language={language}
          ns="alert"
        />
      }
      language={language}
      isDraft={isDraft}
      content={description && <RichTextRenderer state={description} />}
    >
      {children}
    </Article>
  );
}
