import { T } from '@tolgee/react';

import { H2 } from '#ui/heading';

export interface AlertDocumentTitleProps {
  title: string;
  language: string;
  serieName: string;
}

export function AlertDocumentTitle(props: AlertDocumentTitleProps) {
  const { title, language, serieName } = props;

  return (
    <H2 className="bg-blue-950 p-4 text-center !text-2xl uppercase text-yellow-500">
      {title}
      <br />
      <T
        keyName="series.alert.display.generated-title.second-line"
        language={language}
        params={{
          name: serieName,
        }}
      />
    </H2>
  );
}
