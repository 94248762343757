import { SerieImportStatus } from '@zakodium/profid-shared';

import { safeMappedTranslationKey } from './common';

export const TRANSLATION_KEY_SERIES_IMPORT_STATUS = {
  error: 'global.error',
  started: 'series_exchange.import_status.started',
  loaded: 'series_exchange.import_status.loaded',
  ready: 'series_exchange.import_status.ready',
} satisfies Record<SerieImportStatus, TranslationKey>;

export function safeTranslationKeySeriesImportStatus(
  status: SerieImportStatus | null | undefined,
) {
  return safeMappedTranslationKey(TRANSLATION_KEY_SERIES_IMPORT_STATUS, status);
}
