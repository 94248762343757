import { T } from '@tolgee/react';
import { useState } from 'react';
import { match } from 'ts-pattern';

import { ExperimentalAlert } from '../../../components/experimental-features/ExperimentalWarning';
import FormattedErrorAlert from '../../../components/translation/FormattedErrorAlert';
import {
  FormattedStep,
  FormattedStepper,
} from '../../../components/translation/FormattedStepper';
import { useSeqIdParam } from '../../../hooks/useParams';
import { TK } from '../../../tolgee';

import { ConfigurationStep } from './export_serie_page/configuration_step';
import { ExportProgressionStep } from './export_serie_page/progress_step';
import * as storage from './export_serie_page/session';

import { useSerieQuery } from '#gql';
import { PageLayout, PageLayoutNavigation } from '#ui/page_layout';

const stepperOptions: FormattedStep[] = [
  {
    id: 'password',
    label: 'page.series.export.stepper.security.label',
    description: 'page.series.export.stepper.security.description',
  },
  {
    id: 'download',
    label: 'page.series.export.stepper.download.label',
    description: 'page.series.export.stepper.download.description',
  },
];

export function ExportSeriePage() {
  const seqId = useSeqIdParam();
  const [jobId, setJobId] = useState<string | null>(() => storage.get(seqId));

  function onSetJobId(jobId: string) {
    setJobId(jobId);
    storage.set(seqId, jobId);
  }

  function onClearJobId() {
    setJobId(null);
    storage.remove(seqId);
  }

  const { loading, error, data } = useSerieQuery({
    variables: { seqId },
  });

  if (error) {
    return <FormattedErrorAlert error={error} />;
  } else if (loading || !data) {
    return <T keyName="global.loading" />;
  }

  return (
    <PageLayout
      title={<T keyName="page.series.export" />}
      navigation={
        <>
          <PageLayoutNavigation to="/series">
            <T keyName="nav.series.list" />
          </PageLayoutNavigation>
          <PageLayoutNavigation to="./..">
            {data.serie.seqId}
          </PageLayoutNavigation>
        </>
      }
    >
      <ExperimentalAlert />

      <div className="flex flex-1 flex-col justify-center gap-5">
        <FormattedStepper
          getDescription={(step) => step.description as TK}
          steps={stepperOptions}
          current={jobId ? 1 : 0}
        />
        <div className="w-5/6 self-center">
          {match(jobId)
            .with(null, () => (
              <ConfigurationStep
                setJobId={onSetJobId}
                serieId={data.serie.id}
              />
            ))
            .otherwise((id) => (
              <ExportProgressionStep
                clearJobId={onClearJobId}
                jobId={id}
                seqId={data.serie.seqId}
              />
            ))}
        </div>
      </div>
    </PageLayout>
  );
}
