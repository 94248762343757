import { MandatoryChildren } from '../../../global';

import { CardLayout, CardLayoutProps } from '#ui/card_layout';

export interface SeriesImagesCardProps
  extends MandatoryChildren,
    Pick<CardLayoutProps, 'extraTitle'> {}

export function SeriesImagesCard(props: SeriesImagesCardProps) {
  return (
    <CardLayout
      title="global.images"
      headerClassName="relative space-x-2"
      extraTitle={props.extraTitle}
    >
      {props.children}
    </CardLayout>
  );
}
