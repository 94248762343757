import { TrashIcon } from '@heroicons/react/20/solid';
import { PencilIcon, RectangleGroupIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';

import { useHasPermission } from '../../hooks/permissions';

import AlertDocumentHeader from './AlertDocumentHeader';
import DeleteRapidDetectionImageModal from './modals/DeleteRapidDetectionImageModal';
import EditRapidDetectionImageDialog from './modals/EditRapidDetectionImageDialog';
import {
  DraftOrPublicationAlert,
  getRapidDetectionImage,
  getTranslation,
  isAlertDraft,
} from './utils/draftOrPublication';

import {
  AlertDocumentTitle,
  AlertDocumentGeneralInformation,
  AlertDocumentMeasure,
  AlertDocumentRapidIdentificationDescription,
  AlertDocumentRapidIdentificationImage,
} from '#series_alert';
import { Button, useOnOff } from '#tailwind_ui';
import { EmptyStateButton } from '#ui/button';
import { CompleteOverlay } from '#ui/overlay';
import { assert } from '#utils/assert';

export default function AlertDocument(props: {
  alert: DraftOrPublicationAlert;
}) {
  const isDraft = isAlertDraft(props.alert);
  const translation = getTranslation(props.alert);

  const { general, measures, rapidIdentificationDescription } =
    translation.fields;

  return (
    <section className="space-y-2">
      <AlertDocumentHeader alert={props.alert} />

      <AlertDocumentTitle
        title={translation.fields.title}
        language={translation.language}
        serieName={props.alert.serie.name}
      />

      <AlertDocumentGeneralInformation
        language={translation.language}
        generalInformation={general}
        isDraft={isDraft}
      />

      <AlertDocumentRapidIdentificationDescription
        language={translation.language}
        description={rapidIdentificationDescription}
        isDraft={isDraft}
      >
        <RapidDetectionImage alert={props.alert} />
      </AlertDocumentRapidIdentificationDescription>

      <AlertDocumentMeasure
        language={translation.language}
        isDraft={isDraft}
        measures={measures}
      />
    </section>
  );
}

function RapidDetectionImage(props: { alert: DraftOrPublicationAlert }) {
  const isDraft = isAlertDraft(props.alert);
  const draft = props.alert.draft;
  const rapidIdentificationImage = getRapidDetectionImage(props.alert);
  const translation = getTranslation(props.alert);
  const hasAlertUpdatePermission = useHasPermission('alert_update');
  const [
    showDeleteDetectionImageModal,
    openDeleteDetectionImageModal,
    closeDeleteDetectionImageModal,
  ] = useOnOff();

  const [
    showUpdateDetectionImageModal,
    openUpdateDetectionImageModal,
    closeUpdateDetectionImageModal,
  ] = useOnOff();

  const imageNode = (() => {
    if (rapidIdentificationImage) {
      const img = (
        <AlertDocumentRapidIdentificationImage
          url={rapidIdentificationImage.url}
        />
      );
      if (isDraft) {
        assert(draft);
        if (hasAlertUpdatePermission) {
          const actions = (
            <div className="flex gap-2 self-end">
              <Button
                className="pointer-events-auto"
                roundness="circular"
                color="primary"
                onClick={() => openUpdateDetectionImageModal()}
              >
                <PencilIcon className="h-5 w-5" />
              </Button>
              <Button
                className="pointer-events-auto"
                roundness="circular"
                color="danger"
                onClick={() => openDeleteDetectionImageModal()}
              >
                <TrashIcon className="h-5 w-5" />
              </Button>
            </div>
          );
          return (
            <div className="group static">
              <div className="relative mx-3 min-h-[200px] break-inside-avoid">
                {img}
                <CompleteOverlay>
                  <T
                    keyName="series.alert.field.rapid_identification_image"
                    language={translation?.language}
                    ns="alert"
                  />
                  <div className="flex-1" />
                  {actions}
                </CompleteOverlay>
              </div>
            </div>
          );
        } else {
          return img;
        }
      } else {
        return img;
      }
    } else if (isDraft && hasAlertUpdatePermission) {
      return (
        <div
          className="p-3 print:hidden"
          onClick={() => {
            openUpdateDetectionImageModal();
          }}
        >
          <EmptyStateButton
            icon={<RectangleGroupIcon />}
            description="Add a rapid detection image"
          />
        </div>
      );
    }
  })();

  return (
    <>
      {imageNode}
      {draft && (
        <>
          <EditRapidDetectionImageDialog
            isOpen={showUpdateDetectionImageModal}
            onClose={closeUpdateDetectionImageModal}
            id={props.alert.id}
            draftId={draft.id}
          />
          <DeleteRapidDetectionImageModal
            isOpen={showDeleteDetectionImageModal}
            onClose={closeDeleteDetectionImageModal}
            id={props.alert.id}
            draftId={draft.id}
          />
        </>
      )}
    </>
  );
}
