class AssertError extends Error {}

export function assert(
  value: unknown,
  message = `value ${String(value)} is falsy`,
): asserts value {
  if (!value) {
    throw new AssertError(message);
  }
}
