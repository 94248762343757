import { SeriesExchangeExtractor } from '@zakodium/profid-shared';
import { FormattedDate } from 'react-intl';

import { CardLayout } from '#ui/card_layout';
import { FormattedDefinitionList } from '#ui/definition_list';

export interface MetadataCardProps {
  extractor: SeriesExchangeExtractor;
}

export function MetadataCard(props: MetadataCardProps) {
  const metadata = props.extractor.getMetadata();

  return (
    <CardLayout title="series_exchange.metadata.title">
      <FormattedDefinitionList
        data={{
          'series_exchange.metadata.version': metadata.version,
          'page.series.view.import_metadata.exported_at': (
            <FormattedDate value={metadata.exportedAt} />
          ),
        }}
      />
    </CardLayout>
  );
}
