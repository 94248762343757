import { useTranslate } from '@tolgee/react';

import { useSeriesContext } from '../../contexts/series';
import { documentTypeValues, fraudTypeValues } from '../../graphql/enumValues';
import { useHasPermission } from '../../hooks/permissions';
import { SeriesFilters } from '../../stores/searches/SeriesFiltersStore';
import {
  safeTranslationKeyAlertDraft,
  safeTranslationKeyAlertStatus,
} from '../../translationsMapping/alerts';
import {
  TRANSLATIONS_KEY_DOCUMENT_FRAUD_TYPE,
  TRANSLATIONS_KEY_DOCUMENT_TYPE,
} from '../../translationsMapping/documents';
import SelectList, { SelectItem } from '../SelectList';
import CountryFilterSelectList from '../TableSearch/CountryFilterSelectList';
import FacetGraphqlEnumFilterSelectList from '../TableSearch/FacetGraphqlEnumFilterSelectList';
import IfHasPermission from '../permissions/IfHasPermission';

import {
  AlertStatus,
  DocumentType,
  FraudType,
  GqlFacetSearchResult,
} from '#gql';
import { SimpleListContainer, SimpleListContainerItem } from '#tailwind_ui';

interface SeriesSearchFilterProps {
  facets: GqlFacetSearchResult[];
}

export default function SeriesSearchFilter({
  facets,
}: SeriesSearchFilterProps) {
  const { filters, store } = useSeriesContext();

  return (
    <SimpleListContainer>
      <SimpleListContainerItem className="space-y-2">
        <FacetGraphqlEnumFilterSelectList<
          DocumentType,
          SeriesFilters,
          'docType'
        >
          facets={facets}
          prop="docType"
          name="doc.field.type"
          enumValues={documentTypeValues}
          onAdd={store.addFilter}
          onRemove={store.removeFilter}
          value={filters.docType}
          translationMap={TRANSLATIONS_KEY_DOCUMENT_TYPE}
        />
        <FacetGraphqlEnumFilterSelectList<
          FraudType,
          SeriesFilters,
          'docFraudType'
        >
          facets={facets}
          prop="docFraudType"
          name="doc.field.fraud_type"
          enumValues={fraudTypeValues}
          onAdd={store.addFilter}
          onRemove={store.removeFilter}
          value={filters.docFraudType}
          translationMap={TRANSLATIONS_KEY_DOCUMENT_FRAUD_TYPE}
        />
        <CountryFilterSelectList<SeriesFilters, 'docCountry'>
          facets={facets}
          prop="docCountry"
          name="doc.field.country"
          onAdd={store.addFilter}
          onRemove={store.removeFilter}
          value={filters.docCountry}
        />
      </SimpleListContainerItem>
      <SimpleListContainerItem className="space-y-2">
        <AlertStatusSelectList />
        <IfHasPermission userPermission="alert_update">
          <AlertDraftSelectList />
        </IfHasPermission>
      </SimpleListContainerItem>
    </SimpleListContainer>
  );
}

function AlertStatusSelectList() {
  const context = useSeriesContext();
  const canUpdateSerie = useHasPermission('alert_update');

  const { t } = useTranslate();

  const options: Array<SelectItem<AlertStatus | null>> = [
    null,
    AlertStatus.INITIATED,
    AlertStatus.PUBLISHED,
    AlertStatus.ARCHIVED,
  ]
    .filter((status) => (canUpdateSerie ? true : status !== 'INITIATED'))
    .map((v) => ({
      value: v,
      optionLabel: t(safeTranslationKeyAlertStatus(v)),
      searchLabel: t(safeTranslationKeyAlertStatus(v)),
      displayLabel: t(safeTranslationKeyAlertStatus(v)),
    }));

  const onAdd = context.store.addFilter('alertStatus');
  const onRemove = context.store.removeFilter('alertStatus');

  const status = context.filters.alertStatus;
  const selected = options.filter((opt) => status.includes(opt.value));

  return (
    <SelectList<AlertStatus | null>
      name="alertStatus"
      placeholder={t('series.alert.status')}
      options={options}
      onAdd={(item) => onAdd(item.value)}
      onRemove={(option) => onRemove(option.value)}
      selected={selected}
    />
  );
}

function AlertDraftSelectList() {
  const context = useSeriesContext();

  const { t } = useTranslate();

  const options: Array<SelectItem<boolean>> = ([true, false] as const).map(
    (v) => ({
      value: v,
      optionLabel: t(safeTranslationKeyAlertDraft(v)),
      searchLabel: t(safeTranslationKeyAlertDraft(v)),
      displayLabel: t(safeTranslationKeyAlertDraft(v)),
    }),
  );

  const onAdd = context.store.addFilter('alertDraft');
  const onRemove = context.store.removeFilter('alertDraft');

  const drafts = context.filters.alertDraft ?? [];
  const selected = options.filter((opt) => drafts.includes(opt.value));

  return (
    <SelectList<boolean>
      name="alertDraft"
      placeholder={t('series.alert.draft')}
      options={options}
      onAdd={(item) => onAdd(item.value)}
      onRemove={(item) => onRemove(item.value)}
      selected={selected}
    />
  );
}
