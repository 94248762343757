// DO NOT CHANGE this algorithm definition, or it will break exports
import { BufferSource } from '../../types';

export function getCipherAlgorithm(iv: BufferSource) {
  return {
    name: 'AES-GCM',
    iv,
  } as const;
}

export interface CipherOptions {
  data: BufferSource;
  password: string;
  salt: BufferSource;
  iv: BufferSource;
}
