import ImgPreview from '../ImgPreview';

import placeholderImage from '#assets/img/placeholder_image_300x225.png';

export interface DocumentImagePreviewProps {
  image?: {
    document: {
      url: string;
    };
    documentThumb: {
      url: string;
    };
  } | null;
}

const empty = (
  <div>
    <img width="100%" src={placeholderImage} alt="none" />
  </div>
);

export function DocumentImagePreview({ image }: DocumentImagePreviewProps) {
  if (!image) return empty;

  return (
    <ImgPreview src={image.document.url} thumbSrc={image.documentThumb.url} />
  );
}
