import { ZoomImage } from '#tailwind_ui';

export interface AlertDocumentRapidIdentificationImageProps {
  url: string;
}

export function AlertDocumentRapidIdentificationImage(
  props: AlertDocumentRapidIdentificationImageProps,
) {
  return (
    <ZoomImage
      src={props.url}
      alt="Rapid Detection"
      className="mx-auto block max-h-[320px]"
    />
  );
}
