import { T } from '@tolgee/react';

import { Feature } from '../../constants';
import { useDocumentsContext } from '../../contexts/documents';
import {
  documentMediumValues,
  documentTypeValues,
  fraudTypeValues,
  printProcessValues,
} from '../../graphql/enumValues';
import { DocumentFilters } from '../../stores/searches/DocumentFiltersStore';
import {
  TRANSLATIONS_KEY_DOCUMENT_FRAUD_TYPE,
  TRANSLATIONS_KEY_DOCUMENT_MEDIUM,
  TRANSLATIONS_KEY_DOCUMENT_PRINT_PROCESS,
  TRANSLATIONS_KEY_DOCUMENT_TYPE,
} from '../../translationsMapping/documents';
import DateRangePicker from '../DateRangePicker';
import IfHasFeature from '../IfHasFeature';
import CountryFilterSelectList from '../TableSearch/CountryFilterSelectList';
import FacetGraphqlEnumFilterSelectList from '../TableSearch/FacetGraphqlEnumFilterSelectList';

import { DocumentViewSelect } from './search/DocumentViewSelect';
import IsBackgroundFluoSelectList from './search/IsBackgroundFluoFilterSelectList';
import IsBitmapCodeFilterSelectList from './search/IsBitmapCodeFilterSelectList';
import IsMrzOkFilterSelectList from './search/IsMrzOkFilterSelectList';
import LabelFilterSelectList from './search/LabelFilterSelectList';
import SeriesFacetFilterSelectList from './search/SeriesFacetFilterSelectList';
import ServiceFilterSelectList from './search/ServiceFilterSelectList';

import {
  DocumentMedium,
  DocumentType,
  FraudType,
  GqlFacetSearchResult,
  PrintProcess,
} from '#gql';
import {
  SimpleListContainer,
  SimpleListContainerItem,
  Tooltip,
} from '#tailwind_ui';

interface DocumentSearchFilterProps {
  facets: GqlFacetSearchResult[];
}

export default function DocumentSearchFilter(props: DocumentSearchFilterProps) {
  const { facets } = props;
  const { filters, store } = useDocumentsContext();

  return (
    <SimpleListContainer>
      <SimpleListContainerItem className="space-y-2">
        <LabelFilterSelectList<DocumentFilters, 'labels'>
          prop="labels"
          name="global.labels"
          facets={facets}
          onAdd={store.addFilter}
          onRemove={store.removeFilter}
          value={filters.labels}
        />
        <ServiceFilterSelectList<DocumentFilters, 'caseService'>
          prop="caseService"
          name="global.service"
          facets={facets}
          onAdd={store.addFilter}
          onRemove={store.removeFilter}
          value={filters.caseService}
        />
        <FacetGraphqlEnumFilterSelectList<
          DocumentType,
          DocumentFilters,
          'docType'
        >
          facets={facets}
          prop="docType"
          name="doc.field.type"
          enumValues={documentTypeValues}
          translationMap={TRANSLATIONS_KEY_DOCUMENT_TYPE}
          onAdd={store.addFilter}
          onRemove={store.removeFilter}
          value={filters.docType}
        />
        <FacetGraphqlEnumFilterSelectList<
          FraudType,
          DocumentFilters,
          'docFraudType'
        >
          facets={facets}
          prop="docFraudType"
          name="doc.field.fraud_type"
          translationMap={TRANSLATIONS_KEY_DOCUMENT_FRAUD_TYPE}
          enumValues={fraudTypeValues}
          onAdd={store.addFilter}
          onRemove={store.removeFilter}
          value={filters.docFraudType}
        />
        <CountryFilterSelectList<DocumentFilters, 'docCountry'>
          facets={facets}
          prop="docCountry"
          name="doc.field.country"
          onAdd={store.addFilter}
          onRemove={store.removeFilter}
          value={filters.docCountry}
        />
        <IfHasFeature feature={Feature.series}>
          <SeriesFacetFilterSelectList<DocumentFilters, 'series'>
            facets={facets}
            prop="series"
            name="global.series"
            nullOptionLabel="global.series_none"
            onAdd={store.addFilter}
            onRemove={store.removeFilter}
            value={filters.series}
          />
        </IfHasFeature>
        <CountryFilterSelectList<DocumentFilters, 'casePresumedNationality'>
          facets={facets}
          prop="casePresumedNationality"
          name="doc.field.presumed_nationality.short"
          value={filters.casePresumedNationality}
          onAdd={store.addFilter}
          onRemove={store.removeFilter}
        />
      </SimpleListContainerItem>
      <SimpleListContainerItem className="space-y-2">
        <DateRangePicker
          startPlaceholderId="doc.search.seizure_start"
          endPlaceholderId="doc.search.seizure_end"
          start={filters.seizureStart ?? undefined}
          end={filters.seizureEnd ?? undefined}
          onStartChange={store.setSeizureStart}
          onEndChange={store.setSeizureEnd}
        />
      </SimpleListContainerItem>
      <SimpleListContainerItem className="space-y-2">
        <Tooltip
          content={
            <T keyName="doc.search.filter.standardized_images.tooltip" />
          }
        >
          <div>
            <DocumentViewSelect
              name="scanView"
              label="doc.standardized_image"
              setSelected={(scanView) => store.mergeData({ scanView })}
              selected={filters.scanView}
            />
          </div>
        </Tooltip>
        <Tooltip
          content={<T keyName="doc.search.filter.extra_image.tooltip" />}
        >
          <div>
            <DocumentViewSelect
              name="extraImageView"
              label="doc.extra_image"
              setSelected={(extraImageView) =>
                store.mergeData({ extraImageView })
              }
              selected={filters.extraImageView}
            />
          </div>
        </Tooltip>
      </SimpleListContainerItem>
      <SimpleListContainerItem className="space-y-2">
        <IsMrzOkFilterSelectList<DocumentFilters, 'docIsMrzOk'>
          facets={facets}
          prop="docIsMrzOk"
          name="doc.mrz"
          onAdd={store.addFilter}
          onRemove={store.removeFilter}
          value={filters.docIsMrzOk}
        />
        <FacetGraphqlEnumFilterSelectList<
          DocumentMedium,
          DocumentFilters,
          'docMedium'
        >
          facets={facets}
          prop="docMedium"
          name="doc.field.medium"
          enumValues={documentMediumValues}
          translationMap={TRANSLATIONS_KEY_DOCUMENT_MEDIUM}
          onAdd={store.addFilter}
          onRemove={store.removeFilter}
          value={filters.docMedium}
        />
        <FacetGraphqlEnumFilterSelectList<
          PrintProcess,
          DocumentFilters,
          'docPrintBackground'
        >
          facets={facets}
          prop="docPrintBackground"
          translationMap={TRANSLATIONS_KEY_DOCUMENT_PRINT_PROCESS}
          name="doc.field.print_background"
          enumValues={printProcessValues}
          onAdd={store.addFilter}
          onRemove={store.removeFilter}
          value={filters.docPrintBackground}
        />
        <FacetGraphqlEnumFilterSelectList<
          PrintProcess,
          DocumentFilters,
          'docPrintNumber'
        >
          facets={facets}
          prop="docPrintNumber"
          translationMap={TRANSLATIONS_KEY_DOCUMENT_PRINT_PROCESS}
          name="doc.field.print_number"
          enumValues={printProcessValues}
          onAdd={store.addFilter}
          onRemove={store.removeFilter}
          value={filters.docPrintNumber}
        />
        <FacetGraphqlEnumFilterSelectList<
          PrintProcess,
          DocumentFilters,
          'docPrintData'
        >
          facets={facets}
          prop="docPrintData"
          translationMap={TRANSLATIONS_KEY_DOCUMENT_PRINT_PROCESS}
          name="doc.field.print_data"
          enumValues={printProcessValues}
          onAdd={store.addFilter}
          onRemove={store.removeFilter}
          value={filters.docPrintData}
        />
        <FacetGraphqlEnumFilterSelectList<
          PrintProcess,
          DocumentFilters,
          'docPrintPhoto'
        >
          facets={facets}
          prop="docPrintPhoto"
          translationMap={TRANSLATIONS_KEY_DOCUMENT_PRINT_PROCESS}
          name="doc.field.print_photo"
          enumValues={printProcessValues}
          onAdd={store.addFilter}
          onRemove={store.removeFilter}
          value={filters.docPrintPhoto}
        />
        <IsBackgroundFluoSelectList<DocumentFilters, 'docIsBackgroundFluo'>
          facets={facets}
          prop="docIsBackgroundFluo"
          name="doc.field.is_background_fluo"
          onAdd={store.addFilter}
          onRemove={store.removeFilter}
          value={filters.docIsBackgroundFluo}
        />
        <IsBitmapCodeFilterSelectList<DocumentFilters, 'docIsBitmapCode'>
          facets={facets}
          prop="docIsBitmapCode"
          name="doc.field.doc_is_bitmap_code"
          onAdd={store.addFilter}
          onRemove={store.removeFilter}
          value={filters.docIsBitmapCode}
        />
      </SimpleListContainerItem>
    </SimpleListContainer>
  );
}
