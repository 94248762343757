import * as i18nCountries from 'i18n-iso-countries';
import { Alpha3Code } from 'i18n-iso-countries';

import { assert } from '../../assert';
import { Language } from '../../generated/graphql_enums';

export * as i18nCountries from 'i18n-iso-countries';

const countryMapCache: Partial<
  Record<Language, Record<Alpha3Code | 'XXX', string>>
> = {};
export async function getCountryMap(locale: Language) {
  if (countryMapCache[locale]) return countryMapCache[locale];

  await registerLocale(locale);
  const countries = buildCountryMap(locale);
  countryMapCache[locale] = countries;

  return countries;
}

export type CountryCodeAndName = [code: Alpha3Code | 'XXX', name: string];
const countryListCache: Partial<Record<Language, CountryCodeAndName[]>> = {};
export async function getCountryList(locale: Language) {
  const cacheValue = countryListCache[locale];
  if (cacheValue) return cacheValue;

  const map = await getCountryMap(locale);
  const list = Object.entries(map) as CountryCodeAndName[];
  countryListCache[locale] = list;

  return list;
}

async function registerLocale(locale: Language) {
  const data = await (() => {
    switch (locale) {
      case 'en':
        return import('i18n-iso-countries/langs/en.json');
      case 'fr':
        return import('i18n-iso-countries/langs/fr.json');
      case 'de':
        return import('i18n-iso-countries/langs/de.json');
      case 'nl':
        return import('i18n-iso-countries/langs/nl.json');
      case 'it':
        return import('i18n-iso-countries/langs/it.json');
      default:
        throw new Error('unknown locale');
    }
  })();
  i18nCountries.registerLocale(data.default);
}

const unknownNationality: Record<Language, string> = {
  en: 'Unknown nationality',
  fr: 'Nationalité inconnue',
  de: 'Unbekannte Nationalität',
  nl: 'Onbekende nationaliteit',
  it: 'Nazionalità sconosciuta',
};
function buildCountryMap(locale: Language) {
  const map: Record<Alpha3Code | 'XXX', string> = {} as unknown as Record<
    Alpha3Code | 'XXX',
    string
  >;

  for (const code in i18nCountries.getAlpha3Codes()) {
    const name = i18nCountries.getName(code, locale);
    assert(name, `no country name for this code ${code}`);
    map[code as Alpha3Code] = name;
  }

  map.XXX = unknownNationality[locale];

  return map;
}

const alphaCodesCache = new Set(Object.keys(i18nCountries.getAlpha3Codes()));
export function isValidCountryCode(code: string): boolean {
  if (code === 'XXX') return true;

  return alphaCodesCache.has(code);
}
