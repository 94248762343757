import { IOBuffer } from 'iobuffer';

import { BufferSource } from '../../types';

export interface ParseReturn {
  salt: Uint8Array;
  iv: Uint8Array;
  encryptedData: Uint8Array;
}

export function parse(encryptedDataWithHeader: BufferSource): ParseReturn {
  const buffer = new IOBuffer(encryptedDataWithHeader);

  if (!buffer.available(64)) {
    throw new Error('Data too short, cannot contain complete header and data');
  }

  // the first 32 bytes contain the file id and version
  // they are already parsed to dispatch to this function
  buffer.seek(32);
  const salt = buffer.readBytes(16);
  const iv = buffer.readBytes(16);

  const encryptedData = new Uint8Array(
    buffer.buffer,
    buffer.byteOffset + buffer.offset,
    buffer.byteLength - buffer.offset,
  );

  return {
    salt,
    iv,
    encryptedData,
  };
}
