import {
  MinusIcon,
  PlusIcon,
  TagIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import { ForwardedRef, forwardRef, useState } from 'react';
import { FormattedNumber } from 'react-intl';
import { useBoolean } from 'usehooks-ts';

import IfHasPermission from '../../../../components/permissions/IfHasPermission';
import FormattedErrorAlert from '../../../../components/translation/FormattedErrorAlert';
import { useTableSelection } from '../../../../hooks/useTableSelection';

import { LabelsPicker } from './picker';

import { FormattedButton } from '#intl';
import {
  Badge,
  Button,
  ButtonProps,
  Color,
  DialogBody,
  DialogFooter,
  DialogRoot,
  DialogTitle,
} from '#tailwind_ui';

export interface ModalLabelsPickerProps {
  // button props
  label: TranslationKey;
  disabled?: boolean;
  badgeValue: number;

  // modal props
  modalHeader: TranslationKey;

  // callbacks, error management done in the component
  onBatchAdd: (labels: Set<string>) => Promise<void>;
  onBatchRemove: (labels: Set<string>) => Promise<void>;
}

export function ModalLabelsPicker(props: ModalLabelsPickerProps) {
  const {
    label,
    disabled,
    badgeValue,
    modalHeader,
    onBatchAdd,
    onBatchRemove,
  } = props;

  const modal = useBoolean();
  const selection = useTableSelection<string>();
  const [error, setError] = useState<Error | null>(null);

  const buttonProps: ModalLabelsPickerButtonProps = {
    label,
    badgeValue,
    onClick: modal.setTrue,
    disabled,
  };

  function onLabelsAdd() {
    const labels = selection.value;
    Promise.resolve(onBatchAdd(labels))
      .then(() => {
        selection.methods.clear();
        modal.setFalse();
        setError(null);
      })
      .catch((error) => {
        reportError(error);
        setError(error);
      });
  }

  function onLabelsRemove() {
    const labels = selection.value;
    Promise.resolve(onBatchRemove(labels))
      .then(() => {
        selection.methods.clear();
        modal.setFalse();
        setError(null);
      })
      .catch((error) => {
        reportError(error);
        setError(error);
      });
  }

  return (
    <IfHasPermission userPermission="document_update">
      <DialogRoot
        open={modal.value}
        onOpenChange={modal.setValue}
        preventCloseOnInteractOutside
        size="small"
        icon={<TagIcon />}
        trigger={<DefaultButton {...buttonProps} />}
      >
        <DialogTitle>
          <T keyName={modalHeader} />
        </DialogTitle>
        <DialogBody className="flex flex-col gap-5">
          <LabelsPicker
            label={<T keyName="global.labels" />}
            selection={selection}
          />
          <FormattedErrorAlert error={error} onDismiss={() => setError(null)} />
        </DialogBody>
        <DialogFooter>
          <FormattedButton
            messageId="global.cancel"
            icon={<XMarkIcon className="h-5" />}
            color={Color.neutral}
            onClick={modal.setFalse}
          />

          <span className="flex-1" />

          <FormattedButton
            messageId="global.remove"
            icon={<MinusIcon className="h-5" />}
            color={Color.danger}
            disabled={selection.value.size === 0}
            onClick={onLabelsRemove}
          />
          <FormattedButton
            messageId="global.add"
            icon={<PlusIcon className="h-5" />}
            color={Color.primary}
            disabled={selection.value.size === 0}
            onClick={onLabelsAdd}
          />
        </DialogFooter>
      </DialogRoot>
    </IfHasPermission>
  );
}

export interface ModalLabelsPickerButtonProps {
  label: TranslationKey;
  disabled?: boolean;
  badgeValue: number;
  onClick: Exclude<ButtonProps['onClick'], undefined>;
}

const DefaultButton = forwardRef(
  (
    props: ModalLabelsPickerButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const { label, badgeValue, onClick, disabled } = props;

    return (
      <Button
        ref={ref}
        className="!m-0 !rounded-full !bg-transparent !p-0 !shadow-none"
        disabled={disabled}
        onClick={onClick}
      >
        <Badge
          variant="COLORED_BACKGROUND"
          label={
            <>
              <TagIcon className="mr-2 h-5 w-5" />
              <T keyName={label} /> (
              <FormattedNumber value={badgeValue} />)
            </>
          }
          color="alternative"
          className="h-full"
        />
      </Button>
    );
  },
);
