import { T } from '@tolgee/react';
import { CSSProperties } from 'react';

import IfHasFeature from '../../../../components/IfHasFeature';
import DocumentLink from '../../../../components/document/DocumentLink';
import SeriesLink from '../../../../components/serie/SeriesLink';
import { FormattedTable } from '../../../../components/translation/FormattedTable';
import { Feature } from '../../../../constants';
import { TableSearchPagination } from '../../../../hooks/usePaginationTable';

import {
  GqlDocumentIdentifierFieldsFragment,
  GqlSerieIdentifierFieldsFragment,
} from '#gql';
import { Td, Th, ZoomImage } from '#tailwind_ui';

const imgStyle: CSSProperties = {
  borderRadius: '0.4rem',
  maxWidth: 200,
  maxHeight: 100,
};

export interface DocumentPadifSuggestionsTableRow {
  distance: number;
  document: {
    id: string;
    images: Array<{
      document: { url: string };
      documentThumb: { url: string };
    }>;
    series?: GqlSerieIdentifierFieldsFragment | null;
  } & GqlDocumentIdentifierFieldsFragment;
}

export interface DocumentPadifSuggestionsTableProps {
  data: DocumentPadifSuggestionsTableRow[];
  totalCount: number;
  tablePagination: TableSearchPagination;
}

export function DocumentPadifSuggestionsTable(
  props: DocumentPadifSuggestionsTableProps,
) {
  const { data, totalCount, tablePagination } = props;

  return (
    <FormattedTable
      renderHeader={renderHeader}
      renderTr={({ document, distance }) => {
        return (
          <tr key={document.id}>
            <Td className="align-middle">
              <div style={imgStyle}>
                <ZoomImage
                  alt="document preview"
                  src={document.images[0].document.url}
                  thumbSrc={document.images[0].documentThumb.url}
                  className="max-h-full max-w-full"
                />
              </div>
            </Td>
            <Td className="align-middle">
              <DocumentLink document={document} multiline includeService />
            </Td>
            <IfHasFeature feature={Feature.series}>
              <Td className="align-middle">
                <SeriesLink series={document.series} multiline />
              </Td>
            </IfHasFeature>
            <Td className="align-middle">{distance.toFixed(2)}</Td>
          </tr>
        );
      }}
      data={data}
      pagination={{
        ...tablePagination,
        totalCount,
      }}
    />
  );
}

function renderHeader() {
  return (
    <tr>
      <Th className="w-[200px]">
        <T keyName="global.image" />
      </Th>
      <Th>
        <T keyName="global.exhibit" />
      </Th>
      <IfHasFeature feature={Feature.series}>
        <Th>
          <T keyName="global.series" />
        </Th>
      </IfHasFeature>
      <Th className="w-1/3">
        <T keyName="global.distance" />
      </Th>
    </tr>
  );
}
