import { TrashIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import { useWatch } from 'react-hook-form';

import { requiredObject, requiredString } from '../../forms/validation';
import FormattedFormError from '../translation/FormattedFormError';
import FormattedInputField from '../translation/FormattedInputField';

import { GqlSerieQuery, useDeleteSerieMutation } from '#gql';
import {
  FormattedAlert,
  FormattedDialogCancelButton,
  FormattedSubmitButton,
} from '#intl';
import {
  FormRHFDialogBody,
  FormRHFDialogFooter,
  FormRHFDialogRoot,
  FormRHFDialogTitle,
  useNotificationCenter,
} from '#tailwind_ui';

const validationSchema = requiredObject({
  name: requiredString,
  reason: requiredString,
});

const defaultValues = { name: '', reason: '' };

export default function DeleteSerieModal(props: {
  serie: GqlSerieQuery['serie'];
  onSuccess: () => void;
  onRequestClose: () => void;
}) {
  const { serie, onSuccess, onRequestClose } = props;
  const [deleteSerie] = useDeleteSerieMutation();
  const { addNotification } = useNotificationCenter();

  async function handleSubmit(values: typeof defaultValues) {
    await deleteSerie({
      variables: { input: { id: serie.id, reason: values.reason } },
    });

    addNotification({
      type: 'success',
      title: <T keyName="series.delete.success" />,
    });

    onSuccess();
  }

  return (
    <FormRHFDialogRoot
      open
      onOpenChange={onRequestClose}
      defaultValues={defaultValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      icon={<TrashIcon />}
      iconColor="danger"
    >
      <FormRHFDialogTitle>
        <T keyName="series.delete.title" params={{ name: serie.name }} />
      </FormRHFDialogTitle>
      <FormRHFDialogBody>
        <FormattedAlert type="warning" messageId="series.delete.alert" />

        <FormattedInputField
          name="name"
          label="series.delete.field.name.label"
          labelValues={{ name: serie.name }}
        />

        <FormattedInputField
          name="reason"
          label="series.delete.field.reason.label"
        />

        <FormattedFormError />
      </FormRHFDialogBody>
      <FormRHFDialogFooter>
        <DeleteSerieFooter serieName={serie.name} />
      </FormRHFDialogFooter>
    </FormRHFDialogRoot>
  );
}

interface DeleteSerieFooterProps {
  serieName: string;
}

function DeleteSerieFooter(props: DeleteSerieFooterProps) {
  const { serieName } = props;
  const name: string = useWatch({ name: 'name' });

  return (
    <>
      <FormattedDialogCancelButton />
      <FormattedSubmitButton
        disabled={name !== serieName}
        messageId="global.delete"
        color="danger"
      />
    </>
  );
}
