import { TrashIcon } from '@heroicons/react/24/outline';

import { AsyncFormattedConfirmModal } from '../AsyncFormattedConfirmModal';

import { useDeleteDocumentImageMutation } from '#gql';
import { FormattedAlert, FormattedButton } from '#intl';
import { useOnOff } from '#tailwind_ui';

interface ImageDeleteProps {
  docId: string;
  imageId: string;
}

export default function ImageDelete(props: ImageDeleteProps) {
  const { docId, imageId } = props;

  const [isDeleting, openModal, closeModal] = useOnOff();
  const [deleteDocumentImage] = useDeleteDocumentImageMutation();

  return (
    <>
      <FormattedButton
        color="danger"
        onClick={openModal}
        className="pointer-events-auto"
        icon={<TrashIcon className="h-5 w-5" />}
        messageId="global.delete"
      />

      <AsyncFormattedConfirmModal
        icon={<TrashIcon />}
        onConfirm={() =>
          deleteDocumentImage({
            variables: { input: { id: docId, imageId } },
          })
        }
        onClose={closeModal}
        title="doc.image.delete.title"
        confirmText="global.yes"
        cancelText="global.no"
        open={isDeleting}
        body={
          <FormattedAlert type="warning" messageId="doc.image.delete.alert" />
        }
      />
    </>
  );
}
