import { PencilSquareIcon } from '@heroicons/react/20/solid';
import {
  ArchiveBoxIcon,
  ArchiveBoxXMarkIcon,
  ArrowDownTrayIcon,
  LanguageIcon,
  RectangleGroupIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';
import { TrashIcon } from '@heroicons/react/24/solid';
import { T, useTranslate } from '@tolgee/react';
import { ComponentType, ReactNode, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useHasPermission } from '../../../../hooks/permissions';
import { useTolgeeCacheLoad } from '../../../../hooks/useTolgeeCacheLoad';
import {
  safeTranslationKeyGlobalLanguageAdjective,
  safeTranslationKeyGlobalLanguageInSentence,
} from '../../../../translationsMapping/global';
import DownloadPdfAction from '../../DownloadPdfAction';
import { useAlertDraftParams } from '../../hooks/useAlertParams';
import ArchiveAlertModal from '../../modals/ArchiveAlertModal';
import CreateAlertDraftTranslationDialog from '../../modals/CreateAlertDraftTranslationDialog';
import DeleteAlertDraftLanguageModal from '../../modals/DeleteAlertDraftLanguageModal';
import DeleteAlertDraftModal from '../../modals/DeleteAlertDraftModal';
import DeleteAlertModal from '../../modals/DeleteAlertModal';
import EditRapidDetectionImageDialog from '../../modals/EditRapidDetectionImageDialog';
import PublishAlertDraftModal from '../../modals/PublishDraftModal';
import UnarchiveAlertModal from '../../modals/UnarchiveAlertModal';
import { getDownloadPdfUrl } from '../../utils/alertPdf';
import {
  getAlertDraftEditUrl,
  getAlertDraftViewUrl,
  getAlertPublicationViewUrl,
} from '../../utils/navigation';

import { GqlAlertDraftQuery } from '#gql';
import {
  assertUnreachable,
  ButtonGroup,
  ButtonGroupButton,
  ButtonGroupDropdown,
  DropdownActionOption,
  useOnOff,
} from '#tailwind_ui';
import { assert } from '#utils/assert';

type AlertActions =
  | 'download-pdf'
  | 'delete-alert'
  | 'discard-draft'
  | 'archive-alert'
  | 'unarchive-alert'
  | 'publish-draft'
  | 'add-language'
  | 'delete-draft-language'
  | 'edit-rapid-detection-image';

export default function AlertDraftActions(props: {
  alert: GqlAlertDraftQuery['alert'];
  seriesSeqId: string;
}) {
  const { alert, seriesSeqId } = props;
  const draft = alert.draft;
  assert(draft);

  const { t } = useTranslate();

  const navigate = useNavigate();
  const [showDiscardDraftModal, openDiscardDraftModal, closeDiscardDraftModal] =
    useOnOff();

  const [showArchiveAlertModal, openArchiveAlertModal, closeArchiveAlertModal] =
    useOnOff();

  const [showDeleteAlertModal, openDeleteAlertModal, closeDeleteAlertModal] =
    useOnOff();

  const [
    showUnarchiveAlertModal,
    openUnarchiveAlertModal,
    closeUnarchiveAlertModal,
  ] = useOnOff();

  const [
    showPublishAlertDraftModal,
    openPublishAlertDraftModal,
    closePublishAlertDraftModal,
  ] = useOnOff();

  const [
    showCreateAlertDraftTranslationModal,
    openCreateAlertDraftTranslationModal,
    closeCreateAlertDraftTranslationModal,
  ] = useOnOff();

  const [
    showDeleteAlertDraftTranslationModal,
    openDeleteAlertDraftTranslationModal,
    closeDeleteAlertDraftTranslationModal,
  ] = useOnOff();

  const [
    showUpdateDetectionImageModal,
    openUpdateDetectionImageModal,
    closeUpdateDetectionImageModal,
  ] = useOnOff();

  const canUpdateAlert = useHasPermission('alert_update');
  const canDeleteAlert = useHasPermission('alert_draft_delete');
  const { language } = useAlertDraftParams();
  useTolgeeCacheLoad(draft.translation.language, 'alert');
  const hasOneLanguage = draft.languages.length === 1;

  const options = useMemo<
    Array<Array<DropdownActionOption<AlertActions>>>
  >(() => {
    if (alert.status === 'ARCHIVED') {
      return [
        [
          canUpdateAlert && {
            type: 'action',
            label: (
              <T keyName="series.alert.actions.edit_rapid_identification_image" />
            ),
            data: 'edit-rapid-detection-image',
            icon: <RectangleGroupIcon />,
          },
          canUpdateAlert && {
            type: 'action',
            label: <T keyName="series.alert.actions.unarchive_alert" />,
            data: 'unarchive-alert',
            icon: <ArchiveBoxXMarkIcon />,
          },
        ].filter(Boolean) as Array<DropdownActionOption<AlertActions>>,
      ];
    }

    return [
      [
        canUpdateAlert && {
          type: 'action',
          label: (
            <div className="text-success-700">
              <T keyName="series.alert.actions.publish_draft" />
            </div>
          ),
          data: 'publish-draft',
          icon: <UserGroupIcon className="text-success-700" />,
        },
      ].filter(Boolean) as Array<DropdownActionOption<AlertActions>>,
      [
        canUpdateAlert && {
          type: 'action',
          label: (
            <T keyName="series.alert.actions.edit_rapid_identification_image" />
          ),
          data: 'edit-rapid-detection-image',
          icon: <RectangleGroupIcon />,
        },
        canUpdateAlert && {
          type: 'action',
          label: <T keyName="series.alert.actions.add_language" />,
          icon: <LanguageIcon />,
          data: 'add-language',
        },
        canUpdateAlert &&
          alert.status === 'PUBLISHED' && {
            type: 'action',
            label: <T keyName="series.alert.actions.archive_alert" />,
            icon: <ArchiveBoxIcon />,
            data: 'archive-alert',
          },
        {
          type: 'action',
          label: t('series.alert.download_pdf.with_lang', {
            lang: (
              <T
                key="lang"
                keyName={safeTranslationKeyGlobalLanguageInSentence(
                  draft.translation.language,
                )}
              />
            ),
          }),
          data: 'download-pdf',
          icon: <ArrowDownTrayIcon />,
        },
      ].filter(Boolean) as Array<DropdownActionOption<AlertActions>>,
      [
        canUpdateAlert &&
          !hasOneLanguage && {
            type: 'action',
            icon: <LanguageIcon className="text-warning-600" />,
            label: (
              <div className="text-warning-600 first-letter:capitalize">
                {t('series.alert.actions.delete_draft_language', {
                  lang: (
                    <T
                      key="lang"
                      keyName={safeTranslationKeyGlobalLanguageAdjective(
                        draft.translation.language,
                      )}
                    />
                  ),
                })}
              </div>
            ),
            data: 'delete-draft-language',
          },
        canDeleteAlert &&
          alert.status === 'INITIATED' && {
            type: 'action',
            label: (
              <div className="text-danger-600">
                <T keyName="series.alert.actions.delete_alert" />
              </div>
            ),
            data: 'delete-alert',
            icon: <TrashIcon className="text-danger-600" />,
          },
        canUpdateAlert &&
          alert.status !== 'INITIATED' && {
            type: 'action',
            label: (
              <div className="text-warning-600">
                <T keyName="series.alert.actions.discard_draft" />
              </div>
            ),
            data: 'discard-draft',
            icon: <TrashIcon className="text-warning-600" />,
          },
      ].filter(Boolean) as Array<DropdownActionOption<AlertActions>>,
    ].filter((options) => options.length > 0);
  }, [
    alert.status,
    canUpdateAlert,
    canDeleteAlert,
    draft.translation.language,
    t,
    hasOneLanguage,
  ]);

  function renderAction(
    Component: ComponentType,
    option: DropdownActionOption<AlertActions>,
  ): ReactNode {
    if (option.data !== 'download-pdf' || !draft) return <Component />;

    return (
      <DownloadPdfAction
        href={getDownloadPdfUrl({
          alertId: alert.id,
          draftOrPublicationId: draft.id,
          language,
        })}
      >
        <Component />
      </DownloadPdfAction>
    );
  }

  return (
    <>
      <ButtonGroup>
        <ButtonGroupButton
          as={Link}
          to={getAlertDraftEditUrl(alert.id, draft.translation.language)}
          className="flex items-center gap-2"
        >
          <PencilSquareIcon className="h-5 w-5" />
          <T keyName="series.alert.actions.edit_draft_fields" />
        </ButtonGroupButton>
        <ButtonGroupDropdown
          renderAction={renderAction}
          options={options}
          onSelect={(action) => {
            assert(action.data);
            switch (action.data) {
              case 'download-pdf':
                break;
              case 'delete-alert':
                openDeleteAlertModal();
                break;
              case 'discard-draft':
                openDiscardDraftModal();
                break;
              case 'archive-alert':
                openArchiveAlertModal();
                break;
              case 'unarchive-alert':
                openUnarchiveAlertModal();
                break;
              case 'publish-draft':
                openPublishAlertDraftModal();
                break;
              case 'add-language':
                openCreateAlertDraftTranslationModal();
                break;
              case 'delete-draft-language':
                openDeleteAlertDraftTranslationModal();
                break;
              case 'edit-rapid-detection-image':
                openUpdateDetectionImageModal();
                break;
              default:
                assertUnreachable(action.data);
            }
          }}
        />
      </ButtonGroup>
      <DeleteAlertDraftModal
        isOpen={showDiscardDraftModal}
        id={alert.id}
        draftId={draft.id}
        onClose={(success) => {
          closeDiscardDraftModal();
          if (success) {
            assert(alert.currentPublication);
            navigate(
              getAlertPublicationViewUrl(
                alert.id,
                alert.currentPublication.id,
                draft.translation.language,
              ),
              { replace: true },
            );
          }
        }}
      />
      <DeleteAlertModal
        isOpen={showDeleteAlertModal}
        id={alert.id}
        onClose={(success) => {
          closeDeleteAlertModal();
          if (success) {
            navigate(`/series/${seriesSeqId}`, { replace: true });
          }
        }}
      />
      <ArchiveAlertModal
        isOpen={showArchiveAlertModal}
        id={alert.id}
        onClose={closeArchiveAlertModal}
      />
      <UnarchiveAlertModal
        isOpen={showUnarchiveAlertModal}
        id={alert.id}
        onClose={() => {
          closeUnarchiveAlertModal();
        }}
      />
      <PublishAlertDraftModal
        isOpen={showPublishAlertDraftModal}
        id={alert.id}
        draftId={draft.id}
        hasMultipleLanguages={draft.languages.length > 1}
        onClose={(success) => {
          closePublishAlertDraftModal();
          if (success) {
            navigate(
              getAlertPublicationViewUrl(
                alert.id,
                draft.id,
                draft.translation.language,
              ),
            );
          }
        }}
      />
      <CreateAlertDraftTranslationDialog
        isOpen={showCreateAlertDraftTranslationModal}
        alert={alert}
        onClose={closeCreateAlertDraftTranslationModal}
      />
      <DeleteAlertDraftLanguageModal
        isOpen={showDeleteAlertDraftTranslationModal}
        onClose={(success) => {
          closeDeleteAlertDraftTranslationModal();
          if (success) {
            const nextLanguage = draft.languages.find(
              (lang) => lang !== draft.translation.language,
            );
            assert(nextLanguage);
            navigate(getAlertDraftViewUrl(alert.id, nextLanguage));
          }
        }}
        id={alert.id}
        draftId={draft.id}
        language={draft.translation.language}
      />
      <EditRapidDetectionImageDialog
        isOpen={showUpdateDetectionImageModal}
        onClose={closeUpdateDetectionImageModal}
        id={alert.id}
        draftId={draft.id}
      />
    </>
  );
}
