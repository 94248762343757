import { ChevronDoubleDownIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import { SerializedEditorState } from 'lexical';
import { useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useBoolean } from 'usehooks-ts';
import { array } from 'yup';

import {
  optionalString,
  requiredObject,
  requiredString,
} from '../../forms/validation';
import RemoveInList from '../form/RemoveInList';
import SelectUsers from '../form/SelectUsers';
import FormattedButton from '../translation/FormattedButton';
import FormattedFormError from '../translation/FormattedFormError';
import FormattedRichTextField from '../translation/FormattedRichTextField';

import EditableSerieContainer from './EditableSerieContainer';
import SelectSerie, {
  SelectSerieOption,
  SelectSerieProps,
} from './SelectSerie';
import { SerieHeadingBreadcrumb } from './SerieHeading';

import {
  GqlMergeSeriesInput,
  GqlSerieEditableQuery,
  useMergeSeriesMutation,
  useSerieEditableQuery,
} from '#gql';
import {
  FormattedAlert,
  FormattedDialogCancelButton,
  FormattedSubmitButton,
} from '#intl';
import { Dialog, FormRHF } from '#tailwind_ui';
import { H3 } from '#ui/heading';
import { PageLayout, PageLayoutNavigation } from '#ui/page_layout';

interface MergeSerieInnerProps {
  serie: GqlSerieEditableQuery['serie'];
}

function MergeSeriesInner(props: MergeSerieInnerProps) {
  const { serie } = props;

  const [otherSeries, setOtherSeries] = useState<SelectSerieOption>();

  return (
    <PageLayout
      title={
        <T
          keyName="series.merge.title"
          params={{ name: `${serie.seqId} | ${serie.name}` }}
        />
      }
      navigation={
        <>
          <PageLayoutNavigation to="/series">
            <T keyName="nav.series.list" />
          </PageLayoutNavigation>
          <PageLayoutNavigation to={`/series/${serie.seqId}`}>
            <SerieHeadingBreadcrumb serie={serie} />
          </PageLayoutNavigation>
        </>
      }
    >
      {otherSeries ? (
        <MergeWithOtherSeries
          seriesData={serie}
          otherSeries={otherSeries}
          onSelectOtherSeries={setOtherSeries}
        />
      ) : (
        <SelectOtherSeries
          name={serie.name}
          seqId={serie.seqId}
          otherSeries={otherSeries}
          onSelectOtherSeries={setOtherSeries}
        />
      )}
    </PageLayout>
  );
}

interface MergeWithOtherSeriesProps {
  seriesData: GqlSerieEditableQuery['serie'];
  otherSeries: SelectSerieOption;
  onSelectOtherSeries: SelectSerieProps['onSelect'];
}

function MergeWithOtherSeries(props: MergeWithOtherSeriesProps) {
  const { seriesData, otherSeries, onSelectOtherSeries } = props;
  const otherSeriesQuery = useSerieEditableQuery({
    variables: { seqId: otherSeries.seqId },
  });
  if (otherSeriesQuery.error) {
    throw otherSeriesQuery.error;
  } else if (otherSeriesQuery.loading && !otherSeriesQuery.data) {
    return <T keyName="global.loading" />;
  }
  if (!otherSeriesQuery.data) {
    return null;
  }

  const otherSeriesData = otherSeriesQuery.data.serie;

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-8">
        <H3>
          <T
            keyName="series.merge.merging.title"
            params={{ name: seriesData.name, otherName: otherSeriesData.name }}
          />
        </H3>
        <div className="w-64">
          <SelectSerie
            selected={otherSeries}
            onSelect={onSelectOtherSeries}
            excludeSeqId={seriesData.seqId}
          />
        </div>
      </div>
      <FormattedAlert type="info" messageId="series.merge.merging.info" />
      {seriesData.importedAt && otherSeriesData.importedAt && (
        <FormattedAlert
          className="whitespace-pre-line"
          type="warning"
          messageId="series.merge.merging.warn.import_conflict"
          messageValues={{
            code: seriesData.importMetadata?.code ?? '',
            otherName: otherSeriesData.name,
          }}
        />
      )}
      <MergeSeriesForm
        key={otherSeriesData.id}
        seriesData={seriesData}
        otherSeriesData={otherSeriesData}
      />
    </div>
  );
}

interface MergeSeriesFormProps {
  seriesData: GqlSerieEditableQuery['serie'];
  otherSeriesData: GqlSerieEditableQuery['serie'];
}

const mergeSeriesFormValidation = requiredObject({
  contextualProfile: optionalString,
  materialProfile: optionalString,
  aliases: array().of(requiredString),
  managers: array().of(
    requiredObject({
      value: requiredString,
      label: optionalString,
    }),
  ),
});

function MergeSeriesForm(props: MergeSeriesFormProps) {
  const { seriesData, otherSeriesData } = props;
  const defaultValues = useMergeSeriesDefaultValues(
    seriesData,
    otherSeriesData,
  );
  const dialog = useBoolean();
  const [mergeSeries] = useMergeSeriesMutation();
  const navigate = useNavigate();

  async function handleSubmit(values: typeof defaultValues) {
    const input: GqlMergeSeriesInput = {
      id: seriesData.id,
      otherSeriesId: otherSeriesData.id,
      managers: values.managers.map((manager) => manager.value),
      contextualProfile: values.contextualProfile,
      materialProfile: values.materialProfile,
      aliases: values.aliases,
    };

    try {
      await mergeSeries({ variables: { input } });
      navigate('./..');
    } finally {
      dialog.setFalse();
    }
  }

  return (
    <FormRHF<typeof defaultValues>
      id="merge-series-form"
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      validationSchema={mergeSeriesFormValidation}
    >
      <RemoveInList name="aliases" label="series.field.aliases" />
      <SelectUsers name="managers" label="series.field.managers" />
      <FormattedRichTextField
        name="contextualProfile"
        label="series.field.contextual_profile"
      />
      <FormattedRichTextField
        name="materialProfile"
        label="series.field.material_profile"
      />
      <FormattedFormError />
      <div className="flex gap-2">
        <FormattedButton
          as={Link}
          to="./.."
          messageId="global.cancel"
          variant="white"
        />

        <Dialog.Root
          open={dialog.value}
          onOpenChange={dialog.setValue}
          trigger={<FormattedButton messageId="global.merge" color="danger" />}
          icon={<ChevronDoubleDownIcon />}
          iconColor="danger"
        >
          <Dialog.Title>
            <T keyName="series.merge.merging.confirm" />
          </Dialog.Title>

          <Dialog.Body>
            <FormattedAlert
              type="warning"
              messageId="series.merge.merging.alert"
              messageValues={{
                name: seriesData.name,
                otherName: otherSeriesData.name,
              }}
            />
          </Dialog.Body>

          <Dialog.Footer>
            <FormattedDialogCancelButton />
            <FormattedSubmitButton
              form="merge-series-form"
              messageId="global.merge"
              color="danger"
            />
          </Dialog.Footer>
        </Dialog.Root>
      </div>
    </FormRHF>
  );
}

function useMergeSeriesDefaultValues(
  seriesData: GqlSerieEditableQuery['serie'],
  otherSeriesData: GqlSerieEditableQuery['serie'],
) {
  return useMemo(() => {
    return {
      contextualProfile: mergeRichText(
        seriesData.contextualProfile,
        otherSeriesData.contextualProfile,
      ),
      materialProfile: mergeRichText(
        seriesData.materialProfile,
        otherSeriesData.materialProfile,
      ),
      managers: removeDuplicates(
        seriesData.managers.concat(otherSeriesData.managers),
      ).map((user) => ({
        value: user.id,
        label: user.name,
      })),
      aliases: Array.from(
        new Set([
          otherSeriesData.name,
          ...(seriesData.aliases || []),
          ...(otherSeriesData.aliases || []),
        ]),
      ),
    };
  }, [seriesData, otherSeriesData]);
}

function mergeRichText(a: string | null, b: string | null) {
  if (a && b) {
    const aObject = JSON.parse(a) as SerializedEditorState;
    const bObject = JSON.parse(b) as SerializedEditorState;
    const mergedObject: SerializedEditorState = {
      root: {
        ...aObject.root,
        children: [...aObject.root.children, ...bObject.root.children],
      },
    };
    return JSON.stringify(mergedObject);
  } else {
    return a || b;
  }
}

function removeDuplicates(
  managers: GqlSerieEditableQuery['serie']['managers'],
) {
  return managers.filter((user, index) => {
    return managers.findIndex((u) => u.id === user.id) === index;
  });
}

interface SelectOtherSeriesProps {
  name: string;
  seqId: string;
  otherSeries?: SelectSerieProps['selected'];
  onSelectOtherSeries: SelectSerieProps['onSelect'];
}

function SelectOtherSeries(props: SelectOtherSeriesProps) {
  const { name, seqId, otherSeries, onSelectOtherSeries } = props;
  return (
    <div>
      <H3>
        <T keyName="series.merge.selecting.title" params={{ name }} />
      </H3>
      <div className="max-w-md">
        <SelectSerie
          selected={otherSeries}
          onSelect={onSelectOtherSeries}
          excludeSeqId={seqId}
        />
      </div>
    </div>
  );
}

export default function MergeSeries() {
  return (
    <EditableSerieContainer>
      {(serie) => <MergeSeriesInner serie={serie} />}
    </EditableSerieContainer>
  );
}
