import { PlusIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import { Fragment, useId, useMemo } from 'react';
import { useFieldArray } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { array } from 'yup';

import {
  optionalDate,
  optionalString,
  requiredNumber,
  requiredObject,
  requiredString,
} from '../../forms/validation';
import { getUserNameWithService } from '../../helpers/user';
import { useNavigateAfterSave } from '../../hooks/useNavigateAfterSave';
import { FormPreventNavigation } from '../form/FormPreventNavigation';
import RemoveInList from '../form/RemoveInList';
import SelectCountry from '../form/SelectCountry';
import { SelectDocumentType, SelectFraudType } from '../form/SelectFields';
import SelectUsers from '../form/SelectUsers';
import { useAppMeta } from '../providers/AppMetaProvider.context';
import FormattedButton from '../translation/FormattedButton';
import FormattedFormError from '../translation/FormattedFormError';
import FormattedLabel from '../translation/FormattedLabel';
import FormattedRichTextField from '../translation/FormattedRichTextField';
import FormattedSubmitButton from '../translation/FormattedSubmitButton';

import EditableSerieContainer from './EditableSerieContainer';
import { SerieHeadingBreadcrumb } from './SerieHeading';

import {
  GqlSerieEditableQuery,
  GqlUpdateSerieInput,
  useUpdateSerieMutation,
} from '#gql';
import {
  // eslint-disable-next-line no-restricted-imports
  DatePickerFieldRHF,
  // eslint-disable-next-line no-restricted-imports
  InputFieldRHF,
  SimpleStringSelectOption,
  useCheckedFormRHFContext,
  useOnOff,
} from '#tailwind_ui';
import { AccordionCard, CardLayout } from '#ui/card_layout';
import { PageLayout, PageLayoutNavigation } from '#ui/page_layout';

const validationSchema = requiredObject({
  docType: requiredString,
  docCountry: requiredString,
  docFraudType: requiredString,
  importDocumentCount: array().of(
    requiredObject({
      code: requiredString,
      value: requiredNumber.min(0),
    }),
  ),
  importFirstSeizureDate: array().of(
    requiredObject({
      code: requiredString,
      value: optionalDate,
    }),
  ),
  importLastSeizureDate: array().of(
    requiredObject({
      code: requiredString,
      value: optionalDate,
    }),
  ),
  contextualProfile: optionalString,
  materialProfile: optionalString,
});

interface EditSerieInnerProps {
  serie: GqlSerieEditableQuery['serie'];
}

type EditSerieValues = Omit<GqlUpdateSerieInput, 'managers'> & {
  managers: SimpleStringSelectOption[];
};

const FORM_ACTIONS = (
  <>
    <FormattedButton
      as={Link}
      to="./.."
      variant="white"
      messageId="global.cancel"
    />
    <FormattedSubmitButton messageId="global.save" color="primary" />
  </>
);

function entrySortFn(a: { code: string }, b: { code: string }) {
  return a.code.localeCompare(b.code);
}

function EditSerieInner(props: EditSerieInnerProps) {
  const { serie } = props;
  const { id, seqId } = serie;

  const app = useAppMeta();

  const defaultValues = useMemo<EditSerieValues>(
    () => ({
      id: serie.id,
      managers: serie.managers.map((user) => ({
        value: user.id,
        label: getUserNameWithService(user),
      })),
      contextualProfile: serie.contextualProfile,
      docCountry: serie.docCountry,
      docFraudType: serie.docFraudType,
      docType: serie.docType,
      materialProfile: serie.materialProfile,
      aliases: serie.aliases,
      importDocumentCount: serie.importDocumentCount
        .filter((entry) => entry.code !== app.code)
        .map((entry) => ({ code: entry.code, value: entry.value }))
        .sort(entrySortFn),
      importFirstSeizureDate: serie.importFirstSeizureDate
        .filter((entry) => entry.code !== app.code)
        .map((entry) => ({ code: entry.code, value: entry.value }))
        .sort(entrySortFn),
      importLastSeizureDate: serie.importLastSeizureDate
        .filter((entry) => entry.code !== app.code)
        .map((entry) => ({ code: entry.code, value: entry.value }))
        .sort(entrySortFn),
    }),
    [
      app.code,
      serie.aliases,
      serie.contextualProfile,
      serie.docCountry,
      serie.docFraudType,
      serie.docType,
      serie.id,
      serie.importDocumentCount,
      serie.importFirstSeizureDate,
      serie.importLastSeizureDate,
      serie.managers,
      serie.materialProfile,
    ],
  );

  const [saved, setSaved] = useOnOff();
  useNavigateAfterSave(saved, `/series/${seqId}`);

  const [updateSerie] = useUpdateSerieMutation();

  async function onSubmit(values: EditSerieValues) {
    await updateSerie({
      variables: {
        input: {
          id,
          contextualProfile: values.contextualProfile,
          docCountry: values.docCountry,
          docFraudType: values.docFraudType,
          docType: values.docType,
          managers: values.managers?.map((el) => el.value),
          materialProfile: values.materialProfile,
          aliases: values.aliases,
          importDocumentCount: values.importDocumentCount,
          importFirstSeizureDate: values.importFirstSeizureDate,
          importLastSeizureDate: values.importLastSeizureDate,
        },
      },
    });

    setSaved();
  }

  return (
    <FormPreventNavigation
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      noDefaultStyle
    >
      <PageLayout
        title={<T keyName="global.edit" />}
        navigation={
          <>
            <PageLayoutNavigation to="/series">
              <T keyName="nav.series.list" />
            </PageLayoutNavigation>
            <PageLayoutNavigation to={`/series/${seqId}`}>
              <SerieHeadingBreadcrumb serie={serie} />
            </PageLayoutNavigation>
          </>
        }
        actions={FORM_ACTIONS}
        footer={FORM_ACTIONS}
        footerClassName="space-x-2"
      >
        <CardLayout
          title="page.series.view.general_information"
          bodyClassName="flex flex-col gap-5"
        >
          <RemoveInList name="aliases" label="series.field.aliases" />
          <SelectDocumentType name="docType" required />
          <SelectFraudType name="docFraudType" required />
          <SelectCountry name="docCountry" label="doc.field.country" required />
          <SelectUsers name="managers" label="series.field.managers" />
        </CardLayout>

        <CardLayout title="series.field.contextual_profile">
          <FormattedRichTextField
            name="contextualProfile"
            label="series.field.contextual_profile"
            hiddenLabel
          />
        </CardLayout>

        <CardLayout title="series.field.material_profile">
          <FormattedRichTextField
            name="materialProfile"
            label="series.field.material_profile"
            hiddenLabel
          />
        </CardLayout>

        {serie.importedAt && (
          <CardLayout title="series.field.import">
            <SeizureDatesByCode />
            <DocumentCountByCode />
          </CardLayout>
        )}

        <FormattedFormError />
      </PageLayout>
    </FormPreventNavigation>
  );
}

function DocumentCountByCode() {
  const fields = useFieldArray<EditSerieValues>({
    name: 'importDocumentCount',
  });

  const id = useId();
  const codeId = `code-${id}`;
  const countId = `count-${id}`;

  return (
    <AccordionCard title="series.field.import.document_counts">
      <div className="grid grid-cols-2 gap-2">
        <FormattedLabel labelId={codeId} text="global.code" required />
        <FormattedLabel labelId={countId} text="global.value" required />

        {fields.fields.map((field, index) => (
          <Fragment key={field.id}>
            <InputFieldRHF
              label={undefined}
              name={`importDocumentCount.${index}.code`}
              aria-labelledby={codeId}
            />
            <InputFieldRHF
              label={undefined}
              name={`importDocumentCount.${index}.value`}
              type="number"
              aria-labelledby={countId}
            />
          </Fragment>
        ))}

        <div>
          <FormattedButton
            messageId="global.add_row"
            icon={<PlusIcon className="h-5" />}
            onClick={() => fields.append({ code: '', value: 0 })}
          />
        </div>
      </div>
    </AccordionCard>
  );
}

function SeizureDatesByCode() {
  const form = useCheckedFormRHFContext<EditSerieValues>();
  const first = useFieldArray<EditSerieValues>({
    name: 'importFirstSeizureDate',
  });
  const last = useFieldArray<EditSerieValues>({
    name: 'importLastSeizureDate',
  });

  const id = useId();
  const codeId = `code-${id}`;
  const dateFirstId = `first-${id}`;
  const dateLastId = `last-${id}`;

  return (
    <AccordionCard title="series.field.import.seizure_date">
      <div className="grid grid-cols-3 gap-2">
        <FormattedLabel labelId={codeId} text="global.code" required />
        <FormattedLabel
          labelId={dateFirstId}
          text="series.field.import.seizure_date.first"
        />
        <FormattedLabel
          labelId={dateLastId}
          text="series.field.import.seizure_date.last"
        />

        {first.fields.map((field, index) => (
          <Fragment key={field.id}>
            <InputFieldRHF
              label={undefined}
              name={`importFirstSeizureDate.${index}.code`}
              onChange={(e) =>
                last.update(index, {
                  ...form.getValues('importLastSeizureDate')?.at(index),
                  code: e.currentTarget.value,
                })
              }
              aria-labelledby={codeId}
            />
            <DatePickerFieldRHF
              label={undefined}
              name={`importFirstSeizureDate.${index}.value`}
              ariaLabelledBy={dateFirstId}
            />
            <DatePickerFieldRHF
              label={undefined}
              name={`importLastSeizureDate.${index}.value`}
              ariaLabelledBy={dateLastId}
            />
          </Fragment>
        ))}

        <div>
          <FormattedButton
            messageId="global.add_row"
            icon={<PlusIcon className="h-5" />}
            onClick={() => {
              first.append({ code: '', value: null });
              last.append({ code: '', value: null });
            }}
          />
        </div>
      </div>
    </AccordionCard>
  );
}

export default function EditSerie() {
  return (
    <EditableSerieContainer>
      {(serie) => <EditSerieInner serie={serie} />}
    </EditableSerieContainer>
  );
}
