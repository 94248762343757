import { PadifAggregationMethod } from '@zakodium/profid-shared';
import { useMemo, useState } from 'react';

import { UsePaginationTable } from '../../../../hooks/usePaginationTable';

import { DocumentPadifSuggestionsTable } from './table';

import {
  useDocumentPadifAnalysisDistancesJsQuery,
  useDocumentPadifAnalysisDistancesQuery,
} from '#gql';
import { DropdownAggregationMethod } from '#padif';

export interface DocumentPadifSuggestionsDistanceProps {
  pagination: UsePaginationTable;
  documentId: string;
  padifAnalysisId: string;
}

export function DocumentPadifSuggestionsPythonDistance(
  props: DocumentPadifSuggestionsDistanceProps,
) {
  const { documentId, pagination, padifAnalysisId } = props;

  const { data, previousData } = useDocumentPadifAnalysisDistancesQuery({
    variables: {
      id: documentId,
      padifAnalysis: padifAnalysisId,
      ...pagination.pagination,
    },
  });

  const tableData = data || previousData;

  const result = useMemo(() => {
    return {
      nodes:
        tableData?.document?.padifAnalysisDocumentDistance?.nodes.map(
          (node) => ({ id: node.document.id, ...node }),
        ) || [],
      totalCount:
        tableData?.document?.padifAnalysisDocumentDistance?.totalCount || 0,
    };
  }, [tableData]);

  return (
    <DocumentPadifSuggestionsTable
      data={result.nodes}
      totalCount={result.totalCount}
      tablePagination={pagination.tablePagination}
    />
  );
}

export function DocumentPadifSuggestionsJsDistance(
  props: DocumentPadifSuggestionsDistanceProps,
) {
  const { documentId, pagination, padifAnalysisId } = props;

  const [aggregationMethod, setAggregationMethod] =
    useState<PadifAggregationMethod>('EUCLIDEAN_DISTANCE');

  const { data, previousData, error } =
    useDocumentPadifAnalysisDistancesJsQuery({
      variables: {
        id: documentId,
        padifAnalysis: padifAnalysisId,
        aggregationMethod,
        ...pagination.pagination,
      },
    });

  if (error) throw error;

  const tableData = data || previousData;

  const result = useMemo(() => {
    return {
      nodes:
        tableData?.document?.padifAnalysisDocumentDistanceJs?.nodes.map(
          (node) => ({ id: node.document.id, ...node }),
        ) || [],
      totalCount:
        tableData?.document?.padifAnalysisDocumentDistanceJs?.totalCount || 0,
    };
  }, [tableData]);

  return (
    <>
      <DropdownAggregationMethod
        selected={aggregationMethod}
        setSelected={setAggregationMethod}
      />
      <DocumentPadifSuggestionsTable
        data={result.nodes}
        totalCount={result.totalCount}
        tablePagination={pagination.tablePagination}
      />
    </>
  );
}
