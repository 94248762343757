import type {
  Database as BetterSqlite3Database,
  Statement as BetterSqlite3Statement,
} from 'better-sqlite3';

import {
  BindParams,
  Database,
  RowObject,
  RunResult,
  Statement,
} from '../types';

export class BetterSqlite3DatabaseImpl implements Database {
  constructor(private readonly database: BetterSqlite3Database) {}

  prepare = <
    Result extends RowObject = RowObject,
    BP extends BindParams | undefined = BindParams | undefined,
  >(
    sql: string,
  ): Statement<BP, Result> => {
    return new BetterSqlite3StatementImpl<BP, Result>(
      this.database.prepare(sql),
    );
  };

  exec = (source: string): void => {
    this.database.exec(source);
  };

  serialize = (): Uint8Array => {
    return this.database.serialize();
  };
}

export class BetterSqlite3StatementImpl<
  BP extends BindParams | undefined = BindParams | undefined,
  Result extends RowObject = RowObject,
> implements Statement<BP, Result>
{
  constructor(
    private readonly statement: BetterSqlite3Statement<unknown[], Result>,
  ) {}

  private boundParams(params?: BP) {
    if (params === undefined) return [];
    if (!params) return params;

    return Object.fromEntries(
      Object.entries(params).map(([k, v]) => [k.replace(/^\$/, ''), v]),
    );
  }

  get = (params?: BP): Result | undefined => {
    return this.statement.get(this.boundParams(params));
  };

  iterate = (params?: BP): IterableIterator<Result> => {
    return this.statement.iterate(this.boundParams(params));
  };

  all = (params?: BP): Result[] => {
    return Array.from(this.iterate(params));
  };

  mapAll = <ResultMapped>(
    mapperFn: (row: Result) => ResultMapped,
    params?: BP,
  ): ResultMapped[] => {
    return Array.from(this.iterate(params), mapperFn);
  };

  run = (params?: BP): RunResult => {
    const result = this.statement.run(this.boundParams(params));

    return {
      changes: result.changes,
      lastInsertRowid: Number(result.lastInsertRowid),
    };
  };
}
