import { ShieldExclamationIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import { Language } from '@zakodium/profid-shared';
import { FormattedDate, IntlProvider } from 'react-intl';

import Stamp from '../../../../components/alerts/Stamp';

import logoIsf from '#assets/img/logo_isf.png';
import logoProfid from '#assets/img/logo_profid.png';
import { mapLocaleToIntlLocale } from '#intl';

export interface AlertDocumentHeaderProps {
  isDraft?: boolean;
  language: Language;
  date: Date;
  serieSeqId: string;
}

export function AlertDocumentHeader(props: AlertDocumentHeaderProps) {
  const { isDraft, language, date, serieSeqId } = props;

  return (
    <header className="relative grid h-32 grid-cols-4 border-2 border-neutral-400 px-3 py-2">
      <div
        className="col-span-1 bg-contain bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${logoProfid})` }}
      />

      <div className="col-span-2 flex flex-col items-center justify-evenly text-2xl font-bold">
        <div className="flex items-center gap-2 text-warning-700">
          <ShieldExclamationIcon className="h-6 w-6" />
          <span className="uppercase">
            <T
              keyName="series.alert.field.preview.title"
              language={language}
              ns="alert"
            />
          </span>
          <ShieldExclamationIcon className="h-6 w-6" />
        </div>
        <div className="text-xl">{serieSeqId}</div>
        {isDraft ? (
          <div className="mb-1 mt-1 -rotate-6">
            <Stamp size="md">
              <T keyName="global.draft" language={language} />
            </Stamp>
          </div>
        ) : (
          <div className="text-lg font-normal text-neutral-600">
            <IntlProvider locale={mapLocaleToIntlLocale[language]}>
              <FormattedDate value={date} />
            </IntlProvider>
          </div>
        )}
      </div>

      <div
        className="col-span-1 bg-contain bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${logoIsf})` }}
      />
    </header>
  );
}
