import { useTranslate } from '@tolgee/react';
import { useMemo } from 'react';

import { Step, Stepper, StepperProps } from '#tailwind_ui';

export interface FormattedStep extends Omit<Step, 'label'> {
  label: TranslationKey;
}

interface FormattedStepperProps<T extends FormattedStep>
  extends StepperProps<T> {
  getDescription?: (step: T) => undefined | TranslationKey;
}

export function FormattedStepper<T extends FormattedStep>(
  props: FormattedStepperProps<T>,
) {
  const { steps, getDescription, ...otherProps } = props;
  const { t } = useTranslate();

  const formattedSteps = useMemo(() => {
    return steps.map((step) => {
      const description = getDescription?.(step);

      return {
        ...step,
        label: t(step.label),
        description: description && t(description),
      };
    });
  }, [steps, t, getDescription]);

  return <Stepper steps={formattedSteps} {...otherProps} />;
}
