import { BufferSource } from '../../types';

export async function derivePassword(password: string, salt: BufferSource) {
  const baseKey = await getKey(password);

  // DO NOT CHANGE algorithms definitions, or it will break export
  const algorithm = {
    name: 'PBKDF2',
    salt,
    iterations: 100000,
    hash: 'SHA-256',
  };
  const derivedKeyAlgorithm = { name: 'AES-GCM', length: 256 };

  return crypto.subtle.deriveKey(
    algorithm,
    baseKey,
    derivedKeyAlgorithm,
    true,
    ['encrypt', 'decrypt'],
  );
}

function getKey(password: string) {
  return crypto.subtle.importKey(
    'raw',
    new TextEncoder().encode(password.normalize()),
    'PBKDF2',
    false,
    ['deriveBits', 'deriveKey'],
  );
}
