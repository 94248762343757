import IfHasFeature from '../../../components/IfHasFeature';
import DocumentSeriesSuggestions from '../../../components/document/DocumentSeriesSuggestions';
import DocumentSimilarSerialNumbers from '../../../components/document/DocumentSimilarSerialNumbers';
import { useDocumentContext } from '../../../components/providers/DocumentProvider.context';
import { Feature } from '../../../constants';

import {
  DocumentPadifSuggestions,
  DocumentPadifSuggestionsJsDistance,
} from '#document';

export function Suggestions() {
  const { document } = useDocumentContext();

  return (
    <div className="grid grid-cols-1 gap-3 lg:grid-cols-2">
      <div className="col-span-1">
        <DocumentSimilarSerialNumbers document={document} />
      </div>
      <IfHasFeature feature={Feature.series}>
        <div className="col-span-1">
          <DocumentSeriesSuggestions document={document} />
        </div>
      </IfHasFeature>
      <div className="col-span-2">
        <DocumentPadifSuggestions
          documentId={document.id}
          DistanceFetcherView={DocumentPadifSuggestionsJsDistance}
        />
      </div>
    </div>
  );
}
