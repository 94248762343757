import { T } from '@tolgee/react';
import { FieldValues, useWatch } from 'react-hook-form';

import FormattedFormError from '../translation/FormattedFormError';
import FormattedInputField from '../translation/FormattedInputField';

import { getUpdateSeriesNameValidationSchema } from './newSerieForm';

import {
  GqlSerieQuery,
  useSerieNamesQuery,
  useUpdateSerieNameMutation,
} from '#gql';
import {
  FormattedAlert,
  FormattedDialogCancelButton,
  FormattedSubmitButton,
} from '#intl';
import {
  FormRHFDialogBody,
  FormRHFDialogFooter,
  FormRHFDialogRoot,
  FormRHFDialogTitle,
} from '#tailwind_ui';

interface UpdateSerieNameDialogProps {
  serie: GqlSerieQuery['serie'];
  onRequestClose: () => void;
}

export default function UpdateSerieNameDialog(
  props: UpdateSerieNameDialogProps,
) {
  const { onRequestClose, serie } = props;
  const [editName] = useUpdateSerieNameMutation();
  const { data } = useSerieNamesQuery();

  if (!data) {
    return null;
  }
  const validate = getUpdateSeriesNameValidationSchema(
    'page.series.rename_series.exists',
    data.series.nodes
      .flatMap((node) => [node.name, ...(node.aliases || [])])
      // It's allowed to rename a series to an existing alias within the same series
      .filter((name) => !serie.aliases?.includes(name)),
  );

  async function onSubmit(values: FieldValues) {
    await editName({
      variables: {
        input: {
          id: serie.id,
          name: values.name,
        },
      },
    });
  }

  return (
    <FormRHFDialogRoot<{ name: string }>
      open
      autoCloseOnSubmit
      onSubmit={onSubmit}
      validationSchema={validate}
      defaultValues={{ name: serie.name }}
      size="small"
      onOpenChange={onRequestClose}
    >
      <FormRHFDialogTitle>
        <T keyName="page.series.edit_name.title" />
      </FormRHFDialogTitle>
      <FormRHFDialogBody>
        <FormattedInputField name="name" label="global.name" />
        <FormattedAlert
          type="info"
          messageId="page.series.edit_name.alias_info"
        />
        <FormattedFormError />
      </FormRHFDialogBody>
      <FormRHFDialogFooter>
        <FormattedDialogCancelButton />
        <FormSubmitButton serie={serie} />
      </FormRHFDialogFooter>
    </FormRHFDialogRoot>
  );
}

function FormSubmitButton(props: { serie: GqlSerieQuery['serie'] }) {
  const name = useWatch({ name: 'name' });
  const disabled = name === props.serie.name;
  return (
    <FormattedSubmitButton messageId="global.change" disabled={disabled} />
  );
}
