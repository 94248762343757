import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import clsx from 'clsx';
import { ReactNode } from 'react';

export interface AccordionCardProps {
  title: TranslationKey;
  children: ReactNode;
  className?: string;
}

export function AccordionCard(props: AccordionCardProps) {
  const { title, children, className } = props;

  return (
    <details
      className={clsx(
        'group -mx-6 flex flex-col',
        'border-y border-neutral-100 [&+details]:border-t-0',
        className,
      )}
    >
      <summary className="flex flex-row items-center justify-between px-6 py-2 hover:cursor-pointer hover:bg-neutral-50 group-open:border-b group-open:border-neutral-100">
        <span className="underline">
          <T keyName={title} />
        </span>
        <ChevronRightIcon className="h-5 w-5 group-open:rotate-90" />
      </summary>

      <div className="px-6 py-2">{children}</div>
    </details>
  );
}
