import { z, ZodType } from 'zod';

import {
  AlertStatus,
  DocumentType,
  FraudType,
  Language,
} from '../../../../generated/graphql_enums';
import { validAppCode, validateRichText } from '../../../app';
import { isValidCountryCode } from '../../../i18n';
import { SeriesRules } from '../../../series';

import {
  parseJsonString,
  zodTransformStringOrNullToDateOrNull,
  zodTransformStringToDate,
} from './mappers.utils';
import {
  SqliteApplicationEntity,
  SqliteApplicationRow,
  SqliteMetadataEntity,
  SqliteMetadataRow,
  SqliteSeriesAlertEntity,
  SqliteSeriesAlertImageEntity,
  SqliteSeriesAlertImageRow,
  SqliteSeriesAlertPublicationEntity,
  SqliteSeriesAlertPublicationRow,
  SqliteSeriesAlertPublicationTranslationEntity,
  SqliteSeriesAlertPublicationTranslationRow,
  SqliteSeriesAlertRow,
  SqliteSeriesEntity,
  SqliteSeriesFileEntity,
  SqliteSeriesFileRow,
  SqliteSeriesRow,
} from './types';

/* eslint-disable camelcase */

const zRichTextNullable = z
  .string()
  .nullable()
  .refine((input) => {
    if (input === null) return true;
    return validateRichText(input);
  });
const zDateTime = z.string().datetime().transform(zodTransformStringToDate);
const zDateTimeNullable = z
  .string()
  .datetime()
  .nullable()
  .transform(zodTransformStringOrNullToDateOrNull);
const zAppCode = z.string().regex(validAppCode);
const zCountryCode = z.string().refine(isValidCountryCode);

const zRecordAppCodeDateTime = z.preprocess(
  parseJsonString,
  z.record(zAppCode, zDateTimeNullable),
);

const applicationSchema = z
  .object({
    code: zAppCode,
    title: z.string(),
    title_long: z.string(),
  } satisfies Record<keyof SqliteApplicationRow, ZodType>)
  .transform<SqliteApplicationEntity>((application) => {
    return {
      code: application.code,
      title: application.title,
      titleLong: application.title_long,
    };
  });
export function sqliteApplicationMapper(
  application: SqliteApplicationRow,
): SqliteApplicationEntity {
  return applicationSchema.parse(application);
}

const metadataSchema = z
  .object({
    exported_at: zDateTime,
    version: z.number().gte(1).lte(1),
  } satisfies Record<keyof SqliteMetadataRow, ZodType>)
  .transform<SqliteMetadataEntity>((metadata) => {
    return {
      exportedAt: metadata.exported_at,
      version: metadata.version,
    };
  });
export function sqliteMetadataMapper(
  metadata: SqliteMetadataRow,
): SqliteMetadataEntity {
  return metadataSchema.parse(metadata);
}

const seriesSchema = z
  .object({
    id: z.string(),
    seq_year: z.number().gte(2000).lt(2100),
    seq_number: z.number().gte(0),
    created_at: zDateTime,
    updated_at: zDateTime,
    name: z.string().regex(SeriesRules.validNameRegex),
    doc_type: z.enum(
      Object.values(DocumentType) as [DocumentType, ...DocumentType[]],
    ),
    doc_country: zCountryCode,
    doc_fraud_type: z.enum(
      Object.values(FraudType) as [FraudType, ...FraudType[]],
    ),
    contextual_profile: zRichTextNullable,
    material_profile: zRichTextNullable,
    important_fields_updated_at: zDateTime,
    doc_count: z.preprocess(parseJsonString, z.record(zAppCode, z.number())),
    doc_first_seizure_date: zRecordAppCodeDateTime,
    doc_last_seizure_date: zRecordAppCodeDateTime,
  } satisfies Record<keyof SqliteSeriesRow, ZodType>)
  .transform<SqliteSeriesEntity>((series) => {
    return {
      id: series.id,
      seqYear: series.seq_year,
      seqNumber: series.seq_number,
      createdAt: series.created_at,
      updatedAt: series.updated_at,
      name: series.name,
      docType: series.doc_type,
      docCountry: series.doc_country,
      docFraudType: series.doc_fraud_type,
      contextualProfile: series.contextual_profile,
      materialProfile: series.material_profile,
      importantFieldsUpdatedAt: series.important_fields_updated_at,
      docCount: series.doc_count,
      docFirstSeizureDate: series.doc_first_seizure_date,
      docLastSeizureDate: series.doc_last_seizure_date,
    };
  });
export function sqliteSeriesMapper(
  series: SqliteSeriesRow,
): SqliteSeriesEntity {
  return seriesSchema.parse(series);
}

const seriesFilesSchema = z
  .object({
    id: z.string(),
    id_serie: z.string(),
    filename: z.string(),
    id_sqlar: z.string(),
  } satisfies Record<keyof SqliteSeriesFileRow, ZodType>)
  .transform<SqliteSeriesFileEntity>((seriesFiles) => {
    return {
      id: seriesFiles.id,
      idSerie: seriesFiles.id_serie,
      filename: seriesFiles.filename,
      idSqlar: seriesFiles.id_sqlar,
    };
  });
export function sqliteSeriesFilesMapper(
  seriesFiles: SqliteSeriesFileRow,
): SqliteSeriesFileEntity {
  return seriesFilesSchema.parse(seriesFiles);
}

const seriesAlertsSchema = z
  .object({
    id: z.string(),
    id_serie: z.string(),
    updated_at: zDateTime,
    created_at: zDateTime,
    status: z.enum(
      Object.values(AlertStatus) as [AlertStatus, ...AlertStatus[]],
    ),
  } satisfies Record<keyof SqliteSeriesAlertRow, ZodType>)
  .transform<SqliteSeriesAlertEntity>((seriesAlerts) => {
    return {
      id: seriesAlerts.id,
      idSerie: seriesAlerts.id_serie,
      updatedAt: seriesAlerts.updated_at,
      createdAt: seriesAlerts.created_at,
      status: seriesAlerts.status,
    };
  });
export function sqliteSeriesAlertsMapper(
  seriesAlerts: SqliteSeriesAlertRow,
): SqliteSeriesAlertEntity {
  return seriesAlertsSchema.parse(seriesAlerts);
}

const seriesAlertPublicationsSchema = z
  .object({
    id: z.string(),
    id_alert: z.string(),
    published_at: zDateTime,
    id_rapid_identification_image: z.string().nullable(),
  } satisfies Record<keyof SqliteSeriesAlertPublicationRow, ZodType>)
  .transform<SqliteSeriesAlertPublicationEntity>((seriesAlertPublications) => {
    return {
      id: seriesAlertPublications.id,
      idAlert: seriesAlertPublications.id_alert,
      publishedAt: seriesAlertPublications.published_at,
      idRapidIdentificationImage:
        seriesAlertPublications.id_rapid_identification_image,
    };
  });
export function sqliteSeriesAlertPublicationsMapper(
  seriesAlertPublications: SqliteSeriesAlertPublicationRow,
): SqliteSeriesAlertPublicationEntity {
  return seriesAlertPublicationsSchema.parse(seriesAlertPublications);
}

const seriesAlertImagesSchema = z
  .object({
    id: z.string(),
    filename: z.string(),
    id_sqlar: z.string(),
  } satisfies Record<keyof SqliteSeriesAlertImageRow, ZodType>)
  .transform<SqliteSeriesAlertImageEntity>((seriesAlertImages) => {
    return {
      id: seriesAlertImages.id,
      filename: seriesAlertImages.filename,
      idSqlar: seriesAlertImages.id_sqlar,
    };
  });
export function sqliteSeriesAlertImagesMapper(
  seriesAlertImage: SqliteSeriesAlertImageRow,
): SqliteSeriesAlertImageEntity {
  return seriesAlertImagesSchema.parse(seriesAlertImage);
}

const seriesAlertPublicationsTranslationsSchema = z
  .object({
    id_publication: z.string(),
    language: z.enum(Object.values(Language) as [Language, ...Language[]]),
    revision: z.number().gte(0),
    field_title: z.string(),
    field_rapid_identification_description: zRichTextNullable,
    field_general: zRichTextNullable,
    field_measures: zRichTextNullable,
  } satisfies Record<keyof SqliteSeriesAlertPublicationTranslationRow, ZodType>)
  .transform<SqliteSeriesAlertPublicationTranslationEntity>(
    (seriesAlertPublicationsTranslations) => {
      return {
        idPublication: seriesAlertPublicationsTranslations.id_publication,
        language: seriesAlertPublicationsTranslations.language,
        revision: seriesAlertPublicationsTranslations.revision,
        fieldGeneral: seriesAlertPublicationsTranslations.field_general,
        fieldMeasures: seriesAlertPublicationsTranslations.field_measures,
        fieldTitle: seriesAlertPublicationsTranslations.field_title,
        fieldRapidIdentificationDescription:
          seriesAlertPublicationsTranslations.field_rapid_identification_description,
      };
    },
  );
export function sqliteSeriesAlertPublicationsTranslationsMapper(
  seriesAlertPublicationsTranslations: SqliteSeriesAlertPublicationTranslationRow,
): SqliteSeriesAlertPublicationTranslationEntity {
  return seriesAlertPublicationsTranslationsSchema.parse(
    seriesAlertPublicationsTranslations,
  );
}
