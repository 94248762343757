import { SeriesExchangeExtractor } from '@zakodium/profid-shared';

import { CardLayout } from '#ui/card_layout';
import { FormattedDefinitionList } from '#ui/definition_list';

export interface ApplicationCardProps {
  extractor: SeriesExchangeExtractor;
}

export function ApplicationCard(props: ApplicationCardProps) {
  const application = props.extractor.getApplication();

  return (
    <CardLayout title="series_exchange.application.title">
      <FormattedDefinitionList
        data={{
          'global.code': application.code,
          'series_exchange.application.title_short': application.title,
          'series_exchange.application.title_long': application.titleLong,
        }}
      />
    </CardLayout>
  );
}
