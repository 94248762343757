function getKey(seqId: string): string {
  return `series_exchange.export_pending.${seqId}`;
}

export function get(seqId: string) {
  return sessionStorage.getItem(getKey(seqId));
}

export function set(seqId: string, value: string): void {
  sessionStorage.setItem(getKey(seqId), value);
}

export function remove(seqId: string) {
  sessionStorage.removeItem(getKey(seqId));
}
