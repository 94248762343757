import { useDocumentSetContext } from '../../contexts/documentSet';
import {
  documentSetTypeValues,
  documentTypeValues,
} from '../../graphql/enumValues';
import { DocumentSetFilters } from '../../stores/searches/DocumentSetFiltersStore';
import { TRANSLATIONS_KEY_DOCUMENT_SET_TYPE } from '../../translationsMapping/documentSets';
import { TRANSLATIONS_KEY_DOCUMENT_TYPE } from '../../translationsMapping/documents';
import DateRangePicker from '../DateRangePicker';
import CountryFilterSelectList from '../TableSearch/CountryFilterSelectList';
import FacetGraphqlEnumFilterSelectList from '../TableSearch/FacetGraphqlEnumFilterSelectList';

import { DocumentSetType, DocumentType, GqlFacetSearchResult } from '#gql';
import { SimpleListContainer, SimpleListContainerItem } from '#tailwind_ui';

export interface DocumentSetSearchFilterProps {
  facets: GqlFacetSearchResult[];
}

export function DocumentSetSearchFilter(props: DocumentSetSearchFilterProps) {
  const context = useDocumentSetContext();

  return (
    <SimpleListContainer>
      <SimpleListContainerItem className="space-y-2">
        <FacetGraphqlEnumFilterSelectList<
          DocumentSetType,
          DocumentSetFilters,
          'type'
        >
          facets={props.facets}
          prop="type"
          name="doc_set.field.type"
          enumValues={documentSetTypeValues}
          translationMap={TRANSLATIONS_KEY_DOCUMENT_SET_TYPE}
          onAdd={context.store.addFilter}
          onRemove={context.store.removeFilter}
          value={context.filters.type}
        />
        <FacetGraphqlEnumFilterSelectList<
          DocumentType,
          DocumentSetFilters,
          'docType'
        >
          facets={props.facets}
          prop="docType"
          name="doc.field.type"
          enumValues={documentTypeValues}
          translationMap={TRANSLATIONS_KEY_DOCUMENT_TYPE}
          onAdd={context.store.addFilter}
          onRemove={context.store.removeFilter}
          value={context.filters.docType}
        />

        <CountryFilterSelectList<DocumentSetFilters, 'docCountry'>
          facets={props.facets}
          prop="docCountry"
          name="doc.field.country"
          value={context.filters.docCountry}
          onAdd={context.store.addFilter}
          onRemove={context.store.removeFilter}
        />
      </SimpleListContainerItem>

      <SimpleListContainerItem className="space-y-2">
        <DateRangePicker
          startPlaceholderId="doc_set.list.documents.search.added_at_start"
          endPlaceholderId="doc_set.list.documents.search.added_at_end"
          start={context.filters.startDate ?? undefined}
          end={context.filters.endDate ?? undefined}
          onStartChange={context.store.setStartDate}
          onEndChange={context.store.setEndDate}
        />
      </SimpleListContainerItem>
    </SimpleListContainer>
  );
}
