import { useTranslate } from '@tolgee/react';
import { Language } from '@zakodium/profid-shared';
import { useWatch } from 'react-hook-form';

import { safeTranslationKeyGlobalLanguageInSentence } from '../../translationsMapping/global';
import FormattedInputField from '../translation/FormattedInputField';

export function AlertTitleInput() {
  const { t } = useTranslate();
  const language = useWatch({ name: 'language' }) as Language | undefined;

  const disabled = !language;
  const helpValues = language
    ? {
        lang: t(safeTranslationKeyGlobalLanguageInSentence(language)),
      }
    : undefined;

  return (
    <FormattedInputField
      label="global.title"
      name="title"
      required
      disabled={disabled}
      help={
        disabled
          ? 'alert.create.title.help.specify_language'
          : 'alert.create.title.help.language'
      }
      helpValues={helpValues}
    />
  );
}
