import { T } from '@tolgee/react';
import {
  seriesExchangeDecode,
  seriesExchangeParsePfdHeader,
} from '@zakodium/profid-shared';
import { useState } from 'react';

import FormattedFormError from './translation/FormattedFormError';
import FormattedInputField from './translation/FormattedInputField';

import { FormattedAlert, FormattedSubmitButton } from '#intl';
import { Dropzone, DropzoneList, FormRHF, useDropzone } from '#tailwind_ui';
import { assert } from '#utils/assert';
import { JSError } from '#utils/error/api';

const mimetypeAllowList = {
  'application/octet-stream': ['.pfd'],
};

interface PfdDropzoneState {
  isCorrectPfdFile: boolean;
  file: File | null;
}

const defaultPfdDropzoneState: PfdDropzoneState = {
  isCorrectPfdFile: false,
  file: null,
};

interface PfdDropzoneProps {
  onSubmitPreview: (decodedFileBuffer: Uint8Array, password: string) => void;
}

const defaultValues = { password: '' };
export function PfdDropzone(props: PfdDropzoneProps) {
  const { onSubmitPreview } = props;

  const [pfdDropzoneState, setPfdDropzoneState] = useState<PfdDropzoneState>(
    defaultPfdDropzoneState,
  );

  const { dropzoneProps } = useDropzone({
    accept: mimetypeAllowList,
    maxFiles: 1,
    multiple: false,
    replace: true,
  });

  async function handleOnDrop([file]: File[]) {
    if (!file) return;
    const isCorrectPfdFile = await isPfdFile(file);

    setPfdDropzoneState((state) => ({
      ...state,
      isCorrectPfdFile,
      file,
    }));
  }

  function handleOnRemove() {
    setPfdDropzoneState(defaultPfdDropzoneState);
  }

  async function onSubmit(values: typeof defaultValues) {
    // `file` cannot be null on `onSubmit` function. FormRHF only rendered when a file exist.
    assert(pfdDropzoneState.file);
    const arrayBuffer = await pfdDropzoneState.file.arrayBuffer();

    try {
      const decoded = await seriesExchangeDecode(arrayBuffer, values.password);
      onSubmitPreview(new Uint8Array(decoded), values.password);
    } catch (error) {
      throw new JSError('Invalid password', 'error.password.wrong', error);
    }
  }

  return (
    <section className="m-auto max-w-xl space-y-5">
      <div>
        <Dropzone
          {...dropzoneProps}
          onDrop={(files) => void handleOnDrop(files)}
          message={<T keyName="serie.upload.drag_drop" />}
        />

        {pfdDropzoneState.file && (
          <DropzoneList
            files={[pfdDropzoneState.file]}
            onRemove={handleOnRemove}
            showPreview
          />
        )}

        {pfdDropzoneState.file && !pfdDropzoneState.isCorrectPfdFile && (
          <FormattedAlert
            type="error"
            messageId="series_exchange.import.INCORRECT_FILE"
          />
        )}
      </div>

      {pfdDropzoneState.file && pfdDropzoneState.isCorrectPfdFile && (
        <FormRHF onSubmit={onSubmit} defaultValues={defaultValues}>
          <FormattedInputField
            name="password"
            label="series_exchange.import.password.form.field.password"
            autoComplete="off"
            type="text"
          />

          <FormattedFormError />

          <FormattedSubmitButton messageId="series_exchange.import.password.form.preview" />
        </FormRHF>
      )}
    </section>
  );
}

async function isPfdFile(file: File) {
  const buffer = await file.arrayBuffer();

  try {
    seriesExchangeParsePfdHeader(buffer);
    return true;
  } catch {
    return false;
  }
}
