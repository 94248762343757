import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { T } from '@tolgee/react';
import { FormattedDate } from 'react-intl';

import { usePadifContext } from '../../contexts/padif';
import { useSyncTableSearchParams } from '../../hooks/useSyncTableSearchParams';
import DocumentSetType from '../DocumentSet/DocumentSetType';
import ImgLink from '../ImgLink';
import { IndexFilterError } from '../TableSearch/IndexFilterError';
import { TableSearchLayout } from '../TableSearch/TableSearchLayout';
import DocumentLink from '../document/DocumentLink';
import FormattedEmptyState from '../translation/FormattedEmptyState';
import { FormattedTable } from '../translation/FormattedTable';

import { PadifActiveFilter } from './PadifActiveFilter';
import { PadifDocSearchFilter } from './PadifDocSearchFilter';
import { PadifDocSetSearchFilter } from './PadifDocSetSearchFilter';
import { PadifAnalysisReferenceDocument } from './padifGqlTypes';

import { GqlPadifAnalysesQuery, usePadifAnalysesQuery } from '#gql';
import {
  SimpleListContainer,
  SimpleListContainerItem,
  Td,
  Th,
} from '#tailwind_ui';
import { HighlightedLink } from '#ui/link';

type TrItem = GqlPadifAnalysesQuery['padifAnalyses']['nodes'][number];

export default function PadifListTable() {
  const context = usePadifContext();

  const queryResult = usePadifAnalysesQuery({
    variables: {
      ...context.pagination,
      sortBy: context.sort,
      filterBy: context.filters,
    },
  });

  useSyncTableSearchParams(context);

  const error = queryResult.error;
  const data = queryResult.data || queryResult.previousData;

  if (error) return <IndexFilterError error={error} context={context} />;
  if (!data) return null;

  const { nodes, totalCount } = data.padifAnalyses;

  return (
    <TableSearchLayout
      context={context}
      advancedFilter={
        <SimpleListContainer>
          <SimpleListContainerItem className="space-y-2">
            <PadifDocSetSearchFilter />
            <PadifDocSearchFilter />
            <PadifActiveFilter />
          </SimpleListContainerItem>
        </SimpleListContainer>
      }
    >
      <FormattedTable
        data={nodes}
        renderHeader={renderHeader}
        renderTr={renderTr}
        renderEmpty={renderEmpty}
        pagination={{
          ...context.tablePagination,
          totalCount,
        }}
        sort={context.tableSort}
      />
    </TableSearchLayout>
  );
}

function renderEmpty() {
  return <FormattedEmptyState title="global.noresults" />;
}

function renderHeader() {
  return (
    <tr>
      <Th sortField="_id">
        <T keyName="global.name" />
      </Th>
      <Th>
        <T keyName="global.title.doc_set" />
      </Th>
      <Th>
        <T keyName="global.title.nb_doc" />
      </Th>
      <Th>
        <T keyName="page.padif_list.header.reference" />
      </Th>
      <Th sortField="updatedAt">
        <T keyName="global.updated_at" />
      </Th>
      <Th sortField="active">
        <T keyName="page.padif.active" />
      </Th>
    </tr>
  );
}

function renderTr(element: TrItem) {
  return (
    <tr className="even:bg-neutral-50">
      <Td className="align-middle">
        <HighlightedLink to={`/padif/${element.id}`}>
          <T keyName="global.analysis" />
          {` ${element.id}`}
        </HighlightedLink>
      </Td>
      <Td className="align-middle leading-tight">
        <div>
          <HighlightedLink to={`/document-sets/${element.documentSet.id}`}>
            {element.documentSet.name}
          </HighlightedLink>
        </div>
        <div className="text-xs text-neutral-500">
          <DocumentSetType value={element.documentSet} />
        </div>
      </Td>
      <Td className="align-middle">
        {element.documentSet.documents.totalCount}
      </Td>
      <Td className="align-middle">
        {element.referenceDocument ? (
          <div className="flex flex-col gap-1">
            <DocumentLink
              document={element.referenceDocument}
              multiline
              includeService
            />
            <div>
              <ReferenceThumb document={element.referenceDocument} />
            </div>
          </div>
        ) : (
          '-'
        )}
      </Td>
      <Td>
        <FormattedDate value={element.updatedAt} />
      </Td>
      <Td className="align-middle">
        {element.active ? (
          <CheckIcon className="mx-auto h-5 w-5 text-success-600" />
        ) : (
          <XMarkIcon className="mx-auto h-5 w-5 text-danger-600" />
        )}
      </Td>
    </tr>
  );
}

function ReferenceThumb({
  document,
}: {
  document: PadifAnalysisReferenceDocument;
}) {
  let image: Exclude<PadifAnalysisReferenceDocument['imageRecto'], null>;
  if (document.imageRecto) {
    image = document.imageRecto;
  } else if (document.imageVerso) {
    image = document.imageVerso;
  } else {
    return <>-</>;
  }
  return (
    <ImgLink
      imgStyle={{ maxWidth: '100%', maxHeight: 200 }}
      src={image.document.url}
      thumbSrc={image.documentThumb.url}
      alt="reference document thumbnail"
    />
  );
}
