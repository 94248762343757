import { RectangleGroupIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import { SeriesAlertRules } from '@zakodium/profid-shared';
import { useWatch } from 'react-hook-form';

import FormattedDropzoneField from '../../translation/FormattedDropzoneField';
import FormattedFormError from '../../translation/FormattedFormError';
import FormattedSubmitButton from '../../translation/FormattedSubmitButton';

import { useUpdateAlertRapidDetectionImageMutation } from '#gql';
import {
  FormRHFDialogBody,
  FormRHFDialogFooter,
  FormRHFDialogRoot,
  FormRHFDialogTitle,
} from '#tailwind_ui';

interface RapidDetectionImageFields {
  image: File[];
}

interface EditRapidDetectionImageDialogProps {
  id: string;
  draftId: string;
  isOpen: boolean;
  onClose: () => void;
}

export default function EditRapidDetectionImageDialog(
  props: EditRapidDetectionImageDialogProps,
) {
  const { id, draftId, isOpen, onClose } = props;
  const [updateImage] = useUpdateAlertRapidDetectionImageMutation();

  async function onSubmit(values: RapidDetectionImageFields) {
    const rapidIdentificationImage = values.image?.[0];
    if (!rapidIdentificationImage) return;

    await updateImage({
      variables: {
        input: {
          id,
          draftId,
          rapidIdentificationImage,
        },
      },
    });
  }

  return (
    <FormRHFDialogRoot<RapidDetectionImageFields>
      open={isOpen}
      icon={<RectangleGroupIcon />}
      onOpenChange={(value) => {
        if (value) return;
        onClose();
      }}
      autoCloseOnSubmit
      onSubmit={onSubmit}
    >
      <FormRHFDialogTitle>
        <T keyName="series.alert.edit_rapid_identification_image.title" />
      </FormRHFDialogTitle>
      <FormRHFDialogBody>
        <div className="w-[500px]">
          <FormattedDropzoneField
            name="image"
            label="series.alert.field.rapid_identification_image"
            ns="alert"
            hiddenLabel
            accept={SeriesAlertRules.mimetypeAllowList}
            replace
            showList
            showPreview
            multiple={false}
          />
        </div>
        <FormattedFormError />
      </FormRHFDialogBody>
      <FormRHFDialogFooter>
        <SubmitButton />
      </FormRHFDialogFooter>
    </FormRHFDialogRoot>
  );
}

function SubmitButton() {
  const image = useWatch<RapidDetectionImageFields>({ name: 'image' });
  const disabled = Array.isArray(image) ? !image[0] : !image;

  return (
    <FormattedSubmitButton
      color="primary"
      messageId="global.send"
      disabled={disabled}
    />
  );
}
