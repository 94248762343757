import { T } from '@tolgee/react';
import { useCallback } from 'react';

import { safeTranslationKeyGlobalLanguage } from '../../../../translationsMapping/global';

import { Language } from '#gql';
import { Select } from '#tailwind_ui';
import { assert } from '#utils/assert';

export interface AlertLanguageSelectProps {
  languages: Language[];
  selectedLanguage: Language;
  onChange: (language: Language) => void;
  hiddenLabel?: boolean;
}

export function AlertLanguageSelect(props: AlertLanguageSelectProps) {
  const { languages, selectedLanguage, onChange, hiddenLabel = false } = props;

  const handleChange = useCallback(
    (language: string | undefined) => {
      assert(language);
      onChange(language as Language);
    },
    [onChange],
  );

  return (
    <Select<string>
      className="w-32"
      label={<T keyName="global.language" />}
      hiddenLabel={hiddenLabel}
      options={languages}
      renderOption={renderOption}
      onChange={handleChange}
      selected={selectedLanguage}
    />
  );
}

function renderOption(option: string) {
  return <T keyName={safeTranslationKeyGlobalLanguage(option as Language)} />;
}
