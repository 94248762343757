import { LanguageIcon } from '@heroicons/react/20/solid';
import { T, useTranslate } from '@tolgee/react';

import { safeTranslationKeyGlobalLanguageAdjective } from '../../../translationsMapping/global';
import { AsyncFormattedConfirmModal } from '../../AsyncFormattedConfirmModal';

import { Language, useDeleteAlertDraftTranslationMutation } from '#gql';

interface DeleteAlertDraftLanguageModalProps {
  isOpen: boolean;
  id: string;
  draftId: string;
  language: Language;
  onClose: (success?: boolean) => void;
}
export default function DeleteAlertDraftLanguageModal(
  props: DeleteAlertDraftLanguageModalProps,
) {
  const { isOpen, id, draftId, language, onClose } = props;
  const [deleteAlertTranslation] = useDeleteAlertDraftTranslationMutation();
  const { t } = useTranslate();

  const body = (
    <span>
      {t('series.alert.draft.delete_language.confirm.body', {
        lang: (
          <T
            key="lang"
            keyName={safeTranslationKeyGlobalLanguageAdjective(language)}
          />
        ),
      })}
    </span>
  );

  return (
    <AsyncFormattedConfirmModal
      open={isOpen}
      icon={<LanguageIcon />}
      color="danger"
      confirmText="global.delete"
      cancelText="global.cancel"
      title="series.alert.draft.delete_language.confirm.title"
      body={body}
      onClose={onClose}
      onConfirm={() =>
        deleteAlertTranslation({
          variables: {
            input: {
              id,
              draftId,
              language,
            },
          },
        })
      }
    />
  );
}
