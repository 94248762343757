import { PencilIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';

import { requiredObject, requiredRoles } from '../../../forms/validation';
import UserRolesField from '../../form/UserRolesField';
import FormattedFormError from '../../translation/FormattedFormError';
import FormattedUserName from '../../translation/FormattedUserName';

import { GqlUsersQuery, useChangeUserRolesMutation, UserRole } from '#gql';
import { FormattedDialogCancelButton, FormattedSubmitButton } from '#intl';
import {
  FormRHFDialogBody,
  FormRHFDialogFooter,
  FormRHFDialogRoot,
  FormRHFDialogTitle,
  useNotificationCenter,
} from '#tailwind_ui';
import { getSortedRoles } from '#utils/roles';

export default function ChangeUserRolesDialog({
  user,
  onCancel,
}: {
  user: GqlUsersQuery['users']['nodes'][number];
  onCancel: () => void;
}) {
  const [changeUserRoles] = useChangeUserRolesMutation();

  const validationSchema = requiredObject({
    roles: requiredRoles,
  });

  const sortedRoles = getSortedRoles(user.roles);
  const { addNotification } = useNotificationCenter();

  async function onSubmit(values: { roles: UserRole[] }) {
    await changeUserRoles({
      variables: {
        input: {
          id: user.id,
          roles: values.roles,
        },
      },
    });

    addNotification({
      type: 'success',
      title: <T keyName="page.users.update.success" />,
    });
  }

  return (
    <FormRHFDialogRoot
      autoCloseOnSubmit
      onSubmit={onSubmit}
      open
      onOpenChange={(value) => {
        if (value) return;
        onCancel();
      }}
      icon={<PencilIcon />}
      defaultValues={{
        roles: sortedRoles,
      }}
      validationSchema={validationSchema}
    >
      <FormRHFDialogTitle>
        <T keyName="page.users.action.change_roles" />
      </FormRHFDialogTitle>
      <FormRHFDialogBody>
        <div>
          <T keyName="global.user" />
          {`: `}
          <FormattedUserName values={user} />
        </div>
        <UserRolesField name="roles" />
        <FormattedFormError />
      </FormRHFDialogBody>
      <FormRHFDialogFooter>
        <FormattedDialogCancelButton />
        <FormattedSubmitButton messageId="global.change" />
      </FormRHFDialogFooter>
    </FormRHFDialogRoot>
  );
}
