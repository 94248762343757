import { parsePfdHeader } from './parse';
import { BufferSource } from './types';
import { decode as v1Decode } from './v1';

export async function decode(
  data: BufferSource,
  password: string,
): Promise<ArrayBuffer> {
  const headers = parsePfdHeader(data);

  switch (headers.version) {
    case 1:
      return v1Decode(data, password);
    default:
      throw new Error(`Unsupported version number: ${headers.version}`);
  }
}
