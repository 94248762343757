import { Language } from '@zakodium/profid-shared';

import { BoolStr, safeMappedTranslationKey } from './common';

export const TRANSLATIONS_KEY_GLOBAL_COMPLYING = {
  true: 'global.complying',
  false: 'global.noncomplying',
} as const satisfies Record<BoolStr, TranslationKey>;

export function safeTranslationKeyGlobalComplying(
  index: boolean | null | undefined,
) {
  return safeMappedTranslationKey(TRANSLATIONS_KEY_GLOBAL_COMPLYING, index);
}

export const TRANSLATIONS_KEY_GLOBAL_FLUO = {
  true: 'global.fluorescent',
  false: 'global.nonfluorescent',
} as const satisfies Record<BoolStr, TranslationKey>;

export function safeTranslationKeyGlobalFluo(
  index: boolean | null | undefined,
) {
  return safeMappedTranslationKey(TRANSLATIONS_KEY_GLOBAL_FLUO, index);
}

export const TRANSLATIONS_KEY_GLOBAL_PRESENCE = {
  true: 'global.present',
  false: 'global.absent',
} as const satisfies Record<BoolStr, TranslationKey>;

export function safeTranslationKeyGlobalPresence(
  index: boolean | null | undefined,
) {
  return safeMappedTranslationKey(TRANSLATIONS_KEY_GLOBAL_PRESENCE, index);
}

export const TRANSLATIONS_KEY_GLOBAL_LANGUAGE = {
  en: 'global.language.en',
  fr: 'global.language.fr',
  de: 'global.language.de',
  nl: 'global.language.nl',
  it: 'global.language.it',
} as const satisfies Record<Language, TranslationKey>;

export function safeTranslationKeyGlobalLanguage(
  index: Language | null | undefined,
) {
  return safeMappedTranslationKey(TRANSLATIONS_KEY_GLOBAL_LANGUAGE, index);
}

export const TRANSLATIONS_KEY_GLOBAL_LANGUAGE_IN_SENTENCE = {
  en: 'global.language.in_sentence.de',
  fr: 'global.language.in_sentence.fr',
  de: 'global.language.in_sentence.de',
  nl: 'global.language.in_sentence.nl',
  it: 'global.language.in_sentence.it',
} as const satisfies Record<Language, TranslationKey>;

export function safeTranslationKeyGlobalLanguageInSentence(
  index: Language | null | undefined,
) {
  return safeMappedTranslationKey(
    TRANSLATIONS_KEY_GLOBAL_LANGUAGE_IN_SENTENCE,
    index,
  );
}

export const TRANSLATIONS_KEY_GLOBAL_LANGUAGE_ADJECTIVE = {
  en: 'global.language.translation.adjective.en',
  fr: 'global.language.translation.adjective.fr',
  de: 'global.language.translation.adjective.de',
  nl: 'global.language.translation.adjective.nl',
  it: 'global.language.translation.adjective.it',
} as const satisfies Record<Language, TranslationKey>;

export function safeTranslationKeyGlobalLanguageAdjective(
  index: Language | null | undefined,
) {
  return safeMappedTranslationKey(
    TRANSLATIONS_KEY_GLOBAL_LANGUAGE_IN_SENTENCE,
    index,
  );
}
