import { Route } from 'react-router-dom';

import DocumentEdit from '../../components/document/DocumentEdit';
import DocumentFull from '../../components/document/DocumentFull';
import DocumentImage from '../../components/document/DocumentImage';
import DocumentManageExtraImage from '../../components/document/DocumentManageExtraImage';
import DocumentManageStandardizedImage from '../../components/document/DocumentManageStandardizedImage';
import DocumentNew from '../../components/document/DocumentNew';
import DocumentQrcode from '../../components/document/DocumentQrcode';
import DocumentStandardizedImageView from '../../components/document/DocumentStandardizedImageView';
import DocumentSuggestionsLegacy from '../../components/document/DocumentSuggestionsLegacy';
import DocumentViewPage from '../../components/document/DocumentViewPage';
import DocumentsIndexPage from '../../components/document/DocumentsIndexPage';
import { ExperimentalFeatureId } from '../../components/experimental-features/experimentalFeatures';

import { Suggestions } from './pages/suggestions';

export function documentsRoutes(featureSet: Set<ExperimentalFeatureId>) {
  return [
    <Route
      key="/documents"
      path="/documents"
      element={<DocumentsIndexPage />}
    />,
    <Route
      key="/documents/new"
      path="/documents/new"
      element={<DocumentNew />}
    />,
    <Route
      key="documents/:seqId"
      path="/documents/:seqId"
      element={<DocumentViewPage />}
    >
      <Route index element={<DocumentFull />} />
      <Route path="recto" element={<DocumentImage view="RECTO" />} />
      <Route path="verso" element={<DocumentImage view="VERSO" />} />
      {featureSet.has('padifJs') ? (
        [
          <Route
            key="suggestions-python"
            path="suggestions-legacy"
            element={<DocumentSuggestionsLegacy />}
          />,
          <Route
            key="suggestions-js"
            path="suggestions"
            element={<Suggestions />}
          />,
        ]
      ) : (
        <Route
          key="suggestions-python"
          path="suggestions"
          element={<DocumentSuggestionsLegacy />}
        />
      )}
    </Route>,
    <Route
      key="/documents/:seqId/qr"
      path="/documents/:seqId/qr"
      element={<DocumentQrcode />}
    />,
    <Route
      key="/documents/:seqId/edit"
      path="/documents/:seqId/edit"
      element={<DocumentEdit />}
    />,
    <Route
      key="/documents/:seqId/extra-images/manage"
      path="/documents/:seqId/extra-images/manage"
      element={<DocumentManageExtraImage />}
    />,
    <Route
      key="/documents/:seqId/standardized-images/manage"
      path="/documents/:seqId/standardized-images/manage"
      element={<DocumentManageStandardizedImage />}
    />,
    <Route
      key="/documents/:seqId/standardized-images/:imageId"
      path="/documents/:seqId/standardized-images/:imageId"
      element={<DocumentStandardizedImageView />}
    />,
  ];
}
