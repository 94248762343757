import { ReactNode } from 'react';

import { DocumentPadifSuggestionsDistanceProps } from './distance';
import { DocumentPadifSuggestionsPadifSelector } from './selector';

import { useDocumentPadifAnalysisRoisQuery } from '#gql';

export interface DocumentPadifSuggestionsProps {
  documentId: string;
  DistanceFetcherView: (
    props: DocumentPadifSuggestionsDistanceProps,
  ) => ReactNode;
}

export function DocumentPadifSuggestions(props: DocumentPadifSuggestionsProps) {
  const { documentId, DistanceFetcherView } = props;
  const result = useDocumentPadifAnalysisRoisQuery({
    variables: {
      id: documentId,
    },
  });
  const data = result.data || result.previousData;

  if (result.error) throw result.error;
  if (!data) return null;

  const padifAnalyses = data.document.padifAnalyses;

  return (
    <DocumentPadifSuggestionsPadifSelector
      key={documentId}
      documentId={documentId}
      padifAnalyses={padifAnalyses}
      DistanceFetcherView={DistanceFetcherView}
    />
  );
}
