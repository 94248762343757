import { ShieldExclamationIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import { useNavigate } from 'react-router-dom';

import { safeTranslationKeyGlobalLanguage } from '../../../translationsMapping/global';
import FormattedFormError from '../../translation/FormattedFormError';
import FormattedSelectField from '../../translation/FormattedSelectField';
import { AlertTitleInput } from '../AlertTitleInput';
import { languageKeys } from '../utils/languages';
import { getAlertDraftEditUrl } from '../utils/navigation';

import { FormState } from './shared';
import { createAlertValidationSchema } from './validation';

import { GqlSerieQuery, Language, useCreateAlertMutation } from '#gql';
import { FormattedDialogCancelButton, FormattedSubmitButton } from '#intl';
import {
  FormRHFDialogBody,
  FormRHFDialogFooter,
  FormRHFDialogRoot,
  FormRHFDialogTitle,
} from '#tailwind_ui';
import { assert } from '#utils/assert';

interface CreateAlertDialogProps {
  serie: GqlSerieQuery['serie'];
  onClose: () => void;
}

export default function CreateAlertDialog(props: CreateAlertDialogProps) {
  const { onClose: onRequestClose, serie } = props;
  const navigate = useNavigate();
  const [createAlert] = useCreateAlertMutation();

  async function onSubmit(values: FormState) {
    if (!values.language) return;

    const result = await createAlert({
      variables: {
        input: {
          seriesId: serie.id,
          language: values.language,
          title: values.title,
        },
      },
    });

    assert(result.data);
    assert(result.data.createAlert.draft?.id);
    navigate(getAlertDraftEditUrl(result.data.createAlert.id, values.language));
  }

  return (
    <FormRHFDialogRoot<FormState>
      open
      defaultValues={{ language: undefined }}
      onOpenChange={(value) => {
        if (value) return;
        onRequestClose();
      }}
      validationSchema={createAlertValidationSchema}
      icon={<ShieldExclamationIcon />}
      onSubmit={onSubmit}
      autoCloseOnSubmit
    >
      <FormRHFDialogTitle>
        <T keyName="page.series.create_alert.title" />
      </FormRHFDialogTitle>
      <FormRHFDialogBody className="w-96">
        <FormattedSelectField<string>
          options={languageKeys}
          getValue={(val) => val}
          label="global.language"
          name="language"
          required
          renderOption={(option) => (
            <T keyName={safeTranslationKeyGlobalLanguage(option as Language)} />
          )}
        />
        <AlertTitleInput />

        <FormattedFormError />
      </FormRHFDialogBody>
      <FormRHFDialogFooter>
        <FormattedDialogCancelButton />
        <FormattedSubmitButton messageId="global.create" />
      </FormRHFDialogFooter>
    </FormRHFDialogRoot>
  );
}
