import { T } from '@tolgee/react';

import { useSeizureAuthoritiesQuery } from '#gql';
import { FormattedAlert } from '#intl';
import { HighlightedLink } from '#ui/link';

export default function CheckHasSeizureAuthorities() {
  const { data, error } = useSeizureAuthoritiesQuery();
  if (error) throw error;
  if (!data || data.seizureAuthorities.length > 0) return null;

  return (
    <FormattedAlert type="warning">
      <T keyName="home.check.no_seizure_authority" />
      <HighlightedLink to="/admin/seizure-authorities/create">
        <T keyName="admin.create_seizure_authority.title" />
      </HighlightedLink>
    </FormattedAlert>
  );
}
