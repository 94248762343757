import { T } from '@tolgee/react';
import { PadifAggregationMethod } from '@zakodium/profid-shared';
import { useCallback, useMemo } from 'react';

import { Select } from '#tailwind_ui';
import { assert } from '#utils/assert';

export interface DropdownAggregationMethodProps {
  selected: PadifAggregationMethod;
  setSelected: (value: PadifAggregationMethod) => void;
}

export function DropdownAggregationMethod(
  props: DropdownAggregationMethodProps,
) {
  const { selected, setSelected } = props;
  const options = useMemo(() => Object.values(PadifAggregationMethod), []);

  const onSelect = useCallback(
    (selected: string | undefined) => {
      assert(selected);
      setSelected(selected as PadifAggregationMethod);
    },
    [setSelected],
  );

  return (
    <Select<string>
      label={<T keyName="padif.statistics_aggregation_methods.label" />}
      options={options}
      selected={selected}
      onChange={onSelect}
    />
  );
}
