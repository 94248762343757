import { TranslateParams, useTranslate } from '@tolgee/react';
import clsx from 'clsx';

import ErrorAlert from '../error/ErrorAlert';

import { getFormattedError } from './error.helper';

import { assert } from '#utils/assert';

export default function FormattedErrorAlert(props: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
  className?: string;
  onDismiss?: () => void;
  getValues?: (
    messageId: TranslationKey,
    error: Error,
  ) => TranslateParams | undefined;
}) {
  const { t } = useTranslate();
  const { error, className, getValues, onDismiss } = props;

  if (!error) {
    return null;
  }

  const { title, titleId, details, detailsId, detailsValues } =
    getFormattedError(error);

  const values = titleId ? getValues?.(titleId, error) : undefined;
  const alertTitle = titleId ? t({ key: titleId, params: values }) : title;
  const alertDetails = detailsId
    ? t({ key: detailsId, params: detailsValues })
    : details;

  // title should now always be defined because either messageId or message is defined
  assert(alertTitle);

  return (
    <ErrorAlert
      error={error}
      className={clsx(className, 'whitespace-pre-wrap')}
      title={alertTitle}
      details={alertDetails}
      onDismiss={onDismiss}
    />
  );
}
