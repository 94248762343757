import { IOBuffer } from 'iobuffer';

import { BufferSource } from './types';

export interface ParseReturn {
  id: string;
  version: number;
}

export const PFD_HEADER_SIZE = 32;
const PFD_HEADER_ID_SIZE = 31;

export function parsePfdHeader(
  encryptedDataWithHeader: BufferSource,
): ParseReturn {
  const buffer = new IOBuffer(encryptedDataWithHeader);

  if (!buffer.available(PFD_HEADER_SIZE)) {
    throw new Error('Header is too short, it cannot contain complete header');
  }

  const id = buffer.readChars(PFD_HEADER_ID_SIZE);

  if (id !== 'zakodium:profid:series_exchange') {
    throw new Error(
      `Wrong header id, should be "zakodium:profid:series_exchange" but obtained ${id}`,
    );
  }

  const version = buffer.readUint8();

  return { id, version };
}
