import { CombinedOptions } from '@tolgee/core';
import { useTranslate } from '@tolgee/react';

import { DFT } from '../../../tolgee';

// eslint-disable-next-line no-restricted-imports
import { Alert, AlertProps } from '#tailwind_ui';

export interface FormattedAlertProps extends Omit<AlertProps, 'title'> {
  messageId?: TranslationKey;
  messageValues?: CombinedOptions<DFT>;
  title?: TranslationKey;
  titleValues?: CombinedOptions<DFT>;
}

export function FormattedAlert(props: FormattedAlertProps) {
  const { t } = useTranslate();
  const {
    messageId,
    messageValues,
    title,
    titleValues,
    children,
    ...otherProps
  } = props;
  const formattedTitle =
    typeof title === 'string' ? t(title, titleValues) : title;

  return (
    <Alert {...otherProps} title={formattedTitle}>
      {messageId || children ? (
        <>
          {messageId && t(messageId, messageValues)}
          {children}
        </>
      ) : null}
    </Alert>
  );
}
