import { safeMappedTranslationKey } from './common';

export type DisplayableExportSerieStatus =
  | 'seriesExported'
  | 'seriesImagesExported'
  | 'seriesAttachmentsExported'
  | 'publicationFileExported'
  | 'publicationExported'
  | 'ready';

export const TRANSLATION_KEY_EXPORT_SERIE = {
  seriesExported:
    'page.series.export.card.information.progress.series_exported',
  seriesImagesExported:
    'page.series.export.card.information.progress.series_images_exported',
  seriesAttachmentsExported:
    'page.series.export.card.information.progress.series_attachments_exported',
  publicationFileExported:
    'page.series.export.card.information.progress.publication_file_exported',
  publicationExported:
    'page.series.export.card.information.progress.publication_exported',
  ready: 'page.series.export.card.information.progress.ready',
} as const satisfies Record<DisplayableExportSerieStatus, TranslationKey>;

export function safeTranslationKeyExportSerie(
  status: DisplayableExportSerieStatus,
) {
  return safeMappedTranslationKey(TRANSLATION_KEY_EXPORT_SERIE, status);
}
