import { LanguageIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import { useNavigate } from 'react-router-dom';

import { safeTranslationKeyGlobalLanguage } from '../../../translationsMapping/global';
import FormattedFormError from '../../translation/FormattedFormError';
import FormattedSelectField from '../../translation/FormattedSelectField';
import { AlertTitleInput } from '../AlertTitleInput';
import { languageKeys } from '../utils/languages';
import { getAlertDraftEditUrl } from '../utils/navigation';

import { defaultFormState, FormState } from './shared';
import { createAlertValidationSchema } from './validation';

import {
  GqlAlertDraftQuery,
  Language,
  useCreateAlertDraftTranslationMutation,
} from '#gql';
import { FormattedDialogCancelButton, FormattedSubmitButton } from '#intl';
import {
  FormRHFDialogBody,
  FormRHFDialogFooter,
  FormRHFDialogRoot,
  FormRHFDialogTitle,
} from '#tailwind_ui';
import { assertNotNull } from '#utils/assert';

interface CreateAlertDraftTranslationDialogProps {
  isOpen: boolean;
  alert: GqlAlertDraftQuery['alert'];
  onClose: () => void;
}

export default function CreateAlertDraftTranslationDialog(
  props: CreateAlertDraftTranslationDialogProps,
) {
  const navigate = useNavigate();
  const { alert, onClose, isOpen } = props;
  const draft = alert.draft;

  assertNotNull(draft);

  const remainingLanguages = languageKeys.filter((lang) => {
    return !draft.languages.includes(lang);
  });

  const [createTranslation] = useCreateAlertDraftTranslationMutation();

  async function onSubmit(values: FormState) {
    assertNotNull(draft);
    if (!values.language) return;

    await createTranslation({
      variables: {
        input: {
          id: alert.id,
          draftId: draft.id,
          language: values.language,
          title: values.title,
        },
      },
    });

    navigate(getAlertDraftEditUrl(alert.id, values.language));
  }

  return (
    <FormRHFDialogRoot<FormState>
      icon={<LanguageIcon />}
      validationSchema={createAlertValidationSchema}
      onSubmit={onSubmit}
      open={isOpen}
      defaultValues={defaultFormState}
      onOpenChange={(value) => {
        if (value) return;
        onClose();
      }}
    >
      <FormRHFDialogTitle>
        <T keyName="series.alert.add_language.title" />
      </FormRHFDialogTitle>
      <FormRHFDialogBody className="w-96">
        <FormattedSelectField<string>
          options={remainingLanguages}
          getValue={(val) => val}
          label="global.language"
          name="language"
          required
          renderOption={(option) => (
            <T keyName={safeTranslationKeyGlobalLanguage(option as Language)} />
          )}
        />
        <AlertTitleInput />

        <FormattedFormError />
      </FormRHFDialogBody>
      <FormRHFDialogFooter>
        <FormattedDialogCancelButton />
        <FormattedSubmitButton messageId="series.alert.create_translation.submit" />
      </FormRHFDialogFooter>
    </FormRHFDialogRoot>
  );
}
