/**
 * This should be restricted to binary image formats supported by Chromium renderer.
 */
const mimetypeAllowList = {
  'image/png': ['.png'],
  'image/jpeg': ['.jpeg', '.jpg'],
};
const allowedRapidDetectionImageExtensions = new Set(
  Object.values(mimetypeAllowList).flat(),
);

export const SeriesAlertRules = {
  get mimetypeAllowList() {
    return mimetypeAllowList;
  },

  getAllowedExtensions() {
    return Array.from(allowedRapidDetectionImageExtensions);
  },

  isAllowedExtension(extension: string): boolean {
    return allowedRapidDetectionImageExtensions.has(extension.toLowerCase());
  },
};
