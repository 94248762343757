import { T, TranslateParams, useTranslate } from '@tolgee/react';
import clsx from 'clsx';
import { ElementType, ForwardedRef, ReactNode } from 'react';

import { Button, ButtonProps, forwardRefWithAs } from '#tailwind_ui';

export type FormattedButtonProps<T extends ElementType = 'button'> = Omit<
  ButtonProps<T>,
  'children' | 'tooltip'
> & {
  messageId: TranslationKey;
  messageParams?: TranslateParams;
  tooltipId?: TranslationKey;
  tooltipParams?: TranslateParams;
  icon?: ReactNode;
};

export const FormattedButton = forwardRefWithAs(function FormattedButton<
  T extends ElementType = 'button',
>(props: FormattedButtonProps<T>, ref: ForwardedRef<T>) {
  const { messageId, messageParams, tooltipId, tooltipParams, ...otherProps } =
    props;
  const { t } = useTranslate();
  const tooltip = tooltipId
    ? t({ key: tooltipId, params: tooltipParams })
    : undefined;

  return (
    // @ts-expect-error the error is not very helpful here, maybe a TS bug
    <Button
      ref={ref}
      {...otherProps}
      className={clsx(
        otherProps.className,
        // tweaks classname to inline icon
        props.icon && 'flex items-center justify-center gap-1 pl-3',
      )}
      tooltip={tooltip}
    >
      {props.icon}
      <T keyName={messageId} params={messageParams} />
    </Button>
  );
});
