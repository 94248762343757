import { ListItemNode, ListNode } from '@lexical/list';
import { HeadingNode } from '@lexical/rich-text';
import { createEditor, type CreateEditorArgs } from 'lexical';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const onError = (error: Error) => {
  // console.error(error);
};

const config: CreateEditorArgs = {
  namespace: 'EditorValidation',
  onError,
  nodes: [HeadingNode, ListNode, ListItemNode],
};

export function validateRichText(value: string) {
  const editor = createEditor(config);

  try {
    const state = editor.parseEditorState(value);
    editor.setEditorState(state);

    return true;
  } catch {
    return false;
  }
}
