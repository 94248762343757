import { T } from '@tolgee/react';
import { ReactNode, useMemo } from 'react';

import PadifLink from '../../../../components/Padif/PadifLink';
import PadifShowMainReference from '../../../../components/Padif/PadifShowMainReference';
import FormattedEmptyState from '../../../../components/translation/FormattedEmptyState';
import { usePaginationTable } from '../../../../hooks/usePaginationTable';

import { DocumentPadifSuggestionsDistanceProps } from './distance';

import { GqlDocumentPadifAnalysisRoisQuery } from '#gql';
import {
  customOptionsFilter,
  SearchSelect,
  useSearchSelect,
} from '#tailwind_ui';
import { CardLayout } from '#ui/card_layout';

const ROWS_PER_PAGE = 5;

export interface DocumentPadifSuggestionsPadifSelectorProps {
  documentId: string;
  padifAnalyses: GqlDocumentPadifAnalysisRoisQuery['document']['padifAnalyses'];
  DistanceFetcherView: (
    props: DocumentPadifSuggestionsDistanceProps,
  ) => ReactNode;
}

export function DocumentPadifSuggestionsPadifSelector(
  props: DocumentPadifSuggestionsPadifSelectorProps,
) {
  const { documentId, padifAnalyses, DistanceFetcherView } = props;

  const pagination = usePaginationTable({
    itemsPerPage: ROWS_PER_PAGE,
    page: 0,
  });

  const selectPadifOptions = useMemo(() => {
    return padifAnalyses.map((padifAnalysis) => ({
      value: padifAnalysis,
      label: `#${padifAnalysis.id}`,
    }));
  }, [padifAnalyses]);

  const selectPadif = useSearchSelect({
    options: selectPadifOptions,
    initialSelected: selectPadifOptions[0],
    filterOptions: customOptionsFilter((option) => option.label),
  });
  const selectedPadif = selectPadif.selected || selectPadifOptions[0];

  if (padifAnalyses.length === 0) {
    return <FormattedEmptyState title="document.padif.no_padif_analyses" />;
  }

  return (
    <CardLayout
      header={
        <>
          <div className="flex items-center justify-between">
            <T keyName="page.doc.view.suggestions.padif_distances" />

            <div className="flex items-center gap-2">
              <PadifLink padifAnalysis={selectedPadif.value} />

              <SearchSelect<(typeof selectPadif)['options'][number]>
                label="Select Padif analysis"
                hiddenLabel
                getValue={(option) => option.value.id}
                renderOption={(option) => option.label}
                {...selectPadif}
                onChange={(option) => {
                  selectPadif.onChange(option);
                  pagination.onPageChange(0);
                }}
              />
            </div>
          </div>
          <div className="flex justify-center">
            <PadifShowMainReference
              maxHeight={150}
              rois={selectedPadif.value.rois}
              document={selectedPadif.value.referenceDocument}
            />
          </div>
        </>
      }
    >
      <DistanceFetcherView
        documentId={documentId}
        padifAnalysisId={selectedPadif.value.id}
        pagination={pagination}
      />
    </CardLayout>
  );
}
