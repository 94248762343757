import { TFnType } from '@tolgee/react';

import { safeMappedTranslationKey } from '../../../../../translationsMapping/common';
import {
  TRANSLATIONS_KEY_DOCUMENT_FRAUD_TYPE,
  TRANSLATIONS_KEY_DOCUMENT_TYPE,
} from '../../../../../translationsMapping/documents';

import { GqlStatsQuery } from '#gql';
import { assert } from '#utils/assert';

export interface SeriesStatsData {
  lastSeries: GqlStatsQuery['series']['nodes'];
  lastSeriesDocuments: GqlStatsQuery['series']['nodes'];
  total: number;
}

export function getSeriesStats(data: GqlStatsQuery['series']): SeriesStatsData {
  const { nodes } = data;
  // get 5 last series
  const lastSeries = nodes
    .slice()
    .sort(sortPropDesc((series) => series.createdAt))
    .slice(0, 5);
  const lastSeriesDocuments = nodes
    .filter((s) => s.lastDocument?.seriesUpdatedAt)
    .sort(sortPropDesc((series) => series.lastDocument?.seriesUpdatedAt))
    .slice(0, 5);

  return {
    lastSeries,
    lastSeriesDocuments,
    total: nodes.length,
  };
}

function sortPropDesc<T>(accessProp: (value: T) => T[keyof T]) {
  return (a: T, b: T) => {
    const aValue = accessProp(a);
    const bValue = accessProp(b);
    if (aValue < bValue) return 1;
    else if (bValue < aValue) return -1;
    return 0;
  };
}

export interface DocStatsData {
  total: number;
  docTypes: PercentStat[];
  fraudTypes: PercentStat[];
  services: BaseStat[];
  docCountries: BaseStat[];
  presumedNationalities: BaseStat[];
  countInSerie: number;
  percentInSerie: number;
}

export function getDocStats(
  data: GqlStatsQuery['documents'],
  t: TFnType,
): DocStatsData {
  const { facets, totalCount: total } = data;

  const result: DocStatsData = {
    total,
    docTypes: [],
    fraudTypes: [],
    services: [],
    docCountries: [],
    presumedNationalities: [],
    countInSerie: 0,
    percentInSerie: 0,
  };

  for (const facet of facets) {
    if (facet.name === 'docType') {
      result.docTypes = getPercentStats(
        facet.values,
        total,
        t,
        TRANSLATIONS_KEY_DOCUMENT_TYPE,
      );
    } else if (facet.name === 'docFraudType') {
      result.fraudTypes = getPercentStats(
        facet.values,
        total,
        t,
        TRANSLATIONS_KEY_DOCUMENT_FRAUD_TYPE,
      );
    } else if (facet.name === 'series') {
      const documentsWithoutSeries =
        facet.values.find((s) => s.filter === null)?.count ?? 0;
      result.countInSerie = result.total - documentsWithoutSeries;

      const percent = (result.countInSerie / result.total) * 100;
      result.percentInSerie = Number.isNaN(percent) ? 0 : percent;
    } else if (facet.name === 'caseService') {
      result.services = baseConvert(facet.values, total);
    } else if (facet.name === 'docCountry') {
      result.docCountries = onlyFiveAndRemoveXXX(
        baseConvert(facet.values, total),
      );
    } else if (facet.name === 'casePresumedNationality') {
      result.presumedNationalities = onlyFiveAndRemoveXXX(
        baseConvert(facet.values, total),
      );
    }
  }

  return result;
}

function onlyFiveAndRemoveXXX(data: ReturnType<typeof baseConvert>) {
  return data
    .filter((datum) => datum.name !== 'XXX' && datum.name !== null)
    .slice(0, 5);
}

type StatsFacetValue = GqlStatsQuery['documents']['facets'][number]['values'];

export interface PercentStat {
  value: number;
  percent: number;
  id: string | null;
  label: string;
}

function getPercentStats<T extends string>(
  stats: StatsFacetValue,
  total: number,
  t: TFnType,
  translationMap: Record<T, TranslationKey>,
): PercentStat[] {
  return stats.map((stat) => {
    // Facets used on the home page cannot have null values.
    assert(stat.filter !== null);
    const label = translationMap
      ? t(safeMappedTranslationKey(translationMap, stat.filter as T))
      : stat.filter;
    return {
      value: stat.count,
      percent: makePercent(stat.count, total),
      id: label,
      label,
    };
  });
}

function baseData(value: number, total: number) {
  return {
    value,
    percent: makePercent(value, total),
  };
}

export interface BaseStat {
  name: string | null;
  value: number;
  percent: number;
}

function baseConvert(stats: StatsFacetValue, total: number): BaseStat[] {
  return stats.map((stat) => ({
    name: stat.filter,
    ...baseData(stat.count, total),
  }));
}

function makePercent(value: number, total: number): number {
  return Number(((value / total) * 100).toFixed(1));
}
