import { Database } from '../../../sqlite';

import {
  sqliteApplicationMapper,
  sqliteMetadataMapper,
  sqliteSeriesAlertImagesMapper,
  sqliteSeriesAlertPublicationsMapper,
  sqliteSeriesAlertPublicationsTranslationsMapper,
  sqliteSeriesAlertsMapper,
  sqliteSeriesFilesMapper,
  sqliteSeriesMapper,
} from './mappers';
import {
  SqlarId,
  SqliteApplicationRow,
  SqliteMetadataRow,
  SqliteSeriesAlertEntity,
  SqliteSeriesAlertImageRow,
  SqliteSeriesAlertPublicationEntity,
  SqliteSeriesAlertPublicationRow,
  SqliteSeriesAlertPublicationTranslationEntity,
  SqliteSeriesAlertPublicationTranslationRow,
  SqliteSeriesAlertRow,
  SqliteSeriesEntity,
  SqliteSeriesFileEntity,
  SqliteSeriesFileRow,
  SqliteSeriesRow,
  SqliteSqlarEntity,
  SqliteSqlarRow,
} from './types';

export class Extractor {
  constructor(public readonly database: Database) {}

  getApplication() {
    const row = this.database
      .prepare<SqliteApplicationRow>('SELECT * FROM application')
      .get();

    if (!row) {
      throw new Error('application row does not exist');
    }

    return sqliteApplicationMapper(row);
  }

  getMetadata() {
    const row = this.database
      .prepare<SqliteMetadataRow>('SELECT * FROM metadata')
      .get();

    if (!row) {
      throw new Error('metadata row does not exist');
    }

    return sqliteMetadataMapper(row);
  }

  getSeries() {
    return this.database
      .prepare<SqliteSeriesRow>('SELECT * FROM series')
      .mapAll<SqliteSeriesEntity>(sqliteSeriesMapper);
  }

  getSeriesImages(idSeries: SqliteSeriesEntity['id']) {
    const sql = 'SELECT * FROM series_images WHERE id_serie = $id';
    const bindings = { $id: idSeries };

    return this.database
      .prepare<SqliteSeriesFileRow>(sql)
      .mapAll<SqliteSeriesFileEntity>(sqliteSeriesFilesMapper, bindings);
  }

  getSeriesAttachments(idSeries: SqliteSeriesEntity['id']) {
    const sql = 'SELECT * FROM series_attachments WHERE id_serie = $id';
    const bindings = { $id: idSeries };

    return this.database
      .prepare<SqliteSeriesFileRow>(sql)
      .mapAll<SqliteSeriesFileEntity>(sqliteSeriesFilesMapper, bindings);
  }

  getSeriesAlerts(idSeries: SqliteSeriesEntity['id']) {
    const sql = 'SELECT * FROM series_alerts WHERE id_serie = $id';
    const bindings = { $id: idSeries };

    return this.database
      .prepare<SqliteSeriesAlertRow>(sql)
      .mapAll<SqliteSeriesAlertEntity>(sqliteSeriesAlertsMapper, bindings);
  }

  getSeriesAlertPublications(idAlert: SqliteSeriesAlertEntity['id']) {
    const sql = 'SELECT * FROM series_alert_publications WHERE id_alert = $id';
    const bindings = { $id: idAlert };

    return this.database
      .prepare<SqliteSeriesAlertPublicationRow>(sql)
      .mapAll<SqliteSeriesAlertPublicationEntity>(
        sqliteSeriesAlertPublicationsMapper,
        bindings,
      );
  }

  getSeriesAlertPublicationImage(
    idRapidIdentificationImage: SqliteSeriesAlertPublicationEntity['idRapidIdentificationImage'],
  ) {
    const sql = 'SELECT * FROM series_alert_images WHERE id = $id';
    const bindings = { $id: idRapidIdentificationImage };

    const row = this.database
      .prepare<SqliteSeriesAlertImageRow>(sql)
      .get(bindings);

    if (!row) {
      return undefined;
    }

    return sqliteSeriesAlertImagesMapper(row);
  }

  getSeriesAlertPublicationTranslations(
    idPublication: SqliteSeriesAlertPublicationEntity['id'],
  ) {
    const sql =
      'SELECT * FROM series_alert_publications_translations WHERE id_publication = $id';
    const bindings = { $id: idPublication };

    return this.database
      .prepare<SqliteSeriesAlertPublicationTranslationRow>(sql)
      .mapAll<SqliteSeriesAlertPublicationTranslationEntity>(
        sqliteSeriesAlertPublicationsTranslationsMapper,
        bindings,
      );
  }

  getSqlar(idSqlar: SqlarId): SqliteSqlarEntity | undefined {
    const sql = 'SELECT * FROM sqlar WHERE name = $id';
    const bindings = { $id: idSqlar };

    return this.database.prepare<SqliteSqlarRow>(sql).get(bindings);
  }

  getSqlarSize(idSqlar: SqlarId) {
    const sql = 'SELECT sz FROM sqlar WHERE name = $id';
    const bindings = { $id: idSqlar };

    const row = this.database
      .prepare<Pick<SqliteSqlarRow, 'sz'>>(sql)
      .get(bindings);

    if (!row) throw new TypeError(`sqlar row ${idSqlar} is missing`);

    return row.sz;
  }

  getSqlarBlob(idSqlar: SqlarId) {
    const sql = 'SELECT data FROM sqlar WHERE name = $id';
    const bindings = { $id: idSqlar };

    const row = this.database
      .prepare<Pick<SqliteSqlarRow, 'data'>>(sql)
      .get(bindings);

    if (!row) throw new TypeError(`sqlar row ${idSqlar} is missing`);

    return row.data;
  }
}
