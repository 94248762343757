import {
  CountryCodeAndName,
  getCountryList,
  getCountryMap,
} from '@zakodium/profid-shared';
import { useEffect, useState } from 'react';

import { useLocale } from '../components/providers/LocaleProvider.context';

import { Language } from '#gql';

export type CountriesList = CountryCodeAndName[];

export function useLocalizedCountryList() {
  const [ctxLocale] = useLocale();
  const [countries, setCountries] = useState<null | CountriesList>(null);

  useEffect(() => {
    if (!ctxLocale) {
      setCountries(null);
    } else {
      getCountryList(ctxLocale)
        .then((elements: CountriesList) => setCountries(elements))
        .catch(reportError);
    }
  }, [ctxLocale]);

  return countries;
}

export function useLocalizedCountryMap(language?: Language) {
  const [ctxLocale] = useLocale();
  const [countries, setCountries] = useState<Record<string, string> | null>(
    null,
  );

  useEffect(() => {
    void getCountryMap(language ?? ctxLocale).then(setCountries);
  }, [language, ctxLocale]);

  return countries;
}

export function useLocalizedCountryName(code: string, language?: Language) {
  const countryMap = useLocalizedCountryMap(language);
  if (countryMap) {
    return countryMap[code];
  }
  return null;
}
