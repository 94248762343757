import { FormattedButton } from './formatted_button';

import { DialogClose } from '#tailwind_ui';

export function FormattedDialogCancelButton() {
  return (
    <DialogClose asChild>
      <FormattedButton messageId="global.cancel" variant="white" />
    </DialogClose>
  );
}
