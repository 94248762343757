import { MandatoryChildren } from '../../../global';

import { CardLayout, CardLayoutProps } from '#ui/card_layout';

export interface SeriesAttachmentsCardProps extends MandatoryChildren {
  extraTitle?: CardLayoutProps['extraTitle'];
}

export function SeriesAttachmentsCard(props: SeriesAttachmentsCardProps) {
  return (
    <CardLayout
      headerClassName="relative space-x-2"
      title="global.attachments"
      extraTitle={props.extraTitle}
    >
      {props.children}
    </CardLayout>
  );
}
