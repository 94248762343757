import { Feature } from '../../constants';
import IfHasFeature from '../IfHasFeature';
import { useDocumentContext } from '../providers/DocumentProvider.context';

import DocumentSeriesSuggestions from './DocumentSeriesSuggestions';
import DocumentSimilarSerialNumbers from './DocumentSimilarSerialNumbers';

import {
  DocumentPadifSuggestions,
  DocumentPadifSuggestionsPythonDistance,
} from '#document';

export default function DocumentSuggestionsLegacy() {
  const { document } = useDocumentContext();
  return (
    <div className="grid grid-cols-2 gap-3">
      <div className="col-span-2 lg:col-span-1">
        <DocumentSimilarSerialNumbers document={document} />
      </div>
      <IfHasFeature feature={Feature.series}>
        <div className="col-span-2 lg:col-span-1">
          <DocumentSeriesSuggestions document={document} />
        </div>
      </IfHasFeature>
      <div className="col-span-2">
        <DocumentPadifSuggestions
          documentId={document.id}
          DistanceFetcherView={DocumentPadifSuggestionsPythonDistance}
        />
      </div>
    </div>
  );
}
