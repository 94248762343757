import { TrashIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

import FormattedFormError from '../../translation/FormattedFormError';

import { useDeleteDocumentSetMutation } from '#gql';
import { FormattedAlert } from '#intl';
// eslint-disable-next-line no-restricted-imports
import { ConfirmDialog, FormRHF, PropsOf, SubmitButtonRHF } from '#tailwind_ui';

export interface ConfirmDeleteDocumentSetProps extends PropsWithChildren {
  id: string;
  name: string;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  disabled?: boolean;
}

// The form is intentionally empty.
// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface DeleteForm {}
const defaultValues: DeleteForm = {};

export function ConfirmDeleteDocumentSet(props: ConfirmDeleteDocumentSetProps) {
  const { id, name, disabled, children, isOpen, setIsOpen } = props;

  const navigate = useNavigate();
  const [deleteDocumentSet] = useDeleteDocumentSetMutation({
    variables: {
      id,
    },
  });

  async function onSubmit() {
    await deleteDocumentSet();

    setIsOpen(false);
    navigate('/document-sets');
  }

  return (
    <ConfirmDialog
      title={<T keyName="doc_set.delete.title" params={{ name }} />}
      icon={<TrashIcon />}
      color="danger"
      open={isOpen}
      noCloseConfirm
      asConfirm={SubmitButtonRHF}
      confirmProps={{ disabled }}
      confirmText={<T keyName="global.delete" />}
      bodyProps={{ className: 'space-y-2' }}
      onCancel={() => setIsOpen(false)}
      as={FormRHF<DeleteForm>}
      asProps={
        {
          defaultValues,
          onSubmit,
        } satisfies Omit<PropsOf<typeof FormRHF<DeleteForm>>, 'children'>
      }
    >
      <FormattedAlert type="warning" title="doc_set.delete.warning" />
      <FormattedAlert
        type="info"
        title="doc_set.delete.undeletable_if_linked"
      />

      {children}

      <FormattedFormError />
    </ConfirmDialog>
  );
}
