export function parseJsonString(str: unknown): unknown {
  if (typeof str !== 'string') throw new TypeError('need a string');

  return JSON.parse(str);
}

export function zodTransformStringOrNullToDateOrNull(value: string | null) {
  if (!value) return null;
  return zodTransformStringToDate(value);
}

export function zodTransformStringToDate(value: string) {
  return new Date(value);
}
