import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { GenericTableSearchContext } from '../components/providers/TableSearchContextProvider';
import { checkIsFiltered } from '../components/providers/TableSearchContextProvider.utils';

export function useSyncTableSearchParams(context: GenericTableSearchContext) {
  const [search] = useSearchParams();

  // on mount hook, setAdvancedSearch, after sync
  const refContext = useRef(context);
  refContext.current = context;
  const refRun = useRef(0);

  useEffect(() => {
    // Weird issue on useBlocker when navigating on a page which interacts with searchParams
    // PreventNavigation Modal is redisplayed.
    // Delay with timeout makes it work properly (tried queueMicrotask before)
    // also tried to wait for PreventNavigationBlockerContext
    // and PreventNavigationShouldBlockContext be in navigable state.
    // Refs: https://github.com/remix-run/react-router/issues/11144
    const timeout = setTimeout(() => {
      const context = refContext.current;
      context.store.syncUrlSearchParams(search);

      // on mount hook part, setAdvancedSearch, after sync
      if (refRun.current > 0) return;

      context.setAdvancedSearch(checkIsFiltered(context.store.getSnapshot()));
      refRun.current++;
    }, 0);

    return () => clearTimeout(timeout);
  }, [search]);
}
