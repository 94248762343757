import { T } from '@tolgee/react';
import { getAggregator, PadifAggregationMethod } from '@zakodium/profid-shared';
import { decode, Image, writeCanvas } from 'image-js';
import { AgglomerationMethod } from 'ml-hclust';
import { useCallback, useMemo, useState } from 'react';
import { FormattedDate } from 'react-intl';
import { match } from 'ts-pattern';

import { apiClient } from '../../../api';
import { usePromise } from '../../../hooks/usePromise';
import {
  UseTableSelect,
  useTableSelection,
} from '../../../hooks/useTableSelection';
import { safeTranslationKeyDocumentView } from '../../../translationsMapping/documents';
import FormattedErrorAlert from '../../translation/FormattedErrorAlert';
import FormattedLabel from '../../translation/FormattedLabel';
import PadifClustering from '../report/PadifClustering';

import { DocumentView, GqlPadifAnalysisResultQuery } from '#gql';
import { DropdownAggregationMethod } from '#padif';
// Checkbox label is a crop image and not a translatable string
// eslint-disable-next-line no-restricted-imports
import { Checkbox, Select } from '#tailwind_ui';
import { assert } from '#utils/assert';

type ResultJs = Exclude<
  GqlPadifAnalysisResultQuery['padifAnalysis']['resultJs'],
  null | undefined
>;

type ReferenceDocument = Exclude<
  GqlPadifAnalysisResultQuery['padifAnalysis']['referenceDocument'],
  null | undefined
>;
type Roi = Exclude<
  GqlPadifAnalysisResultQuery['padifAnalysis']['rois'][number],
  null | undefined
>;

export interface PadifReportNewProps {
  result: ResultJs & {
    finishedAt: Date;
    documents: Exclude<ResultJs['documents'], null | undefined>;
    distances: Exclude<ResultJs['distances'], null | undefined>;
  };
  referenceDocument: ReferenceDocument;
  rois: Roi[];
}

export function PadifReportNew(props: PadifReportNewProps) {
  const { result, referenceDocument, rois } = props;

  const [clusteringMethod, setClusteringMethod] =
    useState<AgglomerationMethod>('ward');
  const [statisticsAggregationMethod, setStatisticsAggregationMethod] =
    useState<PadifAggregationMethod>('EUCLIDEAN_DISTANCE');
  const selection = useTableSelection(() => new Set(rois.map((roi) => roi.id)));

  const distanceMatrix = useMemo(() => {
    if (!result.distances) return [];
    if (result.distances.length === 0) return [];

    type CompactDistanceMatrix = number[];
    const distances = result.distances
      .filter((entry) => selection.value.has(entry.roi))
      .map((entry) => entry.value as CompactDistanceMatrix);

    function aggregateMatrices(aggregatorFn: (values: number[]) => number) {
      return (compactMatrices: CompactDistanceMatrix[]) => {
        const agg = new Array<number>(
          compactMatrices[0].length,
        ) as CompactDistanceMatrix;

        for (let i = 0; i < agg.length; i++) {
          agg[i] = aggregatorFn(compactMatrices.map((cm) => cm[i]));
        }

        return agg;
      };
    }

    const aggregator = aggregateMatrices(
      getAggregator(statisticsAggregationMethod),
    );
    return aggregator(distances);
  }, [result.distances, statisticsAggregationMethod, selection.value]);

  assert(result.distances);
  assert(result.finishedAt);
  assert(result.documents);

  return (
    <div className="mt-2 flex flex-col">
      <div className="text-center">
        <FormattedDate
          value={result.finishedAt}
          year="numeric"
          month="numeric"
          day="numeric"
          hour="numeric"
          minute="numeric"
          hour12={false}
        />
      </div>

      <details className="group space-y-2">
        <summary className="cursor-pointer text-center group-open:-mb-2">
          <T keyName="global.advanced_settings" />
        </summary>
        <DropdownClusterring
          selected={clusteringMethod}
          setSelected={setClusteringMethod}
        />
        <DropdownAggregationMethod
          selected={statisticsAggregationMethod}
          setSelected={setStatisticsAggregationMethod}
        />
        <RoisPicker
          rois={rois}
          selection={selection}
          referenceDocument={referenceDocument}
        />
      </details>

      <div className="text-center">
        RMSE | {statisticsAggregationMethod} | {clusteringMethod}
      </div>

      <PadifClustering
        referenceId={referenceDocument.id}
        documents={result.documents}
        distanceMatrix={distanceMatrix}
        clusteringMethod={clusteringMethod}
      />
    </div>
  );
}

interface DropdownClusturingProps {
  selected: AgglomerationMethod;
  setSelected: (value: AgglomerationMethod) => void;
}

function DropdownClusterring({
  selected,
  setSelected,
}: DropdownClusturingProps) {
  const options = useMemo(() => getClustreringOptions(), []);

  const onSelect = useCallback(
    (selected: string | undefined) => {
      assert(selected);
      setSelected(selected as AgglomerationMethod);
    },
    [setSelected],
  );

  return (
    <Select<string>
      label={<T keyName="padif.clustering_method.label" />}
      options={options}
      selected={selected}
      onChange={onSelect}
    />
  );
}

function getClustreringOptions(): AgglomerationMethod[] {
  return [
    'single',
    'complete',
    'average',
    'upgma',
    'wpgma',
    'median',
    'wpgmc',
    'centroid',
    'upgmc',
    'ward',
    'ward2',
  ];
}

interface RoisPickerProps {
  selection: UseTableSelect<string>;
  rois: Roi[];
  referenceDocument: ReferenceDocument;
}
function RoisPicker(props: RoisPickerProps) {
  return (
    <div className="space-y-2">
      <FormattedLabel text="padif.rois_choice.label" />

      {props.rois.map((roi) => (
        <RoiCheckbox
          key={roi.id}
          selection={props.selection}
          roi={roi}
          referenceDocument={props.referenceDocument}
        />
      ))}
    </div>
  );
}

interface RoiCheckboxProps {
  selection: UseTableSelect<string>;
  roi: Roi;
  referenceDocument: ReferenceDocument;
}
function RoiCheckbox(props: RoiCheckboxProps) {
  const {
    roi,
    selection: { methods, value },
    referenceDocument,
  } = props;

  const imageUrl = match<DocumentView | undefined>(roi.view)
    .with(
      referenceDocument.imageRecto?.view,
      () => referenceDocument.imageRecto?.document.path,
    )
    .with(
      referenceDocument.imageVerso?.view,
      () => referenceDocument.imageVerso?.document.path,
    )
    .otherwise(() => null);

  const result = usePromise(
    useCallback(async () => {
      if (!imageUrl) return null;

      const image = await cacheGetImage(imageUrl);

      const crop = image.crop({
        origin: {
          column: roi.x,
          row: roi.y,
        },
        height: roi.height,
        width: roi.width,
      });

      const canvas = document.createElement('canvas');
      canvas.height = crop.height;
      canvas.width = crop.width;
      writeCanvas(crop, canvas);

      return canvas.toDataURL('image/png');
    }, [imageUrl, roi]),
  );

  if (result.error) return <FormattedErrorAlert error={result.error} />;
  if (!result.data) return null;

  return (
    <Checkbox
      name="roi"
      label={
        <div className="flex items-center gap-2">
          <img
            src={result.data}
            alt={`roi ${roi.view} ${roi.id}`}
            className="h-10"
          />
          <span style={{ color: roi.color }}>
            <T keyName={safeTranslationKeyDocumentView(roi.view)} />
          </span>
        </div>
      }
      className="items-center"
      checked={value.has(roi.id)}
      onChange={(event) => {
        const method = event.currentTarget.checked
          ? methods.add
          : methods.delete;

        if (method === methods.delete && value.size === 1) return; // do not allow selecting no ROI

        method(roi.id);
      }}
    />
  );
}

function cacheGetImage(imageUrl: string): Promise<Image> {
  const { cache } = cacheGetImage;
  if (cache.has(imageUrl)) return cache.get(imageUrl) as Promise<Image>;

  const promise = apiClient
    .get(imageUrl)
    .arrayBuffer()
    .then((buffer) => decode(new DataView(buffer)));
  cache.set(imageUrl, promise);

  return promise;
}
cacheGetImage.cache = new Map<string, Promise<Image>>();
