import { SeriesRules } from '@zakodium/profid-shared';

import { requiredString, requiredObject } from '../../forms/validation';

const seriesNameValidation = requiredString.test({
  test: (name) => SeriesRules.isValidName(name),
  message: 'form.validation.serie_name',
});

const newSeriesValidationSchema = requiredObject({
  name: seriesNameValidation,
  docType: requiredString,
  docCountry: requiredString,
  docFraudType: requiredString,
});

const updateSeriesNameValidationSchema = requiredObject({
  name: seriesNameValidation,
});

function createNoDuplicateNameValidation(
  errorTranslationKey: TranslationKey,
  names: string[] = [],
) {
  return requiredObject({
    name: requiredString.notOneOf(names || [], errorTranslationKey),
  });
}

export function getSeriesValidationSchema(
  errorTranslationKey: TranslationKey,
  forbiddenName?: string[],
) {
  return [
    newSeriesValidationSchema,
    createNoDuplicateNameValidation(errorTranslationKey, forbiddenName),
  ];
}

export function getUpdateSeriesNameValidationSchema(
  errorTranslationKey: TranslationKey,
  forbiddenNames?: string[],
) {
  return [
    updateSeriesNameValidationSchema,
    createNoDuplicateNameValidation(errorTranslationKey, forbiddenNames),
  ];
}
