import { DocumentTextIcon } from '@heroicons/react/24/outline';
import { PropsWithChildren, ReactNode } from 'react';

import FormattedEmptyState from '../../../components/translation/FormattedEmptyState';
import { FileAttachment } from '../types';

export interface SeriesAttachmentsContainerProps extends PropsWithChildren {
  attachments: FileAttachment[];
  renderAttachments: (attachment: FileAttachment) => ReactNode;
}

export function SeriesAttachmentsContainer(
  props: SeriesAttachmentsContainerProps,
) {
  const { attachments, renderAttachments } = props;

  if (attachments.length === 0) {
    return (
      <FormattedEmptyState
        title="global.no_attachments"
        icon={<DocumentTextIcon />}
      />
    );
  }

  return (
    <>
      <ul className="space-y-1">
        {attachments.map((file) => (
          <li
            key={file.id}
            className="flex max-w-full flex-row flex-nowrap items-center gap-2"
          >
            {renderAttachments(file)}
          </li>
        ))}
      </ul>

      {props.children}
    </>
  );
}
