import { UserPermission } from '#gql';

export type ExperimentalFeatureId = 'auditLogs' | 'padifJs' | 'series_exchange';

export interface ExperimentalFeature {
  id: ExperimentalFeatureId;
  name: string;
  description: string;
  permissions: UserPermission[];
}

export const experimentalFeatures: ExperimentalFeature[] = [
  {
    id: 'auditLogs',
    name: 'Audit logs',
    description: 'View ProFID audit logs',
    permissions: ['auditLog_view'],
  },
  {
    id: 'padifJs',
    name: 'Padif analysis new version report',
    description:
      'With customisable clustering method and statistics aggregation. Data stored by ROI instead pre-aggregated by document.',
    permissions: ['padifAnalysis_view'],
  },
  {
    id: 'series_exchange',
    name: 'Series exchange',
    description: 'Allow to import / export series between ProFID instances',
    permissions: ['serie_create'],
  },
];
