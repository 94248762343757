import { useNavigate } from 'react-router-dom';

import {
  DraftOrPublicationAlert,
  getLanguages,
  getTranslation,
} from '../utils/draftOrPublication';

import { AlertLanguageSelect as LanguageSelect } from '#series_alert';

export function AlertLanguageSelect(props: { alert: DraftOrPublicationAlert }) {
  const navigate = useNavigate();

  const options = getLanguages(props.alert);
  const translation = getTranslation(props.alert);

  return (
    <LanguageSelect
      languages={options}
      selectedLanguage={translation.language}
      onChange={(language) => navigate(`./?language=${language}`)}
      hiddenLabel
    />
  );
}
