import { PencilIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';

import {
  optionalString,
  requiredBoolean,
  requiredObject,
} from '../../../forms/validation';
import SelectService from '../../form/SelectService';
import { useCheckedLoginState } from '../../providers/LoginProvider.context';
import FormattedCheckboxField from '../../translation/FormattedCheckboxField';
import FormattedFormError from '../../translation/FormattedFormError';
import FormattedUserName from '../../translation/FormattedUserName';

import { GqlUsersQuery, useChangeUserProtectedFieldsMutation } from '#gql';
import { FormattedDialogCancelButton, FormattedSubmitButton } from '#intl';
import {
  FormRHFDialogBody,
  FormRHFDialogFooter,
  FormRHFDialogRoot,
  FormRHFDialogTitle,
  useNotificationCenter,
} from '#tailwind_ui';

interface EditUserValues {
  isDisabled: boolean;
  service: string | null;
}

const validationSchema = requiredObject({
  isDisabled: requiredBoolean,
  service: optionalString,
});

export default function ChangeUserProtectedFieldsDialog({
  user,
  onCancel,
}: {
  user: GqlUsersQuery['users']['nodes'][number];
  onCancel: () => void;
}) {
  const login = useCheckedLoginState();
  const [changeUserProtectedFields] = useChangeUserProtectedFieldsMutation();
  const { addNotification } = useNotificationCenter();

  async function onSubmit(values: EditUserValues) {
    await changeUserProtectedFields({
      variables: {
        input: {
          id: user.id,
          service: values.service || null,
          isDisabled: values.isDisabled,
        },
      },
    });

    addNotification({
      type: 'success',
      title: <T keyName="page.users.update.success" />,
    });
  }

  return (
    <FormRHFDialogRoot<EditUserValues>
      autoCloseOnSubmit
      open
      icon={<PencilIcon />}
      onOpenChange={(value) => {
        if (value) return;
        onCancel();
      }}
      validationSchema={validationSchema}
      defaultValues={{
        isDisabled: user.isDisabled,
        service: user.service ? user.service.identifier : null,
      }}
      onSubmit={onSubmit}
    >
      <FormRHFDialogTitle>
        <T keyName="page.users.action.edit_user" />
      </FormRHFDialogTitle>
      <FormRHFDialogBody>
        <div>
          <T keyName="global.user" />
          {`: `}
          <FormattedUserName values={user} />
        </div>
        <FormattedCheckboxField
          name="isDisabled"
          label="user.status.DISABLED"
          disabled={login.id === user.id}
        />

        <SelectService clearable name="service" />

        <FormattedFormError />
      </FormRHFDialogBody>
      <FormRHFDialogFooter>
        <FormattedDialogCancelButton />
        <FormattedSubmitButton messageId="global.change" />
      </FormRHFDialogFooter>
    </FormRHFDialogRoot>
  );
}
