import { useMemo, useSyncExternalStore } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SyncSearchParamsStore } from '../stores/SyncSearchParamsStore';

export type UseUrlSearchParamsStore<
  Data extends Record<string, unknown>,
  Store extends SyncSearchParamsStore<Data>,
> = [data: Data, store: Store];

export type ClassSearch<
  D extends Record<string, unknown>,
  T extends SyncSearchParamsStore<D>,
> = new () => T;

export function useTableSearchStore<
  Data extends Record<string, unknown>,
  // TODO: type parameters should be simplified here as `StoreCls` is not useful.
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
  StoreCls extends ClassSearch<Data, SyncSearchParamsStore<Data>>,
  Store extends SyncSearchParamsStore<Data>,
>(
  _Store: StoreCls,
  initStore?: (store: Store) => void,
): UseUrlSearchParamsStore<Data, Store> {
  const [, setSearch] = useSearchParams();
  const store = useMemo(() => {
    const store = new _Store() as Store;
    store.setSetSearchParams(setSearch);
    initStore?.(store);

    return store;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data = useSyncExternalStore<Data>(store.subscribe, store.getSnapshot);

  return [data, store];
}
