import { ShieldExclamationIcon } from '@heroicons/react/24/outline';
import { T, useTolgee } from '@tolgee/react';
import {
  formatSeriesSeqId,
  SeriesExchangeExtractor,
  SqliteSeriesAlertPublicationEntity,
} from '@zakodium/profid-shared';
import { useEffect, useMemo, useState } from 'react';

import FormattedEmptyState from '../../../../components/translation/FormattedEmptyState';
import { MandatoryChildren } from '../../../../global';

import {
  AlertDocumentGeneralInformation,
  AlertDocumentHeader,
  AlertDocumentMeasure,
  AlertDocumentRapidIdentificationDescription,
  AlertDocumentRapidIdentificationImage,
  AlertDocumentTitle,
  AlertLanguageSelect,
} from '#series_alert';
import { assert } from '#utils/assert';

interface PreviewAlertProps {
  extractor: SeriesExchangeExtractor;
}

export function PreviewAlert(props: PreviewAlertProps) {
  const tolgee = useTolgee();
  const currentUILanguage = tolgee.getLanguage();

  const { extractor } = props;

  const data = extractData(extractor);

  const languages = data?.translations.map((t) => t.language) ?? [];
  const [selectedLanguage, setSelectedLanguage] = useState(() => {
    return (
      languages?.find((l) => l === currentUILanguage) ??
      languages?.at(0) ??
      'en'
    );
  });

  const selectedTranslation = data?.translations.find(
    (t) => t.language === selectedLanguage,
  );

  if (!data) {
    return (
      <PreviewAlertContainer>
        <div className="rounded border border-neutral-200 p-5">
          <FormattedEmptyState
            title="series_exchange.preview.alert.empty"
            icon={<ShieldExclamationIcon />}
          />
        </div>
      </PreviewAlertContainer>
    );
  }
  assert(selectedTranslation);

  return (
    <PreviewAlertContainer>
      <AlertLanguageSelect
        languages={languages}
        selectedLanguage={selectedLanguage}
        onChange={setSelectedLanguage}
      />

      <AlertDocumentHeader
        language={selectedLanguage}
        serieSeqId={data.serieSeqId}
        date={data.publication.publishedAt}
      />

      <AlertDocumentTitle
        title={selectedTranslation.fieldTitle}
        language={selectedTranslation.language}
        serieName={data.serie.name}
      />

      <AlertDocumentGeneralInformation
        language={selectedTranslation.language}
        generalInformation={selectedTranslation.fieldGeneral}
      />

      <AlertDocumentRapidIdentificationDescription
        language={selectedTranslation.language}
        description={selectedTranslation.fieldRapidIdentificationDescription}
      >
        <RapidDetectionImage
          extractor={extractor}
          idRapidIdentificationImage={
            data.publication.idRapidIdentificationImage
          }
        />
      </AlertDocumentRapidIdentificationDescription>

      <AlertDocumentMeasure
        language={selectedTranslation.language}
        measures={selectedTranslation.fieldMeasures}
      />
    </PreviewAlertContainer>
  );
}

function extractData(extractor: SeriesExchangeExtractor) {
  const application = extractor.getApplication();

  const serie = extractor.getSeries().at(0);
  assert(serie);

  const alert = extractor.getSeriesAlerts(serie.id).at(0);
  if (!alert) return null;

  const publication = extractor.getSeriesAlertPublications(alert.id).at(0);
  assert(publication);

  const translations = extractor.getSeriesAlertPublicationTranslations(
    publication.id,
  );

  return {
    serie,
    serieSeqId: formatSeriesSeqId({
      code: application.code,
      seqYear: serie.seqYear,
      seqNumber: serie.seqNumber,
    }),
    alert,
    publication,
    translations,
  };
}

function PreviewAlertContainer(props: MandatoryChildren) {
  return (
    <details className="space-y-2">
      <summary>
        <h3 className="inline cursor-pointer font-semibold">
          <T keyName="series_exchange.preview.alert.title" />
        </h3>
      </summary>

      {props.children}
    </details>
  );
}

interface RapidDetectionImageProps {
  extractor: SeriesExchangeExtractor;
  idRapidIdentificationImage: SqliteSeriesAlertPublicationEntity['idRapidIdentificationImage'];
}

function RapidDetectionImage(props: RapidDetectionImageProps) {
  const { extractor, idRapidIdentificationImage } = props;

  const url = useMemo(() => {
    const image = extractor.getSeriesAlertPublicationImage(
      idRapidIdentificationImage,
    );
    if (!image) return null;

    const buffer = extractor.getSqlarBlob(image?.idSqlar);
    return URL.createObjectURL(new File([buffer], image.filename));
  }, [extractor, idRapidIdentificationImage]);

  useEffect(() => {
    if (!url) return;

    return () => URL.revokeObjectURL(url);
  }, [url]);

  if (!url) return null;

  return <AlertDocumentRapidIdentificationImage url={url} />;
}
