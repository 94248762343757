import { TrashIcon as TrashIconSolid } from '@heroicons/react/20/solid';
import { TrashIcon as TrashIconOutline } from '@heroicons/react/24/outline';
import { useCallback, useState } from 'react';

import IfHasPermission from '../permissions/IfHasPermission';

import DeleteSerieImage from './DeleteSerieImage';

import {
  FileImage,
  SeriesImage,
  SeriesImageOverlay,
  SeriesImagesContainer,
} from '#series';
import { Button, Color, DialogRoot, Size } from '#tailwind_ui';
import { CompleteOverlay } from '#ui/overlay';

export interface RemovableSeriesImagesProps {
  serieId: string;
  images: FileImage[];
}
export function RemovableSeriesImages(props: RemovableSeriesImagesProps) {
  const { serieId, images } = props;

  const [deleteImage, setDeleteImage] = useState<FileImage | null>(null);

  const closeModal = useCallback(() => {
    setDeleteImage(null);
  }, []);

  return (
    <SeriesImagesContainer
      className="columns-2 md:columns-3 lg:columns-4"
      images={images}
      renderImage={(image) => (
        <SeriesImageOverlay
          Overlay={CompleteOverlay}
          label={image.filename}
          overlayChildren={
            <IfHasPermission userPermission="serie_update">
              <Button
                size={Size.xSmall}
                color={Color.danger}
                onClick={() => setDeleteImage(image)}
                roundness="circular"
                className="pointer-events-auto absolute bottom-0 right-0 m-2"
              >
                <TrashIconSolid className="h-5 w-5" />
              </Button>
            </IfHasPermission>
          }
        >
          <SeriesImage image={image} />
        </SeriesImageOverlay>
      )}
    >
      {deleteImage && (
        <DialogRoot
          iconColor="danger"
          icon={<TrashIconOutline />}
          open
          onOpenChange={closeModal}
        >
          <DeleteSerieImage
            serieId={serieId}
            image={deleteImage}
            onCancel={closeModal}
          />
        </DialogRoot>
      )}
    </SeriesImagesContainer>
  );
}
