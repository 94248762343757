import { AppCode } from './types';

export type SeqIdPrefix = 'D' | 'S';

export interface SeqYearAndNumber {
  seqYear: number;
  seqNumber: number;
}

export interface SeqId<Prefix extends SeqIdPrefix = SeqIdPrefix>
  extends SeqYearAndNumber {
  prefix: Prefix;
}

export interface SeqIdSeries extends SeqId<'S'> {
  code: AppCode;
}

export type SeqIdDocument = SeqId<'D'>;

export interface FormatSeriesSeqIdOptions {
  code: AppCode;
  seqYear: number;
  seqNumber: number;
}
export function formatSeriesSeqId(options: FormatSeriesSeqIdOptions) {
  const { code, seqYear, seqNumber } = options;
  const year = seqYear - 2000;
  const number = seqNumber.toString(10).padStart(3, '0');

  return `S-${code}-${year}-${number}`;
}

export interface FormatDocumentSeqIdOptions {
  seqYear: number;
  seqNumber: number;
}
export function formatDocumentSeqId(options: FormatDocumentSeqIdOptions) {
  const { seqYear, seqNumber } = options;
  const year = seqYear - 2000;
  const number = seqNumber.toString(10).padStart(4, '0');

  return `D-${year}-${number}`;
}

const seriesSeqIdRegex = /^S-[A-Z]{3}-\d{2}-\d+$/;
export function parseSeriesSeqId(seq: string): SeqIdSeries | null {
  if (!seriesSeqIdRegex.test(seq)) {
    return null;
  }

  const [prefix, code, seqYear, seqNumber] = seq.split('-');

  return {
    prefix: prefix as 'S',
    code,
    seqYear: 2000 + Number(seqYear),
    seqNumber: Number(seqNumber),
  };
}

const docSeqIdRegex = /^D-\d{2}-\d+$/;
export function parseDocSeqId(seq: string): SeqIdDocument | null {
  if (!docSeqIdRegex.test(seq)) {
    return null;
  }

  const [prefix, seqYear, seqNumber] = seq.split('-');

  return {
    prefix: prefix as 'D',
    seqYear: 2000 + Number(seqYear),
    seqNumber: Number(seqNumber),
  };
}
