import { CSSProperties } from 'react';

import FormattedSearchSelectField from '../translation/FormattedSearchSelectField';

import { GqlServicesQuery, useServicesQuery } from '#gql';
import { useSearchSelect } from '#tailwind_ui';

interface SelectServiceProps {
  name: string;
  menuPortalTarget?: HTMLElement;
  clearable?: boolean;
  required?: boolean;
  autoFocus?: boolean;
  styles?: {
    menuPortal: (previous: CSSProperties) => CSSProperties;
  };
}

export default function SelectService(props: SelectServiceProps) {
  const { data, error } = useServicesQuery();
  if (error) throw error;

  const values =
    data?.services.nodes.map((service) => service.identifier) ?? [];

  const serviceMap =
    // eslint-disable-next-line unicorn/no-array-reduce
    data?.services.nodes.reduce<
      Record<string, GqlServicesQuery['services']['nodes'][number]>
    >((acc, service) => {
      acc[service.identifier] = service;
      return acc;
    }, {}) ?? {};

  const searchSelectProps = useSearchSelect<string>({
    options: values,
    filterOptions: (query, options) => {
      return options.filter((option) => {
        const service = serviceMap[option];
        return (
          service.identifier.toLowerCase().includes(query.toLowerCase()) ||
          service.name.toLowerCase().includes(query.toLowerCase())
        );
      });
    },
  });

  return (
    <FormattedSearchSelectField<string>
      {...props}
      label="global.service"
      {...searchSelectProps}
      renderOption={(option) => {
        const service = serviceMap[option];
        if (!service) return null;
        return `${service.identifier} - ${service.name}`;
      }}
    />
  );
}
